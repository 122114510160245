
.download-btn {
    background: #E81E25;
    color: white;
    height: 42px;
    line-height: 41px;
    padding: 0 50px;
    letter-spacing: 2px;
}

.download-btn:hover {
    background: transparent;
    border: 1px solid #E81E25;
    color: white;
}
.download-btn:focus {
    background: transparent;
    border: 1px solid #E81E25;
    color: white;
}

.download-btn img {
    width: 13px;
    margin: 0 0 3px 3px;
}


.book-landing .container {
    max-width: 1140px;
}

.book-landing .intro {
    background: url('../../images/wine-glass.png'), url('../../images/purple.png') center, no-repeat;
    background-size: cover;
    /*background: url('../../images/wine-glass.png');*/
}

.book-landing .intro-bg {
    padding: 107px 0;
    background: url('../../images/bg37.png') top left;
    background-repeat: repeat-y;
    background-size: 232px;


}

.book-landing .intro h1 {
    font-size: 55px;
    line-height: 69px;
    margin: 20px 0 5px 0;
    width: 89%;
    color: white;

}

.book-landing .intro h2 {
    font-weight: 100;
    color: white;
    font-size: 23px;
    font-style: normal;
    font-family: FedraSansPro;
    letter-spacing: 1px;
    margin-bottom: 15px;
}

.intro .free-book {
    background: #E6B136;
    color: white;
    text-transform: uppercase;
    padding: 6px 27px;
    font-size: 12px;
    letter-spacing: 1px;
}

/*.intro .free-book:hover {*/
/*    background: transparent;*/
/*    border: 1px solid #E6B136;*/
/*    padding: 6px 28px;*/
/*}*/


.intro .image-book {
    position: relative;
}

.intro .image-book img {
    position: absolute;
    left: -41px;
    top: -89px;
    max-width: 650px;
}

@media (max-width: 1250px) {
    .intro .image-book img {
        max-width: 581px;
        left: 0;
    }
}

@media (max-width: 1192px) {
    .intro .image-book img {
        max-width: 100%;
    }
}

@media (max-width: 991px) {
    .book-landing .intro .image-book img {
        position: initial;
        margin-top: 45px;
    }

    .book-landing .intro .intro-items {
        text-align: center;
    }

    .book-landing .intro-bg {
        padding: 70px 0;
    }
}

@media (max-width: 925px) {
    .book-landing .intro h1 {
        width: 100%;
    }
}

@media (max-width: 550px) {
    .book-landing .intro h1 {
        font-size: 55px;
        margin-bottom: 15px;
    }
}

@media (max-width: 425px) {
    .book-landing .intro h1 {
        font-size: 46px;
        line-height: 66px;
    }

    .book-landing .intro h2 {
        font-size: 20px;
        margin-bottom: 25px;
    }
}

@media (max-width: 353px) {
    .book-landing .intro h1 {
        font-size: 37px;
        line-height: 60px;
    }
}


/*section from-us*/

.from-us {
    padding: 60px 0;
}

.from-us h3 {
    color: #461754;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 25px;

}

.from-us .our-info {
    padding: 30px 0 0 0;
}

.from-us .our-info p {
    color: #242322;
    font-size: 15px;
    width: 86%;
    margin-bottom: 100px;
    line-height: 28px;

}

.from-us .book {
    background: url("../../images/book-color.png");
    padding-right: 30px;
    height: 342px;
}

.from-us .book-container {
    background: url("../../images/bottleBgg.png") no-repeat;
    background-position: right;
    background-size: contain;
    height: 342px;
    position: relative;
}

.from-us .book-container img {
    position: absolute;
    bottom: 18px;
    left: 0;
    max-width: 610px;
}

.from-us .form-container {
    background: #242323;
    color: white;
    width: 365px;
    padding: 65px 40px 45px;
}

.from-us .form-container h5 {
    color: white;
    text-transform: capitalize;
    text-align: center;
    font-size: 23px;
    letter-spacing: 1px;
    line-height: 37px;
    margin-bottom: 25px;

}

.from-us .form-container .form-group {
    margin-bottom: 20px;
}

.from-us .form-container form .form-group input {
    display: block;
    width: 100%;
    height: 42px;
    /* border: none; */
    padding: 0 10px;
    border: none;
}

.from-us .form-container form input::placeholder {
    color: #9C9C9C;
    font-size: 12px;
}

.from-us .form-container .download-btn {
    width: 100%;
    margin-top: 6px;
}

.from-us ul {
    margin-bottom: 90px;
}

.from-us .gallery img {
    margin-bottom: 5px;
}

.from-us h4 {
    color: #461754;
    font-weight: bold;
    font-size: 31px;
    margin: 80px 0 25px 0;
}

.from-us a {
    margin-bottom: 23px;
    display: block;

}



.from-us .tittle-top {
    position: relative;
}

.from-us .tittle-top::before {
    content: "";
    width: 8px;
    height: 8px;
    background: #E81E25;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 9px;
    left: 0;
}

.from-us .tittle-top p {
    margin-left: 15px;
    line-height: 26px;
    color: black;

}
.from-us .tittle-top a:hover,
.from-us .info-top a:hover {
    color: #E81E25;
}
.from-us ul li.info  {
    line-height: 22px;
    color: black;
    position: relative;
    margin-bottom: 7px;
    padding: 0 57px 0 14px;
    margin-top: 7px;
}

.from-us ul li.info::before {
    content: "";
    width: 8px;
    height: 8px;
    background: #E81E25;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 7px;
    left: 0;
}

.from-us ul li.top-list {
    margin-bottom: 20px;
}

.and-more {
    position: relative;
}

.and-more:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #FBFAF9;
    z-index: -1;
}

.from-us ul li.top-list a img {
    top: 20%;
}

.from-us .info-top {
    position: relative;
}

.from-us .info-top::before {
    content: "";
    width: 8px;
    height: 8px;
    background: #E81E25;
    border-radius: 100%;
    display: block;
    position: absolute;
    top: 7px;
    left: 0;
}

.from-us .info-top a {
    margin-left: 14px;
    line-height: 22px;
    color: black;
}

.from-us ul li a {
    color: black;
    position: relative;
    margin-bottom: 12px;
}

.from-us ul li a:hover {
    color: #E81E25;
}

.from-us .container-gray-bg {
    padding: 50px;
}

.from-us .container-gray-bg ul {
    margin-bottom: 28px;
}

.from-us .container-gray-bg h4 {
    margin-top: 0;
}

.from-us .container-gray-bg ul img {
    margin-top: 10px;
}

.from-us .container-gray-bg p {
    text-align: center;
    margin-bottom: 6px;
    font-family: FSerProB;
    font-size: 17px;
    color: #461754;
}
.from-us .container-gray-bg p.top-small {
    margin-bottom: 20px;
    font-family: 'FedraSansPro';
    font-size: 14px;
    color: black;
    padding-left: 14px;
    text-align: left;
}

.from-us .container-gray-bg a.download-btn {
    display: block;
    width: 212px;
    margin: 35px auto;
}

.from-us .container-gray-bg a.download-btn:hover {
    background: #9C1711;
}
.from-us .container-gray-bg a.download-btn:focus {
    background: #9C1711;
}

@media (max-width: 1024px) and (min-width: 991px) {
    .from-us .form-container {
        padding: 45px 40px 15px;
    }
    .from-us .form-container form input {
        height: 37px;
    }
}

@media (min-width: 959px) {
    .from-us h3 {
        width: 70%;
    }
}
@media (max-width: 991px) and (min-width: 760px) {
    .from-us .book-container img {
        max-width: 100%;
        bottom: -44px;
    }
}
@media (max-width: 991px) {
    .from-us .form-container {
        padding: 30px 40px 15px;
        margin: 45px auto 0;
        display: table;
    }
}

@media (max-width: 768px) {
    .from-us {
        padding: 20px 0 0 0
    }

    .from-us h4 {
        margin: 55px 0 25px 0;
    }

    .from-us ul {
        margin-bottom: 50px;
    }
}

@media (max-width: 765px) {
    .from-us .image-cont {
        height: 799px;
    }
}

@media (max-width: 625px) {
    .from-us .book-container img {
        max-width: 100%;
        position: inherit;
    }

    .from-us .our-info p {
        margin-bottom: 40px;
    }

    .from-us .image-cont {
        height: 100%
    }

    .from-us .container-gray-bg {
        padding: 30px 25px 26px 25px;

    }

    .from-us .container-gray-bg p {
        line-height: 26px;
    }
}

@media (max-width: 540px) {
    .from-us .book {
        height: 261px;
    }

    .from-us h3 {
        font-size: 29px;
    }

    .from-us h4 {
        margin: 40px 0 25px 0;
        font-size: 25px;
    }

    .from-us ul li a {
        font-size: 13px;
    }
}

@media (max-width: 450px) {
    .from-us ul li.top-list a img {
        top: 11%;
    }
}

@media (max-width: 425px) {
    .from-us .book {
        height: 206px;
    }

    .from-us .form-container {
        width: 100%;
    }

    .from-us h3 {
        font-size: 25px;
        line-height: 41px;
    }

    .from-us .our-info p {
        width: 100%;
        font-size: 12px;
        line-height: 25px;
    }

    .from-us .form-container h5 {
        font-size: 18px;
    }

    .from-us h4 {
        font-size: 20px;
    }

    .from-us .container-gray-bg p {
        font-size: 15px;
    }
}

@media (max-width: 350px) {
    .from-us .book {
        height: 161px;
    }

    .from-us h3 {
        font-size: 22px;
        line-height: 36px;
    }

    .from-us h4 {
        font-size: 18px;
    }

    .from-us ul li.top-list a img {
        top: 8%;
    }

    .from-us ul li.find-image {
        display: inline-block;
        margin: 0 4px 16px 0;
    }

    .from-us ul li.info {
        padding: 0 0 0 12px;
    }

    .from-us .container-gray-bg p {
        font-size: 13px;
    }
    .what-will-find h4 {
        letter-spacing: -0.2px;
    }
}