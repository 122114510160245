@font-face {
    font-family: 'Moderato G3 L';
    src: url('../../fonts/moderato/ModeratoG3L-BoldItalic.eot');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Moderato G3 L';
    src: local('Moderato G3 L Bold Italic'), local('ModeratoG3L-BoldItalic'),
    url('../../fonts/moderato/ModeratoG3L-BoldItalic.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG3L-BoldItalic.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG3L-BoldItalic.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG3L-BoldItalic.svg#ModeratoG3L-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato G3 L';
    src: url('../../fonts/moderato/ModeratoG3L.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Moderato G3 L';
    src: local('Moderato G3 L'), local('ModeratoG3L'),
    url('../../fonts/moderato/ModeratoG3L.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG3L.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG3L.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG3L.svg#ModeratoG3L') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato G3 L';
    src: url('../../fonts/moderato/ModeratoG3L-Bold.eot');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Moderato G3 L';
    src: local('Moderato G3 L Bold'), local('ModeratoG3L-Bold'),
    url('../../fonts/moderato/ModeratoG3L-Bold.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG3L-Bold.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG3L-Bold.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG3L-Bold.svg#ModeratoG3L-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato E7 L';
    src: url('../../fonts/moderato/ModeratoE7L.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Moderato E7 L';
    src: local('Moderato E7 L'), local('ModeratoE7L'),
    url('../../fonts/moderato/ModeratoE7L.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoE7L.woff') format('woff'),
    url('../../fonts/moderato/ModeratoE7L.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoE7L.svg#ModeratoE7L') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato E4 L';
    src: url('../../fonts/moderato/ModeratoE4L-Italic.eot');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Moderato E4 L';
    src: local('Moderato E4 L Italic'), local('ModeratoE4L-Italic'),
    url('../../fonts/moderato/ModeratoE4L-Italic.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoE4L-Italic.woff') format('woff'),
    url('../../fonts/moderato/ModeratoE4L-Italic.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoE4L-Italic.svg#ModeratoE4L-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato G5 L';
    src: url('../../fonts/moderato/ModeratoG5L-Bold.eot');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Moderato G5 L';
    src: local('Moderato G5 L Bold'), local('ModeratoG5L-Bold'),
    url('../../fonts/moderato/ModeratoG5L-Bold.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG5L-Bold.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG5L-Bold.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG5L-Bold.svg#ModeratoG5L-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato G9 L';
    src: url('../../fonts/moderato/ModeratoG9L-Italic.eot');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Moderato G9 L';
    src: local('Moderato G9 L Italic'), local('ModeratoG9L-Italic'),
    url('../../fonts/moderato/ModeratoG9L-Italic.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG9L-Italic.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG9L-Italic.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG9L-Italic.svg#ModeratoG9L-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato G5 L';
    src: url('../../fonts/moderato/ModeratoG5L-Italic.eot');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Moderato G5 L';
    src: local('Moderato G5 L Italic'), local('ModeratoG5L-Italic'),
    url('../../fonts/moderato/ModeratoG5L.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG5L.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG5L-Italic.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG5L-Italic.svg#ModeratoG5L-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato G7 L';
    src: url('../../fonts/moderato/ModeratoG7L.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Moderato G7 L';
    src: local('Moderato G7 L'), local('ModeratoG7L'),
    url('../../fonts/moderato/ModeratoG7L.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG7L.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG7L.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG7L.svg#ModeratoG7L') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato G5 L';
    src: url('../../fonts/moderato/ModeratoG5L.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Moderato G5 L';
    src: local('Moderato G5 L'), local('ModeratoG5L'),
    url('../../fonts/moderato/ModeratoG5L.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG5L.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG5L.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG5L.svg#ModeratoG5L') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moderato G5 L';
    src: url('../../fonts/moderato/ModeratoG5L-BoldItalic.eot');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Moderato G5 L';
    src: local('Moderato G5 L Bold Italic'), local('ModeratoG5L-BoldItalic'),
    url('../../fonts/moderato/ModeratoG5L-BoldItalic.woff2') format('woff2'),
    url('../../fonts/moderato/ModeratoG5L-BoldItalic.woff') format('woff'),
    url('../../fonts/moderato/ModeratoG5L-BoldItalic.ttf') format('truetype'),
    url('../../fonts/moderato/ModeratoG5L-BoldItalic.svg#ModeratoG5L-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans';
    src: url('../../fonts/SofiaSans-Light.eot');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Sofia Sans';
    src: local('Sofia Sans Light'), local('SofiaSans-Light'),
    url('../../fonts/SofiaSans-Light.woff2') format('woff2'),
    url('../../fonts/SofiaSans-Light.woff') format('woff'),
    url('../../fonts/SofiaSans-Light.ttf') format('truetype'),
    url('../../fonts/SofiaSans-Light.svg#SofiaSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Sans';
    src: url('../../fonts/SofiaSans-Medium.eot');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Sofia Sans';
    src: local('Sofia Sans Medium'), local('SofiaSans-Medium'),
    url('../../fonts/SofiaSans-Medium.woff2') format('woff2'),
    url('../../fonts/SofiaSans-Medium.woff') format('woff'),
    url('../../fonts/SofiaSans-Medium.ttf') format('truetype'),
    url('../../fonts/SofiaSans-Medium.svg#SofiaSans-Medium') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

