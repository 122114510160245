@media only screen and (min-width: 1400px) and (max-width: 4000px) {
    .intro h2 {
        font-size: 52px;
        line-height: 60px;
        margin: 0 0 30px 0;
    }

    .intro.intro-wine-tests h2 {
        font-size: 65px;
        line-height: 90px;
    }

    .event-row {
        margin-right: 250px;
    }

    .events-list .cstm-pagination {
        padding-right: 125px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .christmas-landing {
        min-height: 800px;
    }

    .christmas-landing .heading-2 {
        font-size: 84px;
        line-height: 90px;
    }

    .christmas-landing .heading-2 span {
        font-size: 100px;
        padding: 24px 0;
        line-height: 86px;
    }

}

@media only screen and (min-width: 1200px) {
    .select-product .container {
        max-width: 1140px;
    }


}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .mystery-box .hero-text {
        height: 535px;
    }

    .mystery-box .hero-text h1 {
        font-size: 65px;
        line-height: 85px;
    }

    .mystery-box .hero-text h2 {
        font-size: 25px;
        letter-spacing: 2px;
        line-height: 33px;
    }

    .mystery-box .hero-text a.btn {
        margin: 20px auto 0 auto;
        font-size: 16px;
    }

    .choose-mystery-box .mystery-box-package ul {
        min-height: 440px;
    }

    .intro-seewines-magnum .intro-text {
        padding: 65px 0 60px 0;
    }

    .seewines-magnum .benefits .benefit h5,
    .seewines-magnum .benefits .benefit p {
        padding: 0;
    }

    .bellini-intro .banner-intro {
        padding: 0 45px;
    }

    .bellini-intro .intro-bottle img {
        margin-left: -106px;
        width: 212px;
    }

    .participate-steps .step p {
        padding: 0 38px;
    }

    .top .top-item {
        margin-right: 23px;
    }

    .select-product .owl-thumbs {
        margin: 0 0 3rem 0;
    }

    .select-product h5 {
        margin: 0 0 0.5rem 0;
        font-size: 16px;
    }

    .special-holiday img {
        margin-top: 0;
    }

    .event .event-action .from-us .form-container {
        padding: 35px 25px 15px;
    }

    .event .event-date {
        padding: 38px 20px 25px 20px;
    }

    .event .event-date span {
        font-size: 45px;
        margin-right: 10px;
    }

    .event .event-info {
        padding: 5px 20px;
    }

    .christmas-landing {
        min-height: 626px;
        padding-bottom: 50px;
    }

    .christmas-landing .heading-2 {
        font-size: 66px;
        line-height: 72px;
    }

    .christmas-landing .heading-2 span {
        font-size: 82px;
        line-height: 80px;
        padding: 20px 0;
    }

    .christmas-landing .cont {
        padding-top: 160px;
    }

    .christmas-landing .block-title {
        font-size: 23px;
        top: 38px;
    }

    .owl-history .tab-content {
        margin: 0 0 0 150px;
        padding: 100px 10px 50px 235px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {
    .our-sommelier .sommeliers-img img {
        max-height: none;
        width: 100%;
    }
}

@media only screen and (min-width: 0px) and (max-width: 991px) {

    .mystery-box .mystery-box-info img {
        margin: 0 0 20px 0;
    }

    .seewines-magnum .benefits .benefit h5,
    .seewines-magnum .benefits .benefit p {
        padding: 0;
    }

    .special-holiday img {
        margin-top: 0;
    }

    .wine-tests-page .benefits .benefit p {
        padding: 0;
    }

    .event .event-action-container {
        border: none;
    }

    .mystery-box .mystery-box-info a.btn {
        display: table;
        margin: 20px auto 0 auto;
    }

    .mystery-box .mystery-box-info .video-holder {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {

    .about-stat .val {
        font-size: 50px;
        line-height: 50px;
    }

    .about-stat .lab span {
        margin: 0;
    }

    .about-stat .lab {
        font-size: 18px;
        line-height: 24px;
    }

    .nav-history {
        max-width: 250px;
        padding: 15px;
    }

    .owl-history .tab-content {
        margin: 0 0 0 150px;
        padding: 100px 10px 50px 140px;
    }

    .owl-history .tab-content h3 {
        font-size: 40px;
        line-height: 46px;
    }


    .create-label:after {
        display: none;
    }

    .contacts .contact-info {
        padding: 35px 0 0 0;
    }

    .page-info {
        padding: 10px 0;
        margin: 0 0 10px 0;
    }

    .big-space {
        padding: 50px 0;
    }

    .space {
        padding: 50px 0;
    }

    .nav-btn {
        z-index: 105;
        width: 30px;
        height: 18px;
        cursor: pointer;
        position: relative;
        top: 5px;
    }

    .nav-btn span {
        display: block;
        height: 4px;
        background-color: #4e5257;
        position: absolute;
        right: 0;
        top: 0;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;

        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {
        width: 100%;
    }

    .nav-btn span:nth-child(2) {
        width: 100%;
        top: 7px;
    }

    .nav-btn span:nth-child(3) {
        width: 100%;
        top: 14px;
    }

    .nav-btn:hover span:nth-child(2) {
        width: 100%;
    }

    .nav-btn:hover span:nth-child(3) {
        width: 100%;
    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #3c5a5d;
        right: -5px;
    }

    .nav-btn.open span:nth-child(1) {
        width: 100%;
        transform: rotate(45deg);
        top: -3px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg);
        top: 18px;
    }

    header {
        padding: 15px 0 15px 0;
    }

    header.fixed {
        padding: 15px 0;
    }

    header .logo {
        margin: 0 auto;
        display: table;
    }

    header .logo img {
        height: 32px;
    }

    .heading {
        margin: 0 0 30px 0;
        font-size: 40px;
        line-height: 46px;
    }

    header.error-page .logo {
        transform: translate(-50%, -50%);
    }

    header.error-page .logo {
        height: 40px;
    }

    .team p {
        width: 100%;
    }

    .top .top-item.phone {
        margin-right: 0;
        margin-top: 5px;
    }

    .newsletter .hint {
        padding: 0 80px;
        font-size: 13px;
    }

    .newsletter .btn {
        margin: 15px 0 0 0;
    }

    .blog-list .blog-item h3 a {
        font-size: 20px;
        line-height: 26px;
    }


    .intro h2 {
        font-size: 40px;
        line-height: 50px;
    }

    .intro.intro-wine-tests h2 {
        max-width: 473px;
    }

    .intro.intro-wine-tests .carousel-caption {
        padding: 50px 0 85px 0;
    }


    .home .benefits {
        padding: 50px 0 15px;
    }

    .home .benefits .benefit {
        position: relative;
        margin: 0 0 35px 0;
    }

    .home .benefits .benefit h5 {
        padding: 0;
        height: inherit
    }

    .home .benefits .benefit .benefit-img {
        height: 60px;
        margin: 0 0 10px 0;
    }

    .home .benefits .benefit .benefit-img img {
        height: 60px;
        margin: 0 auto;
        display: block;
        max-width: 100px;
    }


    .newsletter .btn {
        margin: 0 0 0 0;
    }


    .product .product-variants {
        padding: 15px 15px 5px 15px;
    }

    .blog-list .blog-item .blog-post-content {
        padding: 20px 15px;
    }

    .blog-list .blog-item h3 {
        min-height: 46px;
    }

    /*.product .product-variants .product-variant {*/
    /*    margin: 0 0 10px 0;*/
    /*}*/
    .product .product-features {
        padding: 50px 0;
    }

    .product .product-sort {
        margin-bottom: 25px;
    }

    .suitable-foods {
        margin: 20px 0 0 0;
        padding: 20px 0 0 0;
        border-top: 1px solid #e3e2e2;
    }

    .product .assortment {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .similar-products {
        padding-left: 0;
        padding-right: 0;
        padding-top: 50px;
        padding-bottom: 5px;
    }


    .events .event-space {
        margin-bottom: 16px;
    }

    .events .event .event-info .event-meta .slots {
        margin-left: 0;
        display: block;
    }

    .events .event:hover .event-info .btn {
        max-height: 36px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    .events .event .event-info {
        padding: 25px 20px 0;
    }

    .events .view-all {
        margin: 40px auto 0;
    }


    .blog-list .view-all {
        margin: 30px auto 0;
        display: table;
    }


    .product-recently {
        padding-top: 50px;
    }


    .offers .main-offer-text h3 {
        font-size: 18px;
        letter-spacing: 3px;
    }

    .offers .main-offer-text {
        bottom: 18%;
        padding-top: 10px;
        padding-bottom: 10px;
    }


    .assortment .assortment-item {
        border-bottom: 30px solid #fff;
    }


    .products-list .product-item {
        margin-bottom: 50px;
    }

    .products-list .product-item .product-action {
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: 0 0 0 0;
        transform: translate(0, 0px);
    }

    /*
    .products-list .product-item:hover .product-img {
        transform: scale(1.3);
    }
    */
    .products-list.product-recently .product-item .product-prices .price,
    .products-list.product-recently .product-item .product-prices .old-price {
        font-size: 14px;
    }

    .products-list.product-recently .product-item .product-name {
        font-size: 14px;
        line-height: 22px;
    }

    .products-list .product-item:hover .product-img:after {
        margin-left: -70px;
    }


    .special-offers {
        padding-bottom: 0;
    }


    .search {
        max-width: 100%;
        display: none;
        margin: 0 0 15px 0;
    }


    .blog-view h1 {
        font-size: 32px;
        line-height: 42px;
    }

    .blog-view .blog-main-img {
        max-height: 350px;
    }


    .blog-similar.blog-list .blog-item {
        margin-bottom: 50px;
    }


    .blog-list .blog-filter {
        margin-left: -5px;
        margin-bottom: 20px;
    }

    .blog-list .blog-filter li:first-of-type {
        margin-left: 5px;
    }

    .blog-list .blog-filter li {
        margin: 0 0 0 5px;
    }

    .blog-list .blog-filter li a {
        padding: 3px 10px;
        margin: 0 0 5px 0;
    }


    .mystery-box .video iframe {
        height: 250px;
        margin: 0 0 30px 0;
    }

    .mystery-box .video .heading {
        margin: 0 0 20px 0;
    }

    .mystery-box .benefits .benefit p {
        padding: 0 0;
    }

    .mystery-box .package .package-price {
        margin: 30px auto 0;
        display: table;
    }


    .event .event-content {
        margin-top: 15px;
        margin-bottom: 0;
    }

    .event .event-desc h1 {
        font-size: 22px;
        line-height: 28px;
        margin: 0 0 15px 0;
    }

    .event .event-desc .event-meta {
        padding: 15px 15px 0 15px;
    }

    .event .event-desc {
        padding: 27px 20px 20px 20px;
    }

    .event .event-action {
        padding: 0 20px 25px 20px;
    }

    .event .event-action .btn {
        height: inherit;
        line-height: inherit;
        padding: 10px 15px;
        margin-top: 25px;
    }

    .event .event-desc .event-meta p {
        margin: 0 0 20px;
    }

    .event .event-short {
        background-color: #fff;
    }

    .event .event-date {
        padding: 10px 15px 25px 15px;
    }

    .event .event-info {
        padding: 5px 15px;
    }


    .user-widgets .widget-item .icn {
        height: 20px;
    }


    .promo-code {
        margin: 30px 0 30px 0;
    }


    footer {
        background-image: none;
        padding: 50px 0 30px;
    }

    footer .footer-menu {
        margin: 0 0 30px 0;
    }

    footer h3 {
        margin: 0 0 10px 0;
    }

    footer ul li {
        margin-bottom: 3px;
    }

    footer .footer-contacts.visit-us li {
        padding-left: 28px;
    }

    footer .footer-contacts li {
        color: #b3b3b3;
        margin-bottom: 20px;
        padding-left: 0;
    }

    footer .footer-logo {
        margin: 0 auto 40px;
        height: 100px;
    }


    .assortment-img img {
        width: 100%;
    }

    .assortment .assortment-item h3 {
        font-size: 14px;
        line-height: 18px;
        height: 37px;

        display: flex;
        align-items: center;
        justify-content: center;
    }


    .events .event-block .event-info h3 {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 5px 0;
    }

    .events .event-block .event-info .event-meta {
        font-size: 13px;
    }

    .events .event-block .event-info .event-meta .slots {
        display: block;
        margin: 0;
    }


    .countries .view-all {
        float: none;
        padding: 11px 0;
        text-align: center;
    }

    .countries .country {
        padding: 10px 3px;
    }


    .products-list .product-item .product-desc {
        color: #6A6665;
        min-height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    header.fixed ~ .page {
        padding-top: 62px !important;
    }


    .mystery-box .package .package-info {
        padding: 0 0 0 10px;
    }

    .bottles {
        padding: 30px;
    }

    .events-filter {
        margin: 0 0 20px 0;
    }

    .events-filter .btn {
        height: 38px;
        line-height: 38px;
        width: 100%;
    }

    .event-row .event-info .event-desc h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .sticky-top {
        top: 65px;
        z-index: 3;
    }

    .event .event-date span {
        margin-bottom: 15px;
    }

    .blog-similar.blog-list .blog-item h3 a {
        font-size: 14px;
        line-height: 18px;
    }

    .team .person img {
        margin: 0 auto;
        display: block;
    }

    .contacts .contact-info ul li {
        padding: 25px 0 25px 0;
    }

    .contacts .contact-form {
        padding: 55px 15px 80px 15px;
    }

    .total .product-row .product-name {
        font-size: 11px;
        line-height: 18px;
        margin: 0 0 10px 0;
    }

    .total .product-row .product-old-price {
        text-align: right;
    }


    .products-list .product-item .product-action .btn-secondary-outline {
        display: none;
    }

    .products-list .product-item .product-action .btn-primary {
        margin: 0;
    }

    .profile .profile-content {
        padding: 65px 45px 65px 45px;
    }

    .profile label {
        font-size: 11px;
    }


    .profile .order-details .val {
        margin: 0 0 10px 0;
        line-height: 18px;
    }

    .profile .product-price {
        text-align: left;
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 13px;
        color: #000;
    }

    .profile-orders .order-num,
    .profile-content .order-num {
        padding: 0 0 20px 0;
        font-size: 13px;
        line-height: 20px;
    }

    .profile .order-products {
        border-top: 0;
    }

    .profile .order-products .product-row:first-of-type {
        padding-top: 0;
    }

    .profile .order-details {
        margin: 0 0 20px 0;
    }

    .profile .order-products .product-row .product-name {
        margin: 0;
    }

    .product-recently {
        padding: 50px 15px 0 15px;
    }

    .new-offers {
        padding: 50px 15px 0 15px;
    }

    .profile-content .order-row a.btn {
        max-width: 180px;
    }

    .sum ul li span {
        font-weight: bold;
    }

    .gift-package label {
        margin-left: 5px;
        display: inline;
    }

    .order-auth {
        background: none;
    }

    .profile .profile-content .order-row .order-num span {
        display: block;
        width: 100%;
    }

    .profile .profile-content .order-row .order-num div a.btn {
        width: 230px;
    }

    .mystery-box .hero-text {
        height: 400px;
        padding-bottom: 5px;
        padding-top: 90px;
    }

    .mystery-box .hero-text h1 {
        font-size: 56px;
        line-height: 70px;
        margin-top: 25px;
    }

    .mystery-box .hero-text h2 {
        font-size: 22px;
        letter-spacing: 1px;
        line-height: 33px;
    }

    .our-sommelier {
        padding-bottom: 35px;
    }

    .our-sommelier .sommelier-img {
        padding: 0;
    }

    .our-sommelier .sommelier-img:before {
        width: 42px;
        height: 42px;
    }

    .our-sommelier .sommelier-info {
        padding: 25px 10px 0 10px;
    }

    .our-sommelier .sommelier-info h3 {
        font-size: 18px;
        margin-bottom: 14px;
        line-height: 23px;
    }

    .our-sommelier .sommelier-info p {
        font-size: 13px;
        line-height: 23px;
        margin-bottom: 35px;
    }

    .choose-mystery-box .mystery-box-package.sec {
        margin-top: 0;
    }

    .choose-mystery-box .mystery-box-package.sec .mystery-tittle {
        padding: 14px 0 13px 0;
    }

    .choose-mystery-box .mystery-box-package .mystery-tittle span.wine-type {
        font-size: 14px;
    }

    .choose-mystery-box .mystery-box-package ul {
        min-height: 372px;
    }

    .choose-mystery-box .mystery-box-package.sec ul {
        min-height: 312px;
    }

    .choose-mystery-box .mystery-box-package {
        margin-bottom: 20px;
    }

    /*.comments .mystery-box-comments .comment-block p {*/
    /*    font-size: 15px;*/
    /*    line-height: 23px;*/
    /*}*/
    /*    seewines-magnum*/
    .intro-seewines-magnum .intro-text {
        padding: 50px 0 50px 0;
    }

    .intro-seewines-magnum .intro-text .heading {
        font-size: 40px;
        line-height: 50px;
    }

    .intro-seewines-magnum .intro-text h2.heading {
        margin: 10px auto 16px auto;
    }

    .intro-seewines-magnum .intro-text p {
        font-size: 16px;
    }

    .intro-seewines-magnum .intro-text h2:after {
        margin-left: -12px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift {
        text-align: center;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift img {
        margin: 0;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift span {
        display: block;
        margin: 5px auto 0 auto;
        text-align: center;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .person-type {
        text-align: center;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .person-type img {
        max-width: 48px;
        max-height: 52px;
        margin: 0 auto 5px auto;
    }

    .bellini-game .from-us .form-container {
        width: 365px;
    }

    .bellini-intro .banner-intro {
        padding: 0 25px;
    }

    .bellini-intro .intro-bottle img {
        margin-left: -90px;
        width: 180px;
    }

    .how-to-participate .info-btn .btn {
        letter-spacing: 1px;
        margin: 0 5px;
    }

    .participate-steps .step p {
        font-size: 16px;
        padding: 0 23px;
    }

    .bellini-game .from-us .form-container h5 {
        font-size: 31px;
        line-height: 46px;
    }

    .select-product .owl-thumb-item img {
        height: 6rem;
    }

    .select-product h5 {
        font-size: 14px;
        margin: 0 0 0.3rem 0;
    }

    .select-product .owl-thumb-item {
        margin: 0 0.5rem;
    }

    .select-product .heading {
        font-size: 2rem;
        line-height: 2.6rem;
    }

    .select-product .bot-info {
        margin: 0 0 1rem 0;
    }

    .select-product .bot-info img {
        height: 1.3rem;
    }

    .select-product .bot-info .bot {
        padding: 0.6rem 1rem;
    }

    .select-product .qty-holder .plus,
    .select-product .qty-holder .minus,
    .select-product .qty-holder input {
        height: 40px;
    }

    .select-product .cta {
        height: 42px;
        margin: 1rem 0 0 0;
        line-height: 39px;
    }

    .select-product .owl-thumbs {
        margin: 0 0 1rem 0;
    }

    .event .event-action .from-us.our-team {
        padding-top: 0;
    }

    .event .event-action .from-us.our-team .form-container {
        margin-top: 0;
    }

    .wine-tests-page .heading-2 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 25px;
    }

    .special-holiday {
        padding: 65px 0;
    }

    .subscribe-land {
        min-height: 1120px;
    }

    .subscribe-land .heading {
        font-size: 28px;
        line-height: 45px;
    }

    .subscribe-steps .subscribe-step .step-text {
        font-size: 14px;
    }

    .subscribe-land .left-shape,
    .subscribe-land .right-shape {
        max-width: 200px;
    }

    .our-sommelier .sommeliers-img img {
        margin-bottom: 45px;
    }

    .mystery-box .box-for-us .heading {
        line-height: 31px;
    }

    .mystery-box .box-for-us .box-block h5 {
        margin-top: 22px;
        font-size: 24px;
        line-height: 33px;
        padding: 0 20px;
    }

    .christmas-landing {
        min-height: 460px;
        padding-bottom: 50px;
        margin-top: -94px;
    }

    .christmas-landing.questionnaire {
        margin-top: -110px;
    }

    .christmas-landing .heading-2 {
        font-size: 50px;
        line-height: 58px;
        margin: 0 0 30px 0;
    }

    .christmas-landing .heading-2 span {
        font-size: 58px;
        padding: 8px 0;
        line-height: 63px;
    }

    .christmas-landing .cont {
        padding-top: 118px;
    }

    .christmas-header .logo img {
        height: 32px;
    }

    .christmas-header .christmas-nav ul li a {
        font-size: 16px;
        letter-spacing: 0;
    }

    .christmas-landing .btn {
        font-size: 13px;
        min-width: 250px;
        min-height: 46px;
        line-height: 45px;
    }

    .christmas-header .christmas-nav ul li a img {
        margin-top: -22px;
        margin-left: 5px;
        height: 43px;
    }

    .christmas-landing .block-title {
        font-size: 23px;
        padding-top: 30px;
        top: 80px;
    }

    .christmas-landing .heading {
        font-size: 34px;
        line-height: 44px;
        margin: 0 0 30px 0;
    }

    .christmas-landing.questionnaire form .form-group label .quest-img {
        height: 45px;
        margin-bottom: 20px;
    }

    .christmas-landing.questionnaire form .form-group label img {
        width: 50px;
    }

    .christmas-landing.questionnaire form .form-group label {
        font-size: 12px;
        padding: 30px 12px;
    }

    .christmas-landing .questionnaire-pagination li a {
        width: 50px;
        height: 50px;
        line-height: 48px;
    }

    .christmas-landing .questionnaire-pagination li a img {
        width: 8px;
    }


    .timeline-stage {
        background: none !important;
        margin-bottom: 50px;
    }

    .timeline .timeline-stage .timeline-image {
        min-height: inherit;
    }

    .timeline {
        margin-top: 0;
        padding: 50px 0;
    }

    .timeline .year {
        margin: 15px 0 5px 0;
    }

    .timeline .timeline-stage .timeline-nav {
        margin: 10px 0 0 0;
    }

    .timeline .timeline-stage .timeline-nav li a {
        padding: 5px 15px 5px 0;
    }

    .timeline .year {
        font-size: 36px;
        line-height: 36px;
    }

    .section-02 .text-right-side {
        padding: 0 0 0 50px;
        width: 50%;
        float: right;
    }

    .section-01 .text-left-side {
        padding: 0 50px 0 0;
        width: 50%;
    }


    .categories .page-intro,
    .contacts .page-intro,
    .partners .page-intro,
    .team-page .page-intro,
    .blog-list .page-intro,
    .distillery .page-intro,
    .vineyards .page-intro,
    .about-us .page-intro {
        padding: 100px 0 100px 0;
    }

    .intro-text h1,
    .page-intro h1 {
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 5px;
    }

    .intro-text p {
        font-size: 17px;
        line-height: 24px;
    }

    .plantation-info {
        text-align: center;
        padding: 50px 0 25px 0;
    }

    .plantation-info .val {
        margin: 0 0 25px 0;
    }

    .sub-heading {
        width: 100%;
    }

    .vineyards .plantations .plantation-text {
        padding: 50px 0 50px 0;
    }

    .vineyards .plantations .plantation-small-text {
        padding: 0 0 50px 0;
    }

    .vineyards .plantations .plantation-01 {
        margin: 0;
    }

    .plantation-work .plantation-work-img {
        padding: 0 0 0 0;
    }

    .plantation-work h2 {
        margin: 30px 0 20px 0;
    }

    .plantation-work .img-bottom {
        position: inherit;
        bottom: inherit;
        right: inherit;
        width: 100%;
    }

    .plantation-01,
    .plantation-work .plantation-work-img {
        width: 100%;
    }

    .plantation-info .val {
        font-size: 19px;
        line-height: 28px;
    }


    .section-01 .section-01-middle {
        width: 65%;
        position: relative;
        bottom: 0;
        left: 0;
    }

    .order-auth .heading-icon {
        height: 18px;
        vertical-align: baseline;
        margin: 0 5px 0 0;
    }

    .order-auth .heading {
        margin: 0 0 20px 0;
    }

    .offers .small-offer .lab {
        width: 100%;
        text-align: center;
        font-size: 36px;
        line-height: 46px;
    }

    .product h1 {
        font-size: 30px;
        line-height: 40px;
    }

    .product .product-desc {
        margin: 15px 0 25px;
    }

    .product-quantity {
        margin: 0 0 15px 0;
    }

    .product .product-info .buy {
        width: 100%;
    }

    .mega-menu .menu-heading {
        margin: 0 0 15px 0;
    }

    .mega-menu ul li a {
        margin: 5px 0;
    }


    .owl-fix-pos {
        padding-left: 0;
    }

    .owl-projects .owl-nav button.owl-prev {
        left: 3%
    }

    .owl-projects .owl-nav button.owl-next {
        right: 3%
    }

    .team-block img {
        margin: 0 0 15px 0;
    }

}

@media only screen and (min-width: 0px) and (max-width: 767px) {

    .nav-history {
        max-width: 100%;
        background-color: #F5F3EF;
        margin: 0 0 30px 0;
        position: inherit;
        top: inherit;
        left: inherit;
    }

    .owl-history .tab-content {
        background-color: #ffffff;
        margin: 0;
        padding: 15px;
    }

    .owl-history .tab-content h3 {
        font-size: 30px;
        line-height: 40px;
        margin: 0;
    }

    .owl-history .tab-content .tab-pane p:last-child {
        margin: 0;
    }

    .year-nav {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .year-nav::-webkit-scrollbar {
        display: none; /* Скриване на скролбара за WebKit браузъри */
    }

    .year-nav .year-btn {
        word-wrap: break-word;
    }

    .about-stat .stat {
        margin: 15px 0;
    }

    .about-stat .val {
        font-size: 50px;
        line-height: 50px;
    }

    .about-stat .lab span {
        margin: 0;
    }

    .about-stat .lab {
        font-size: 18px;
        line-height: 24px;
    }

    .create-label .heading {
        margin: 0 0 10px 0;
    }

    .create-label:after {
        display: none;
    }

    .label-steps {
        padding: 0;
    }

    .step-arrow {
        width: 58px;
        margin-top: 23px;
    }

    .textpage-nav {
        background-color: #F7F5F2;
        padding: 50px 0 30px 15px;
    }

    .characteristics .characteristic {
        padding: 15px 20px;
        margin: 0 0 15px 0;
        min-height: inherit;
        text-align: center;
    }

    .characteristics .characteristic .lab {
        margin: 5px 0;
    }

    .back-to-shop {
        display: table;
        margin: 0 0 15px 0;
    }

    header.fixed {
        padding: 5px 0;
    }

    .order-auth .heading-icon {
        height: 18px;
        vertical-align: baseline;
        margin: 0 5px 0 0;
    }

    .mega-menu .menu-heading {
        margin: 0 0 15px 0;
    }

    .mega-menu {
        padding: 90px 0 0;
        overflow: scroll;
    }

    .mega-menu ul {
        margin: 0 0 40px 0;
    }

    .mega-menu ul li a {
        font-size: 17px;
        line-height: 24px;
        margin: 5px 0;
    }

    .go-to-nav button {
        margin: 0 0 5px 0 !important;
    }

    .go-to-nav button,
    .go-to-nav a {
        width: 100%;
    }

    .cart-delivery-hint {
        line-height: 20px;
        margin: 0 0 20px 0;
    }

    .user-lang li {
        padding: 0 10px;
    }

    .user-lang li:before {
        top: 13px;
        left: -1px;
    }

    .plantation-info .val {
        font-size: 19px;
        line-height: 26px;
    }

    .plantation-work .plantation-work-img {
        padding: 0 0 0 0;
    }

    .plantation-work h2 {
        margin: 30px 0 20px 0;
    }

    .plantation-work .img-bottom {
        position: inherit;
        bottom: inherit;
        right: inherit;
        width: 100%;
    }

    .plantation-work .plantation-work-text {
        padding: 0 0 0 0;
    }

    .plantation-info {
        text-align: center;
    }

    .plantation-info .val {
        margin: 0 0 25px 0;
    }

    .vineyards .plantations .plantation-text {
        padding: 50px 0 50px 0;
    }

    .vineyards .plantations .plantation-small-text {
        padding: 0 0 50px 0;
    }

    .vineyards .plantations .plantation-01 {
        margin: 0 0 0 0;
    }

    .cart-list .cart-list .product-old-price {
        text-align: center;
    }

    .cart-list .product-img {
        text-align: center;
    }

    .cart-list .cart-product-price {
        text-align: center;
        display: block;
    }

    .cart-list .remove-product {
        position: absolute;
        top: 50%;
        left: 0;
        right: inherit;
        font-size: 15px;
        height: 28px;
        margin: -14px 0 0 0;
    }

    .page-info {
        padding: 10px 0;
        margin: 0 0 0 0;
    }

    .space-bot {
        padding-bottom: 50px;
    }

    .big-space {
        padding: 50px 0;
    }

    .space {
        padding: 35px 0;
    }

    .plantation-info img {
        height: 50px;
        margin: 0 0 10px 0;
    }

    .nav-btn {
        z-index: 105;
        width: 30px;
        height: 18px;
        cursor: pointer;
        position: relative;
        top: 5px;
    }

    .nav-btn span {
        display: block;
        height: 4px;
        background-color: #4e5257;
        position: absolute;
        right: 0;
        top: 0;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;

        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {
        width: 100%;
    }

    .nav-btn span:nth-child(2) {
        width: 100%;
        top: 7px;
    }

    .nav-btn span:nth-child(3) {
        width: 100%;
        top: 14px;
    }

    .nav-btn:hover span:nth-child(2) {
        width: 100%;
    }

    .nav-btn:hover span:nth-child(3) {
        width: 100%;
    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #3c5a5d;
        right: -5px;
    }

    .nav-btn.open span:nth-child(1) {
        width: 100%;
        transform: rotate(45deg);
        top: -3px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg);
        top: 18px;
    }

    header {
        padding: 5px 0 0 0;
    }

    header .logo {
        margin: 0 auto;
        display: table;
    }

    header .logo img {
        height: 32px;
    }

    .heading {
        margin: 0 0 20px 0;
        font-size: 28px;
        line-height: 32px;
        width: 100% !important;
    }

    .delivery-hint span {
        display: block;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
    }

    .delivery-hint .sep {
        display: none;
    }

    .delivery-hint {
        padding: 4px 0;
    }

    .top .top-item {
        text-align: center;
        display: inline-block;
    }

    .top .top-item.social {
        margin-right: 0;
        float: right;
    }

    .top .top-item:after {
        display: none;
    }

    .top .top-item.phone {
        display: none;
    }

    .top .top-item.vouchers {
        display: none;
    }

    .search {
        max-width: 100%;
        display: none;
        margin: 0 0 15px 0;
    }

    .user-widget .user-nav:after {
        width: 30px;
        left: inherit;
        right: 0;
    }

    .owl-fix-pos {
        padding-left: 0;
    }

    .owl-projects .owl-nav button.owl-prev {
        left: 3%
    }

    .owl-projects .owl-nav button.owl-next {
        right: 3%
    }

    .user-widget .user-nav {
        top: 36px;
        left: inherit;
        right: 0;
    }

    .user-widgets .widget-item .cart-sum {
        display: none;
    }

    .user-widgets .widget-item.fav-widget {
        display: none;
    }

    .user-widgets {
        text-align: right;
        padding: 10px 0 15px;
    }

    .cart-widget:after {
        bottom: -16px;
        z-index: 15;
    }

    .user-widget .user-nav:before,
    .cart-container:before {
        display: none
    }

    .cart-container {
        top: 37px;
    }


    .newsletter .hint {
        padding: 0;
        font-size: 14px;
    }

    .newsletter .btn {
        margin: 15px 0 0 0;
    }


    .home .benefits {
        padding: 50px 0 15px;
        overflow: hidden;
        position: relative;
    }

    .home .benefits .benefit {
        position: relative;
        margin: 0 0 35px 0;
    }

    .home .benefits .benefit h5 {
        padding: 0;
        height: inherit
    }

    .home .benefits .benefit .benefit-img {
        height: 60px;
        margin: 0 0 10px 0;
    }

    .home .benefits .benefit .benefit-img img {
        height: 60px;
        margin: 0 auto;
        display: block;
        max-width: 100px;
    }


    .similar-products,
    .product-recently,
    .new-offers {
        padding-left: 0;
        padding-right: 0;
        padding-top: 50px;
        padding-bottom: 5px;
    }

    .new-offers.products-list .product-item .product-name {
        min-height: 67px;
    }

    .offers .main-offer-text h3 {
        font-size: 14px;
        letter-spacing: 3px;
    }

    .offers .main-offer-text {
        bottom: 11%;
        padding-top: 0;
        padding-bottom: 0;
    }


    .offers .main-offer:hover .btn,
    .offers .small-offer:hover .btn {
        opacity: 0;
    }

    .offers .small-offer {
        margin: 0 0 30px 0;
    }

    .home .blog-list .blog-item,
    .blog-list .blog-item,
    .events .event,
    .assortment .assortment-item {
        margin: 0 0 30px 0;
    }


    .events .event-block:hover .event-info .btn {
        margin-bottom: 0;
    }

    .events .event-block .event-info .btn {
        padding: 0;
        background-color: transparent;
        border: 0;
        color: #E30615;
        line-height: 1.5;
        text-decoration: underline;
        max-height: 20px;
    }

    .events .event-block .event-info {
        padding: 25px 20px 0;
    }


    .events .event .event-info .event-meta .slots {
        margin-left: 0;
        display: block;
    }

    .events .event:hover .event-info .btn {
        max-height: 36px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    .events .event .event-info {
        padding: 25px 20px 0;
    }

    .events .view-all {
        margin: 15px auto 0;
    }


    .blog-list .blog-item h3 a {
        font-size: 18px;
        line-height: 24px;
    }

    .blog-list .view-all {
        margin: 0 auto 0;
    }


    .products-list .product-item {
        margin-bottom: 50px;
    }

    .products-list .product-item .product-action {
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: 0 0 0 0;
        transform: translate(0, 0);
    }

    .add-review .review-info {
        padding: 30px 15px;
    }

    .add-review .product-rate > ul {
        display: block;
        margin-left: 0;
    }

    .add-review .product-rate > .product-stars {
        display: block;
        float: left;
        margin: 15px 0 30px 0;
        position: relative;
    }

    .add-review .form-hint {
        padding-right: 20px;
    }

    .add-review label {
        font-size: 13px;
    }

    .add-review .review-img {
        margin: 30px auto 30px;
        display: block;
        padding-left: 0;
    }

    /*    .products-list .product-item:hover .product-img {
            transform: scale(1.3);
        }*/
    .products-list.product-recently .product-item .product-prices .price,
    .products-list.product-recently .product-item .product-prices .old-price {
        font-size: 14px;
    }

    .products-list.product-recently .product-item .product-name {
        font-size: 14px;
        line-height: 22px;
    }

    .products-list .product-item:hover .product-img:after {
        margin-left: -70px;
    }


    .special-offers {
        padding-bottom: 0;
    }


    .assortment-img img {
        width: 100%;
    }

    .assortment .assortment-item {
        min-height: inherit;
    }

    .assortment .assortment-item .assortment-info {
        padding: 20px 30px 30px;
    }

    .assortment .assortment-item .btn {
        position: inherit;
        bottom: inherit;
        min-width: inherit;
        left: inherit;
        margin-left: inherit;
    }

    .assortment .assortment-item h3 {
        font-size: 14px;
        line-height: 18px;
        height: inherit;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .assortment .assortment-item .btn {
        padding: 0 15px;
    }


    .products-list .product-item .product-action .btn {
        padding: 5px 5px;
        height: inherit;
        line-height: 18px;
        letter-spacing: 0;
        font-size: 10px;
    }


    .login {
        background: none;
    }


    .blog-list .hero-area {
        padding: 50px 0;
    }

    .blog-list .hero-area h1 {
        font-size: 30px;
        line-height: 40px;
        margin: 0 0 0 0;
    }

    .blog-list .blog-item {
        margin-bottom: 20px;
    }

    .blog-list .blog-filter {
        margin-left: -5px;
        margin-bottom: 20px;
    }

    .blog-list .blog-filter li:first-of-type {
        margin-left: 5px;
    }

    .blog-list .blog-filter li {
        margin: 0 0 0 5px;
    }

    .blog-list .blog-filter li a {
        padding: 3px 10px;
        margin: 0 0 5px 0;
    }

    .team-list .team-block .person-desc {
        padding: 0;
    }


    .blog-view h1 {
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 20px 0;
    }

    .blog-view figure.image[style] {
        width: 100% !important;
    }

    .blog-view .meta {
        text-align: center;
        margin: 0 0 30px 0;
    }

    .blog-view .meta .date {
        margin: 0 0 0 0;
    }

    .blog-similar.blog-list .blog-item {
        margin-bottom: 40px;
    }

    .blog-similar.blog-list.space {
        padding: 50px 0;
    }

    .login .space, .register .space {
        padding: 30px 0;
    }

    .register h1, .login h1 {
        font-size: 26px;
        line-height: 30px;
        margin: 0 0 15px 0;
    }

    .mystery-box .hero-text {
        height: auto;
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .mystery-box .hero-text h1 {
        font-size: 26px;
        line-height: 46px;
        margin-bottom: 2px;
    }

    .mystery-box .hero-text h2 {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 22px;
    }

    .mystery-box .hero-text a.btn {
        margin: 10px auto 0 auto;
        font-size: 11px;
        height: 35px;
        padding-top: 0;
        line-height: 33px;
    }

    .mystery-box .hero h3 {
        font-size: 14px;
        letter-spacing: 3px;
    }

    .mystery-box .video .btn {
        display: block;
    }

    .mystery-box .video iframe {
        height: inherit;
        margin: 0 0 30px 0;
    }

    .mystery-box .video .heading {
        margin: 0 0 20px 0;
    }

    .mystery-box .benefits,
    .wine-tests-page .benefits {
        padding: 50px 0 0 0;
    }

    .mystery-box .package .bottles {
        max-height: 200px;
        margin: 0 auto;
        display: block;
    }

    .mystery-box .package .package-info {
        padding: 30px 0;
    }

    .mystery-box .package .package-price {
        margin: 0 auto;
    }

    .mystery-box .benefits .benefit p {
        padding: 0 0;
    }

    .our-sommelier {
        padding: 30px 0 0 0;
    }

    .our-sommelier h2 {
        margin-bottom: 30px;
    }

    .our-sommelier .sommelier-img:before {
        width: 44px;
        height: 44px;
        margin-bottom: -14px;
        margin-right: -9px;
    }

    .our-sommelier .sommelier-img {
        padding: 0;
    }

    .our-sommelier .sommelier-info {
        padding: 10px;
    }

    .our-sommelier .sommelier-info h3 {
        font-size: 19px;
        margin-bottom: 10px;
    }

    .our-sommelier .sommelier-info p {
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 35px;
    }

    .mystery-box .mystery-box-info h3 {
        margin-bottom: 18px;
    }

    .mystery-box .mystery-box-info p {
        font-size: 14px;
        line-height: 23px;
    }

    .mystery-box .mystery-box-info a.btn {
        letter-spacing: 1px;
        margin: 15px auto 0 auto;
    }


    .event-row .event-info {
        position: inherit;
        bottom: inherit;
    }

    .event-row:before {
        display: none;
    }

    .event-row .event-info .event-desc {
        margin: 10px 0 0;
        padding: 0;
    }

    .event-row .event-info .event-date small {
        padding-left: 10px;
    }

    .event-row .event-info .event-date span,
    .event-row .event-info .event-date {
        padding: 0;
        margin: 0;
        color: #000;
    }

    .event-row .event-info .event-desc h3 {
        color: #000;
        font-size: 18px;
        line-height: 24px;
    }

    .event-row .event-info .event-desc p {
        color: #6A6665;
        margin: 0;
    }

    .event-row .event-label {
        top: 15px;
        left: 15px;
    }

    .events-filter h4 {
        margin: 0 0 15px 0;
    }

    .events-filter {
        margin: 0 0 30px 0;
    }


    .event .event-content {
        margin-top: 15px;
        margin-bottom: 60px;
        padding: 0 15px;
    }

    .event .event-desc h1 {
        font-size: 22px;
        line-height: 28px;
        margin: 0 0 15px 0;
    }

    .event .event-desc .event-meta {
        padding: 15px 15px 0 15px;
    }

    .event .event-desc {
        padding: 0;
        border: 0;
    }

    .event .event-action {
        padding: 0 0 25px 0;
        margin: 0;
    }

    .event .event-action .from-us.our-team {
        padding-top: 25px;
    }

    .event .event-date {
        padding: 0 0 25px 0;
        margin: 0 0 15px 0;
    }

    .event .event-info {
        padding: 5px 0;
        color: #000;
    }

    .event .event-short {
        background-color: #fff;
        margin: 0 0 25px 0;
        border: none;
    }

    .event .event-action .btn {
        height: inherit;
        line-height: inherit;
        padding: 10px 15px;
    }

    .event .event-desc .event-meta p {
        margin: 0 0 20px;
    }


    .sort-result .form-control {
        margin: 0 0 10px 0;
    }

    .countries .country {
        margin: 0 0 10px 0;
        padding: 5px 10px;
    }

    .countries .view-all {
        float: none;
        padding: 5px 0;
        text-align: center;
    }


    .countries {
        margin: 0 0 20px 0;
        border-bottom: 0;
    }


    .maturity {
        padding: 20px 0;
        border-bottom: 1px solid #e3e2e2;
    }

    .types-cups {
        border-bottom: 1px solid #e3e2e2;
        padding: 0 0 20px 0;
    }

    .product .temperature {
        padding: 20px 0;
        border-bottom: 1px solid #e3e2e2;
    }

    .product .suitable-foods {
        padding: 20px 0;
        border-bottom: 1px solid #e3e2e2;
    }

    .product .suitable-foods ul li,
    .product .product-aromas ul li {
        margin-bottom: 15px;
    }

    .product .product-sort {
        padding: 20px 0;
        margin: 0;
    }

    .product .product-aromas {
        padding: 20px 0 0 0;
    }

    .product .product-sort p {
        margin: 0;
    }

    .product .product-profile {
        margin: 30px 0 30px 0;
    }

    .product .product-desc {
        margin: 20px 0 5px;
    }

    .product .product-features.gray {
        background-color: #FBFAF9;
        padding-bottom: 10px;
    }

    .product .product-features {
        padding: 20px 0;
    }

    .product .product-info {
        padding: 30px 0 0;
    }

    .product .buy {
        width: 100%;
    }

    .product .product-quantity {
        margin: 0 0 10px 0;
    }

    .product .product-variants .product-variant {
        margin-bottom: 10px;
    }

    .variant-error {
        margin: 2px 0 0 0;
    }

    .btn {
        padding: 0 35px;
    }

    .product .product-img {
        margin: 0 0 0 0;
    }


    .product .assortment {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .product .assortment .heading {
        margin-bottom: 30px;
    }


    .comments .heading {
        margin-bottom: 30px;
    }

    .comments .rate-list {
        margin: 0 0 0 0;
    }

    .comments .rating .lab {
        margin: 0 0 5px;
    }

    .comments .rating {
        text-align: center;
    }

    .comments .rate-list .btn {
        margin: 15px auto 29px auto;
        display: table;
        width: 100%;
    }

    .profile:after {
        display: none;
    }

    .user-menu {
        margin: 10px 0 0 0;
    }

    .profile .profile-content {
        margin: 30px 0 30px 0;
        border-top: 1px solid #DCDAD9;
        border-right: 1px solid #DCDAD9;
        padding: 25px 20px 45px 20px;
        height: auto;
        border-bottom: 1px solid #dcdad9;
    }

    .alert-success {
        margin-bottom: 0;
        margin-top: 30px;
    }

    .profile .heading {
        margin: 0 0 20px 0;
    }

    .profile .form-group {
        margin: 0 0 20px 0;
    }

    .products .hero-area {
        padding: 30px 0;
    }

    .products .hero-area h1 {
        font-size: 34px;
        line-height: 40px;
    }


    .cstm-pagination ul li .page-numbers {
        line-height: 26px;
        font-size: 12px;
        width: 30px;
        height: 30px;
        border-width: 1px;
        margin: 0 3px;
    }


    .gift-action {
        margin: 20px 0 0 0;
    }

    .mystery-box-order {
        padding: 0 0 50px 0;
    }

    .mystery-box-order .choose-period {
        margin: 0 0 20px 0;
    }

    .mystery-box-order .choose-period ul {
        width: 100%;
    }

    .mystery-box-order .choose-period ul li {
        width: 100%;
        font-size: 14px;
        display: block;
        border-right: 1px solid #461754;
        border-bottom: 1px solid #461754;
        height: 34px;
        line-height: 34px;
    }

    .mystery-box-order .choose-period ul.has-promos li .badge {
        top: 9px;
        left: 10px;
    }

    .mystery-box-order .choose-period ul li:last-of-type {
        border-bottom: 0;
    }

    .mystery-box-order .choose-date .btn {
        margin: 15px 0 0 0;
    }

    .order-payment .payment-method label .payment-name {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 0 0;
    }

    .order-payment .payment-method label {
        padding: 10px 65px 10px 45px;
    }

    .promo-code {
        margin: 15px 0 15px 0;
    }

    .checkout {
        padding: 0 0 50px 0;
    }

    .mb-cart-heading {
        margin: 15px 0 5px 0;
        color: #afafaf;
    }

    .cart {
        padding: 45px 0 50px;
    }

    .cart-headings {
        display: none;
    }


    footer {
        background-image: none;
        padding: 50px 0 30px;
        text-align: center;
    }

    footer .footer-menu {
        margin: 0 0 30px 0;
    }

    footer h3 {
        margin: 0 0 10px 0;
    }

    footer ul li {
        margin-bottom: 3px;
    }

    footer .footer-logo {
        margin: 0 auto 40px;
        height: 100px;
    }


    .events .event-block {
        margin: 0 0 15px 0;
    }

    .events .event-block .event-info h3 {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 5px 0;
    }

    .events .event-block .event-info .event-meta {
        font-size: 13px;
    }

    .events .event-block .event-info .event-meta .slots {
        display: block;
        margin: 0;
    }

    .events-list .cstm-pagination {
        margin-top: 15px;
    }

    header.fixed ~ .page {
        padding-top: 58px !important;
    }

    .products-list .product-item .product-desc {
        font-size: 12px;
        height: 63px;
        overflow: hidden;
        position: relative;
    }

    .products-list .product-item .product-name {
        font-size: 14px;
        line-height: 18px;
        height: 71px;
    }

    .cart-list .product-desc {
        margin: 0 0 10px 0;
    }

    .total h3 {
        font-size: 16px;
        line-height: 22px;
    }

    .empty-category img {
        height: 150px;
    }


    .event-row .event-info .event-capacity {
        color: #333;
        text-align: left;
        margin: 2px 0 10px 0;
    }

    .event-row .event-info .event-date span {
        font-size: 40px;
        line-height: 44px;
        position: relative;
        top: -4px;
    }

    .contacts .contact-form {
        padding: 55px 15px 80px 15px;
    }

    .contacts:after {
        display: none;
    }

    .contacts .short-map iframe {
        height: 350px;
    }

    .contacts .contact-info ul li:last-of-type {
        border: 0;
    }

    .contacts .contact-form {
        border: 0;
    }

    .contacts .contact-info ul li {
        padding: 25px 10px 25px 0;
    }

    .contacts .contact-info {
        padding: 45px 0 30px 0;
    }

    .promo-code .btn {
        padding: 0 10px;
    }

    .total .product-row .product-name {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 10px 0;
    }

    .total .product-row .product-old-price {
        text-align: right;
    }

    .assortment .assortment-item h3,
    .blog-list .blog-item h3,
    .blog-similar.blog-list .blog-item h3 {
        min-height: inherit;
    }

    .product .product-prices {
        text-align: center;
    }

    .products-list .product-item .product-action .btn-secondary-outline {
        display: none;
    }

    .products-list .product-item .product-action .btn-primary {
        margin: 0;
    }

    .profile .order-details .val {
        margin: 0 0 10px 0;
        line-height: 18px;
    }

    .profile .product-price {
        text-align: left;
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 14px;
        color: #000;
    }

    .profile-orders .order-num,
    .profile-content .order-num {
        padding: 0 0 20px 0;
        font-size: 14px;
        line-height: 20px;
    }

    .profile-content .order-num {
        padding: 10px 0 20px 0;
    }

    .profile .order-products {
        border-top: 0;
    }

    .profile .order-products .product-row:first-of-type {
        padding-top: 0;
    }

    .profile .order-details {
        margin: 0 0 20px 0;
    }

    .profile .order-products .product-row .product-name {
        margin: 0;
    }


    .profile .profile-content .order-row .order-num span {
        display: block;
    }

    .profile .profile-content .order-row .order-num a.pay-again {
        min-width: 100%;
        justify-content: center;
    }

    .profile .profile-content .order-row .order-num .pay-now {
        width: 100%;
    }

    .profile .profile-content .order-row .order-num span.btn-text {
        max-width: 100% !important;
    }

    .profile .profile-content .order-row .buttons-block {
        width: 100%;
        justify-content: space-between;
        margin: 0;
    }

    .profile .profile-content .order-row .order-num div a.btn {
        max-width: 100%;
        width: 100%;
    }

    .profile .profile-content .order-row .cancel-button,
    .profile .profile-content .order-row .btn-order {
        width: 48%;
    }

    .profile .profile-content .order-row .order-num a.replay,
    .profile .profile-content .order-row .order-num a.cancel {
        background: white;
        border: none;
        color: #303539;
    }

    .profile .profile-content .order-row .order-num a.replay:before {
        background: url("../../images/icon_3-gray.svg") center no-repeat;
    }

    /*TODO*/
    .profile-address-list .btn {
        margin: 20px 0 0 0;
    }

    .sidebar .active-filters h4 {
        margin: 0 0 10px 0;
    }

    .sidebar .active-filters {
        padding: 0 0 20px;
    }

    .sidebar .active-filters ul li {
        display: inline-block;
        margin: 0 10px 3px 0;
    }

    .sidebar .widget {
        padding: 10px 0;
    }

    #wo-breadcrumbs {
        padding-bottom: 25px;
        margin-bottom: 0;
    }

    .custom-select {
        font-size: 14px;
    }

    .sidebar.active .widgets {
        display: none;
    }

    .blog-view .blog-main-img {
        margin: 0 0 20px 0;
    }

    .gift-card,
    .gift,
    .order-info,
    .date-info,
    .order-delivery {
        padding: 15px;
        margin: 0 0 15px 0;
    }

    .order-info label {
        font-size: 16px;
    }

    .mystery-box-order .choose-date,
    .mystery-box-order .choose-period {
        background-color: #fff;
        padding: 15px;
    }

    .thankyou .total {
        margin-top: 0px;
    }

    .thankyou h1 {
        font-size: 28px;
        line-height: 32px;
        margin: 0 0 15px 0;
    }

    .upsell .upsell-product .product-action {
        text-align: right;
    }

    .mystery-box-order .config {
        padding: 15px;
    }

    .plantation-work.distillery-plant {
        padding: 30px 0;
    }

    .plantation-work .new-imgs {
        max-height: inherit;
        margin: 15px 0;
        width: 100%;
    }

    .categories .page-intro h1 {
        font-size: 40px;
        line-height: 34px;
        letter-spacing: 5px;
    }

    .mystery-box-order .choose-date h4,
    .mystery-box-order .choose-period h4,
    .mystery-box-order .config h3,
    .order-delivery h4,
    .order-info h3,
    .order-delivery h3,
    .gift-card h3,
    .gift h3 {
        font-size: 14px;
        line-height: 18px;
    }

    .order-payment h3,
    .order-info h3,
    .order-delivery h3 {
        font-size: 22px;
        line-height: 28px;
        padding: 0 0 10px 0;
        margin: 0 0 15px 0;
    }

    .order-delivery .delivery-method label {
        padding: 10px 10px 10px 45px;
    }

    .order-delivery .delivery-method input[type=radio] {
        margin-top: -8px;
    }

    .order-delivery .delivery-method {
        margin: 0 0 10px 0;
    }

    .total h3 {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 0;
        border: 0;
    }

    .sum ul li span {
        font-weight: bold;
    }

    .gift-package label {
        margin-left: 5px;
        display: inline;
    }

    .intro h2 {
        font-size: 32px;
        line-height: 38px;
    }

    .carousel-item {
        height: 450px;
    }

    .intro .carousel-caption {
        bottom: 10%;
    }

    .order-auth-login {
        padding: 0 20px 30px;
    }

    .order-auth {
        background: none;
    }

    .order-auth .heading {
        margin: 0 0 10px 0;
    }

    .order-auth .or {
        text-align: center;
        margin: 11px 0;
    }

    .cart h1 {
        font-size: 30px;
        line-height: 36px;
        margin: 0 0 0 0;
    }

    .order-auth h1 {
        font-size: 26px;
        line-height: 30px;
        margin: 0 0 10px 0;
    }

    .age-gate .btn {
        margin: 0 5px 5px;
        min-width: 100%;
    }

    #flash-messages-wrapper {
        padding-bottom: 20px;
    }

    .choose-mystery-box .mystery-box-package {
        margin-bottom: 50px;
    }

    .choose-mystery-box h2 {
        margin-bottom: 30px;
    }

    .choose-mystery-box .mystery-box-package .mystery-tittle h3 {
        font-size: 17px;
        line-height: 24px;
    }

    .choose-mystery-box .mystery-box-package .mystery-tittle span.wine-type {
        font-size: 14px;
        display: block;
        margin-top: 2px;
    }

    .choose-mystery-box .mystery-box-package.sec {
        margin-top: 0;
    }

    .choose-mystery-box .mystery-box-package .mystery-tittle {
        padding: 13px 0;
    }

    .choose-mystery-box .mystery-box-package.sec .mystery-tittle {
        padding: 12px 0 11px 0;
    }

    .choose-mystery-box .mystery-box-package .price {
        padding: 10px 0;
        font-size: 21px;
    }

    .choose-mystery-box .mystery-box-package ul {
        padding: 10px 0 15px 0;
        min-height: 334px;
    }

    .choose-mystery-box .mystery-box-package.sec ul {
        min-height: 274px;
    }

    .choose-mystery-box .mystery-box-package ul li {
        font-size: 14px;
        padding: 5px 0 5px 21px;
    }

    .choose-mystery-box .mystery-box-package .info-box {
        padding: 0 15px 25px 15px;
    }

    .choose-mystery-box .mystery-box-package ul li:before {
        width: 15px;
        height: 12px;
        margin-top: 10px;
    }

    .choose-mystery-box .mystery-box-package form .form-group select {
        padding: 0 11px;
        font-size: 13px;
    }

    .choose-mystery-box .mystery-box-package.third {
        margin: 0;
    }

    .comments .mystery-box-comments .comment-block {
        padding: 20px 15px;
    }

    .mystery-box .comments h3 {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .comments .mystery-box-comments .comment-block h5 {
        font-size: 15px;
    }


    .comments .mystery-box-comments .comment-block p.comment-title {
        font-size: 13px;
        line-height: 22px;
        min-height: 35px;
    }

    .comments .mystery-box-comments .comment-block p {
        font-size: 13px;
        line-height: 22px;
        min-height: 110px;
    }

    .comments .mystery-box-comments .comment-block ul {
        margin-bottom: 6px;
    }

    .comments .mystery-box-comments .comment-block ul li img {
        width: 17px;
    }

    .comments .mystery-box-comments.owl-theme .owl-dots .owl-dot span {
        width: 13px;
        height: 13px;
        margin: 0 4px;
    }

    .mystery-box .comments a.btn {
        margin: 17px auto 0 auto;
    }

    .comments .mystery-box-comments.owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 20px;
    }

    /*    seewines-magnum*/
    .intro-seewines-magnum .intro-text {
        padding: 30px 0 45px 0;
    }

    .intro-seewines-magnum .intro-text .heading {
        font-size: 28px;
        line-height: 36px;
    }

    .intro-seewines-magnum .intro-text h2.heading {
        margin: 10px auto 16px auto;
    }

    .intro-seewines-magnum .intro-text h2:after {
        width: 26px;
        height: 26px;
        margin-left: -13px;
        left: 3px;

    }

    .intro-seewines-magnum .intro-text p {
        font-size: 15px;
    }

    .seewines-magnum .benefits {
        padding: 0;
    }

    .magnum-offer {
        padding-bottom: 50px;
    }

    .magnum-info p {
        margin-bottom: 20px;
        line-height: 1.5;
        font-size: 14px;
    }

    .magnum-info .heading {
        margin-bottom: 25px;
    }

    .magnum-offer .magnum-offer-cont {
        border-bottom: 1px solid #D9D9D9;
        padding: 0 15px 10px 15px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block {
        padding: 25px 0;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .person-type {
        text-align: center;
        font-size: 15px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .person-type img {
        max-height: 50px;
        margin-bottom: 5px;
        margin-right: 10px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block span {
        margin: 0 auto;

    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift span {
        max-width: 100px;
        text-align: center;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block h5 {
        font-family: 'FedraSansPro', sans-serif;
        text-transform: uppercase;
        font-size: 15px;
        text-align: center;
        color: #6A6665;
        margin-top: 18px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift {
        min-height: 131px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift img {
        display: block;
        margin: 3px auto 3px auto;
    }

    .register-form .form-control {
        height: 42px;
    }

    .register-form form .form-group {
        margin-bottom: 1rem;
    }

    .register-form form button {
        font-size: 12px;
        letter-spacing: 1px;
    }

    .register-form .register-link {
        margin: 20px 0 0 0;
    }

    .register-form .register-link a {
        margin-left: 5px;
    }

    .bellini-intro .banner-intro img {
        max-height: 490px;
        display: block;
        margin: 0 auto;
    }

    .bellini-intro .intro-bottle img {
        position: static;
        margin: 0 auto;
        display: block;
        max-width: 150px;
    }

    .bellini-game .from-us .form-container {
        padding: 30px 30px 30px !important;
    }

    .bellini-game .from-us .form-container label {
        font-size: 13px;
    }

    .how-to-participate h3 {
        font-size: 27px;
        line-height: 35px;
    }

    .participate-steps .step {
        padding: 12px 0 40px 0;
    }

    .participate-steps .step:after {
        height: 30px;
    }

    .participate-steps .step span {
        margin: 0 auto 12px auto;
        width: 60px;
        height: 60px;
        font-size: 26px;
        padding: 5px 0 0 0;
    }

    .participate-steps .step p {
        font-size: 15px;
    }

    .how-to-participate .info-btn .btn {
        letter-spacing: 1px;
        margin: 0 5px;
        font-size: 11px;
    }

    .review-modal .review-info select.form-control {
        margin-left: 5px;
    }

    .bellini-game .from-us {
        float: none;
    }

    .bellini-game .from-us .form-container h5 {
        font-size: 24px;
        line-height: 34px !important;
    }

    .select-product .owl-thumbs {
        margin: 0 0 1rem 0;
    }

    .select-product .owl-thumb-item {
        margin: 0 0 1rem 0;
    }

    .select-product .owl-thumb-item img {
        height: 6rem;
    }

    .select-product .qty-holder {
        max-width: 150px;
        margin: 0 auto;
    }

    .select-product .product-item {
        text-align: center;
        padding: 40px 0;
    }

    .select-product .heading {
        font-size: 1.6rem;
        line-height: 2rem;
    }

    .select-product .product-item .heading {
        text-align: center;
    }

    .select-product .product-item .product-meta {
        font-size: 15px;
    }

    .select-product .product-item .product-price {
        font-size: 1.5rem;
    }

    .select-product .owl-nav {
        display: none;
    }

    .select-product .product-item img {
        max-height: 20rem;
        margin: 0 auto 10px auto;
    }

    .select-product h5 {
        margin: 0.5rem 0 0.5rem 0;
        font-size: 15px;
    }

    .select-product .bot-info {
        margin: 0 0 1rem 0;
    }

    .select-product .bot-info img {
        height: 1.3rem;
        margin: 0;
    }

    .select-product .bot-info .bot {
        padding: 0.6rem 1rem;
    }

    .select-product .qty-holder .plus,
    .select-product .qty-holder .minus,
    .select-product .qty-holder input {
        height: 41px;
    }

    .select-product .cta {
        height: 43px;
        line-height: 39px;
        margin: 1rem 0 0 0;
    }

    .intro.intro-wine-tests .carousel-caption {
        padding: 40px 15px 70px 15px;
    }

    .intro.intro-wine-tests h2 {
        font-size: 30px;
        line-height: 42px;
    }

    .intro .btn {
        letter-spacing: 2px;
        padding: 0 40px;
    }

    .intro.intro-wine-tests .owl-dots {
        margin-bottom: 8px;
    }

    .wine-tests-page .heading-2 {
        font-size: 22px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    .wine-tests-page p {
        margin-bottom: 18px;
        font-size: 14px;
        line-height: 1.6;
    }

    .wine-tests-page .owl-carousel .owl-stage-outer {
        width: 100% !important;
    }

    .special-holiday {
        padding: 50px 0;
    }

    .tasting-sommelier .person-name span {
        font-size: 14px;
    }

    .wine-tests-page .faq .question {
        font-size: 15px;
        line-height: 24px;
    }

    .wine-tests-page .faq .answer .answer-content {
        font-size: 14px;
        line-height: 24px;
    }

    .inquiry-form input[type="date"]::-webkit-calendar-picker-indicator,
    .inquiry-form input[type="time"]::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    .inquiry-form input[type="date"],
    .inquiry-form input[type="time"] {
        padding-left: 35px;
    }

    .event .event-action .from-us.our-team .form-container {
        margin-top: 0;
    }

    .inquiry-form .form-group.date {
        background-position-x: 11px !important;
    }

    .inquiry-form .form-group.time {
        background-position-x: 11px !important;
    }

    .profile-address .profile-content .magnum-offer .magnum-offer-cont {
        min-width: auto;
        padding: 0;
    }

    .profile-address .profile-content .magnum-offer .col-12 {
        padding: 0;
    }

    .subscribe-land {
        background: url("../../images/seewines-october-bgr.png") center bottom no-repeat, #F3EDFE;
        min-height: 910px;
        background-size: 1070px;
    }

    .subscribe-land .heading {
        font-size: 25px;
        line-height: 39px;
        margin-bottom: 20px;
    }

    .subscribe-steps .subscribe-step {
        margin-bottom: 25px;
    }

    .subscribe-steps .subscribe-step span {
        width: 40px;
        height: 40px;
        line-height: 38px;
        font-size: 16px;
    }

    .subscribe-land p,
    .subscribe-steps .subscribe-step .step-text {
        font-size: 15px;
    }

    .subscribe-land .left-shape {
        max-width: 150px;
    }

    .subscribe-land .right-shape {
        max-width: 150px;
        bottom: 0;
    }

    .subscribe-land .bottom-desc {
        margin-bottom: -10px;
        padding: 0 15px;
    }

    .subscribe-land .bottom-desc p {
        color: #000;
    }

    .our-sommelier .sommeliers-img img {
        margin-bottom: 45px;
    }

    .mystery-box .box-for-us .heading {
        line-height: 30px;
    }

    .mystery-box .box-for-us .box-block {
        margin-bottom: 30px;
    }

    .mystery-box .box-for-us .box-block h5 {
        margin-top: 30px;
        font-size: 24px;
        line-height: 34px;
        padding: 0 25px;
    }

    .mystery-box .box-for-us .box-block img {
        width: 100%;
    }

    .mystery-box .box-for-us .col-md-4:last-of-type .box-block {
        margin-bottom: 0;
    }

    .christmas-landing {
        min-height: auto;
        padding: 96px 0 50px 0;
        margin-top: -86px;
    }

    .christmas-landing .heading-2 {
        font-size: 28px;
        line-height: 33px;
        margin: 0 0 25px 0;
    }

    .christmas-landing .heading-2 span {
        font-size: 28px;
        padding: 8px 0;
        line-height: 36px;
    }

    .christmas-landing .cont {
        padding-top: 5px;
    }

    .christmas-landing .btn {
        font-size: 12px;
        min-height: 44px;
        line-height: 43px;
        min-width: 200px;
    }

    .christmas-landing .btn img {
        height: 11px;
        margin-left: 6px;
    }

    .christmas-header .logo img {
        height: 28px;
    }

    .christmas-header .christmas-nav ul li a {
        font-size: 14px;
        letter-spacing: 0;
    }

    .christmas-header .christmas-nav ul li a img {
        margin-top: -18px;
        margin-left: 4px;
        height: 35px;
    }

    .christmas-landing .block-title {
        font-size: 19px;
        margin-bottom: 20px;
        top: 0;
    }

    .christmas-landing .heading {
        font-size: 28px;
        line-height: 34px;
        margin: 0 0 25px 0;
    }

    .christmas-landing.questionnaire form .form-group label {
        font-size: 13px;
        padding: 22px 12px;
    }

    .christmas-landing.questionnaire form .form-group label .quest-img {
        margin-bottom: 12px;
        height: 42px;
    }

    .christmas-landing.questionnaire form .form-group label img {
        width: 46px;
    }

    .christmas-landing .questionnaire-pagination {
        margin-top: 0;
    }

    .christmas-landing .questionnaire-pagination li a {
        width: 48px;
        height: 48px;
        line-height: 44px;
    }

    .christmas-landing .questionnaire-pagination li a img {
        width: 8px;
    }

    .home-about .heading {
        margin: 0 auto 20px;
    }

    .home-about .special-text {
        font-size: 17px;
        padding: 0;
    }

    .home-about {
        text-align: center;
    }

    .offers .small-offer .lab {
        font-size: 32px;
        width: 100%;
        text-align: center;
    }

    .categories .page-intro,
    .contacts .page-intro,
    .team-page .page-intro,
    .partners .page-intro,
    .blog-list .page-intro,
    .distillery .page-intro,
    .vineyards .page-intro,
    .about-us .page-intro {
        padding: 80px 0 50px 0;
    }

    .page-intro h1:after {
        bottom: -20px;
    }

    .intro-text h1,
    .page-intro h1 {
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 5px;
    }

    .intro-text p {
        width: 100%;
        font-size: 17px;
        line-height: 26px;
    }

    .team-block img {
        margin: 0 0 25px 0;
    }

    header .user-widgets .widget-item {
        padding: 0 10px;
    }

    .user-widgets .widget-item .icn {
        height: 17px;
    }

    .about-us .section-01 .heading,
    .about-us .section-02 .heading {
        text-align: center;
    }

    .partner .partner-title {
        font-size: 19px;
        line-height: 26px;
        margin: 0 0 20px 0;
    }

    .partner .partner-address {
        font-size: 13px;
        line-height: 20px;
    }

    .partner .partner-address {
        min-height: inherit;
    }

    .partner .partner-action a {
        display: block;
        width: 100%;
        font-size: 13px;
        margin: 0 0 10px 0;
    }

    .partner .partner-text {
        min-height: inherit;
    }

    .lp-product .lp-product-name {
        font-size: 36px;
        line-height: 46px;
    }

    .lp-product img {
        max-height: 300px;
    }

    .lp-product {
        padding: 50px 0;
    }

    .product h1 {
        font-size: 30px;
        line-height: 38px;
    }


    .sub-heading {
        width: 100%;
        font-size: 22px;
        line-height: 28px;
    }

    .history p {
        font-size: 16px;
        line-height: 24px;
    }

    .section-01 .text-left-side,
    .section-02 .text-right-side {
        padding: 0;
    }

    .section-01:after,
    .section-02:after {
        display: none;
    }

    .section-01 .section-01-middle {
        position: relative;
        bottom: 0;
        left: 0;
    }

    .team {
        position: relative;
        background: #f4f3ef;
        padding-bottom: 0;
    }

    .team p {
        width: 100%;
        min-height: auto;
    }

    .timeline-stage {
        background: none !important;
        margin-bottom: 50px;
    }

    .timeline .timeline-stage .timeline-image {
        min-height: inherit;
    }

    .timeline {
        margin-top: 0;
        padding: 50px 0;
    }

    .timeline .year {
        margin: 15px 0 5px 0;
    }

    .timeline .timeline-stage .timeline-nav {
        margin: 10px 0 0 0;
    }

    .timeline .timeline-stage .timeline-nav li a {
        padding: 5px 15px 5px 0;
    }

    .timeline .year {
        font-size: 36px;
        line-height: 36px;
    }

    /*.section-01 .heading,*/
    /*.section-02 .heading {*/
    /*    text-align: left;*/
    /*    margin-bottom: 5px;*/
    /*}*/

}

@media only screen and (min-width: 0px) and (max-width: 574px) {
    .choose-mystery-box .mystery-box-package ul {
        min-height: auto;
    }

    .choose-mystery-box .mystery-box-package.sec ul {
        min-height: auto;
    }

    .seewines-magnum .benefits .benefit .benefit-img {
        margin: 0 0 15px 0;
    }

    .seewines-magnum .benefits .benefit h5 {
        margin: 0 0 10px 0;
    }

    .bellini-game .from-us .form-container {
        width: 100%;
    }

}

@media screen and (orientation: landscape)
and (min-device-width: 0)
and (max-device-width: 767px) {

    .order-auth-login {
        padding: 60px 10px;
    }

    .categories {
        padding-bottom: 0;
    }

    .event-row .event-info .event-desc {
        margin: 0 0 0;
    }

    .event-row .event-info .event-date {
        margin: 5px 0 0 0;
    }

    .event-row .event-info .event-date span {
        font-size: 40px;
        line-height: 40px;
    }

    .blog-view .blog-main-img {
        float: left;
        max-height: 250px;
        margin: 0 20px 20px 0;
    }

    .delivery-hint span {
        display: inline-block;
    }

    .top .top-item.social {
        float: none;
        margin-right: 35px;
    }

    .top .top-item:after {
        content: "";
        height: 15px;
        width: 1px;
        background-color: #753e86;
        display: block;
        position: absolute;
        top: 3px;
        right: -16px;
    }

    .top .top-item.phone {
        display: inline-block;
    }

    .delivery-hint .sep {
        display: inline-block;
    }


    .owl-carousel .owl-stage {
        padding-left: 0 !important;
    }


    .mystery-box .video iframe {
        height: 400px;
        margin: 0 0 30px 0;
    }

    .mystery-box .hero h3 {
        font-size: 22px;
        letter-spacing: 5px;
    }

    .mystery-box .hero-text {

    }

    .assortment-info .btn {
        height: inherit;
        line-height: 18px;
        padding: 10px 20px;
    }

    .comments .rate-list .btn {
        margin: 5px auto 29px auto;
    }

    .blog-list .blog-item h3 a {
        font-size: 16px;
        line-height: 24px;
    }

    .blog-list .blog-item h3 {
        margin: 20px 0 5px 0;
    }


    .team .person img {
        margin: 0 auto;
        display: block;
    }

    .products-list .product-item .product-name {
        min-height: 50px;
    }

    .assortment .assortment-item .btn {
        padding: 7px 15px;
    }

    .product .product-prices {
        text-align: left;
    }


}

@media screen and (orientation: landscape)
and (min-device-width: 767px)
and (max-device-width: 1024px) {

    .timeline-stage {
        background: none !important;
        margin-bottom: 50px;
    }

    .timeline .timeline-stage .timeline-image {
        min-height: inherit;
    }

    .timeline {
        margin-top: 0;
        padding: 50px 0;
    }

    .timeline .year {
        margin: 15px 0 5px 0;
    }

    .timeline .timeline-stage .timeline-nav {
        margin: 10px 0 0 0;
    }

    .timeline .timeline-stage .timeline-nav li a {
        padding: 5px 15px 5px 0;
    }

    .timeline .year {
        font-size: 36px;
        line-height: 36px;
    }

    .section-02 .text-right-side {
        padding: 0 0 0 20px;
        width: 50%;
        float: right;
    }

    .section-01 .text-left-side {
        padding: 0 20px 0 0;
        width: 50%;
    }

    .space {
        padding: 65px 0;
    }

    .intro h2 {
        font-size: 34px;
        line-height: 44px;
    }

    .categories .products-list .product-item {
        margin-bottom: 70px;
    }

    .products-list .product-item {
        margin-bottom: 0;
    }

    .products-list .product-item .product-action {
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: 0 0 0 0;
        transform: translate(0, 0);
    }


    .assortment .assortment-item .assortment-info {
        padding: 20px 15px 30px;
    }

    .assortment .assortment-item h3 {
        font-size: 18px;
        line-height: 24px;
    }


    .events .event-space {
        margin-bottom: 22px;
    }

    .events .event-block .event-info h3 {
        font-size: 18px;
        line-height: 24px;
    }


    .newsletter .hint {
        padding: 0 100px;
    }


    .home .blog-list {
        padding-bottom: 0;
    }

    .home .benefits .benefit h5 {
        font-size: 14px;
        line-height: 20px;
    }

    .event .event-desc h1 {
        font-size: 30px;
        line-height: 34px;
    }

    .event .event-desc {
        padding: 55px 20px 20px 20px;
    }

    .event .event-date {
        padding: 38px 30px 25px 30px;
    }

    .event .event-content {
        margin-bottom: 0;
    }
}


/*slider-module*/

@media (max-width: 880px) {

    .slider-module .owl-carousel .item {
        height: 400px;
    }

    .slider-module .owl-carousel .carousel-block {
        position: static;
        padding-top: 55px;
        padding-bottom: 105px;
    }
}

@media (max-width: 767px) {
    .slider-module .owl-carousel .carousel-block h2 {
        font-size: 34px;
        line-height: 40px;
    }

    .slider-module .owl-carousel .carousel-block h1 {
        font-size: 14px;
        line-height: 23px;
    }
}

@media (max-width: 575px) {
    .slider-module .owl-carousel .carousel-block h1,
    .slider-module .owl-carousel .carousel-block h2 {
        padding: 0 10px;
    }

    .profile .profile-content .order-row .order-num span {
        display: block;
        width: 100%;
    }


    .profile .profile-content .order-row .order-num a span {
        display: block;
        width: 100%;
        max-width: 149px !important;
        padding: 6px 0 6px 25px !important;
    }

    .profile .profile-content .order-row .order-num div a.btn {
        font-size: 9px;
        padding: 0 6px;
        line-height: 15px;
        min-height: 43px;
    }

    .profile-content .order-row {
        padding: 11px 10px;
    }

    .profile .profile-content {
        padding: 25px 15px 35px 15px;
    }

    .profile .profile-content .order-row .order-num .btn-delivery {
    }

    .profile .order-row .view-order {
        margin: 11px 0 0 auto;
    }

    .profile .profile-content .order-row .order-num a.replay:before {
        margin-left: 0;
        margin-top: -2px;
    }

    .profile .profile-content .order-row .order-num a.cancel:before {
        margin-left: 0;
    }

    .profile-content .order-num .cancel-button {
        width: 104px !important;
    }

    .profile .profile-content .order-row .order-num div a.pay-again {
        text-align: center;
        padding-left: 0 !important;
    }

    .profile .profile-content .order-row .order-num a.pay-again span:before {
        left: auto;
    }

    .mystery-box .mystery-box-info .video-holder iframe {
        height: 205px;
    }
}

@media (max-width: 425px) {
    .slider-module .owl-carousel .owl-nav button.owl-next,
    .slider-module .owl-carousel .owl-nav button.owl-prev {
        margin: -43px 0 0 0;
        background: transparent;
    }

    .slider-module .owl-carousel .carousel-block h2 {
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 2px;
    }

    .slider-module .owl-carousel .carousel-block p {
        width: 100%;
        text-align: center;
        margin: 0 0 22px 0;
        padding: 0 20px;
    }

    .from-us .form-container h5 {
        line-height: 29px !important;
    }
}

@media (max-width: 425px) and (min-width: 370px) {
    .slider-module .owl-carousel .owl-nav button.owl-next,
    .slider-module .owl-carousel .owl-nav button.owl-prev {
        margin: -64px 0 0 0;
    }
}

@media (max-width: 370px) {
    .slider-module .owl-carousel .carousel-block h2 {
        padding: 0 25px;
    }
}


/*two-banners-module*/

@media (max-width: 1150px) {
    .two-banners-module .two-banners-module-container {
        padding: 0 70px;
    }
}

@media (max-width: 1100px) {
    .two-banners-module .two-banners-module-container {
        padding: 0;
    }
}

@media (max-width: 992px) {
    .two-banners-module .module-banner .module-banner-choice h2 {
        line-height: 34px;
        margin-bottom: 4px;
    }

    .two-banners-module .module-banner .module-banner-choice h3 {
        font-size: 35px;
        line-height: 40px;
    }
}

@media (max-width: 860px) {
    .two-banners-module .module-banner img {
        min-width: 100%;
    }


}

@media (max-width: 767px) {

    .two-banners-module {
        padding: 50px 0 10px 0;
    }

    .two-banners-module .module-banner {
        text-align: center;
        margin-bottom: 20px;
    }

    .two-banners-module img.right-decoration {
        margin-bottom: -135px;
        width: 135px;
    }

    .two-banners-module .module-banner .module-banner-choice.left-banner {
        width: 100%;
        left: 0;
        margin: 0;
        text-align: left;
        padding-left: 28px;
    }

    .two-banners-module .module-banner .module-banner-choice.left-banner h3 {
        text-align: left;
    }

    .two-banners-module .module-banner .module-banner-choice.left-banner .red-eclipse {
        left: 0;
        margin-left: 165px;
    }
}

@media (max-width: 600px) {
    .two-banners-module img.right-decoration {
        margin-bottom: -88px;
        width: 135px;
    }
}

@media (max-width: 575px) {
    .two-banners-module {
        padding: 40px 0 10px 0;
    }
}

@media (max-width: 425px) {
    .two-banners-module .module-banner .module-banner-choice.left-banner {
        bottom: 15%;
    }

    a.btn.buy {
        padding: 0 35px
    }

    .two-banners-module .module-banner .module-banner-choice.right-banner a.buy {
        margin-left: 25px;

    }

    .two-banners-module img.right-decoration {
        width: 98px;
    }

    .two-banners-module img.left-decoration {
        width: 70px;
    }

    .two-banners-module .module-banner .module-banner-choice.right-banner {
        margin-right: 25px;
    }

}


@media (max-width: 1333px) {
    .product-slide-module .owl-carousel .owl-nav button.owl-next {
        margin-right: -9px;
    }


    .product-slide-module .owl-carousel .owl-nav button.owl-prev {
        margin-left: -34px;
    }


}

@media (max-width: 1315px) {
    .product-slide-module .owl-carousel {
        padding: 0 35px;
    }

    .product-slide-module .owl-carousel .owl-nav button.owl-next {
        margin-right: 0;
    }

    .product-slide-module .star-decoration {
        top: 24%;
    }


    .product-slide-module .owl-carousel .owl-nav button.owl-prev {
        margin-left: 0;
    }

    .product-slide-module .owl-carousel .owl-nav button.owl-prev span {
        left: 0;
    }

    .product-slide-module .owl-carousel .owl-nav button.owl-next span {
        left: 0;
    }

}

@media (max-width: 1150px) {
    .product-slide-module .see-more {
        margin-top: 10px;
    }
}

@media (max-width: 1140px) {
    .product-slide-module .gift-box-sec {
        left: 17%;
    }
}

@media (max-width: 1024px) {
    .product-slide-module .see-more {
        margin-top: -41px;
    }
}

@media (max-width: 992px) and (min-width: 575px) {
    .product-slide-module .left-mask-2 {
        top: 23%;
    }
}

@media (max-width: 992px) {
    .product-slide-module h2 {
        padding: 0 20px;
        margin: 64px 0 0 0;
        width: 100%;
        font-size: 30px;
        line-height: 50px;
    }

    .holiday-offers .product-article .product-info .product-action {
        height: 100%;
    }

    .holiday-offers .product-article .product-info .product-action {
        opacity: 1;
        background: white;
    }

    .holiday-offers .product-article .product-info .product-action a.view {
        display: none;
    }

    .product-slide-module .see-more {
        margin-top: 10px;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-action {
        padding: 0 15px 20px 15px;
        height: 100%;
    }
}

@media (max-width: 820px) {
    .product-slide-module .gift-box-sec {
        left: 10%;
    }
}

@media (max-width: 767px) {
    .product-slide-module h2 {
        font-size: 26px;
        line-height: 43px;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-action {
        height: 100%;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-action a {
        font-size: 12px;
    }

    .holiday-offers .product-article {
        height: 100%;
    }

    .holiday-offers .product-article .product-info:hover {
        transform: none;
    }

    .product-slide-module .wine-img img {
        width: 200px;
    }

    .product-slide-module .gift-box {
        width: 111px;
        margin-bottom: -104px;
    }

    .product-slide-module .owl-carousel {
        padding: 0;
    }

    .product-slide-module .star-decoration {
        top: 22%;
    }
}

@media (max-width: 666px) {
    .product-slide-module .gift-box-sec {
        left: 5%;
        width: 123px;
    }
}

@media (max-width: 640px) {

    .product-slide-module .products-list .product-item {
        height: 550px;
    }

    .product-slide-module .products-list .product-item .product-action {
        opacity: 1;
        display: block;
    }
}

@media (max-width: 575px) {
    .product-slide-module h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 23px;
        line-height: 35px;
        padding: 0 5px;
    }

    .product-slide-module .wine-img {
        text-align: center;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-name {
        padding: 13px 5px 23px 5px;
    }
}


@media (max-width: 500px) {
    .product-slide-module .star-decoration {
        width: 94px;
        top: 29%;
    }

    .product-slide-module .decoration {
        width: 96px;
        margin-left: -48px;
        margin-top: -20px;
    }
}


@media (max-width: 425px) {
    .product-slide-module.holiday-offers .product-article .product-info .product-action {
        padding: 0 10px;
    }

    .product-slide-module h2 {
        font-size: 20px;
        line-height: 33px;
    }

    .product-slide-module h3 {
        margin-top: 40px;
    }

    .product-slide-module .star-decoration {
        top: 26%;
    }

    .product-slide-module .owl-carousel {
        padding: 0;
    }

    .holiday-offers .product-article .product-info .product-name {
        height: 76px;
    }

    .product-slide-module .gift-box {
        width: 82px;
        margin-bottom: -80px;
    }

    .product-slide-module .gift-box-sec {
        width: 103px;
        left: 0;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-name:after {
        margin-bottom: 12px;
    }

    .product-slide-module .product-article .product-info .product-name h5 {
        padding: 3px 8px 7px 8px;
        height: 67px;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-prices {
        padding: 0 0 15px 0;
    }


    .product-slide-module.holiday-offers .product-article {
        margin-bottom: 0;
    }

    .product-slide-module .left-mask-2 {
        width: 160px;
        margin-left: -120px;
    }
}

@media (max-width: 361px) {
    .product-slide-module .star-decoration {
        width: 83px;
    }
}

@media (max-width: 340px) {
    .product-slide-module .star-decoration {
        top: 31%;
    }
}

@media (max-width: 321px) {
    .product-slide-module .star-decoration {
        top: 32%;
    }
}


/*holiday-offers*/

@media (max-width: 1200px) {
    .holiday-offers .category-choice {
        padding: 0 40px;
    }

    .holiday-offers .product-article .product-info.tequila .product-name {
        padding: 13px 10px 20px 10px;
    }
}

@media (max-width: 1187px) {
    .holiday-offers .product-article .product-info .product-prices {
        padding: 12px 0 15px 0;
    }

    .holiday-offers .product-article .product-info .product-desk {
        min-height: 75px;
    }

    .holiday-offers .product-article .product-info .product-name {
        padding: 13px 5px 20px 5px;
    }
}

@media (max-width: 992px) {
    .holiday-offers .category-choice .col-md-4:nth-child(1) button,
    .holiday-offers .category-choice .col-md-4:nth-child(2) button,
    .holiday-offers .category-choice .col-md-4:nth-child(3) button {
        margin-bottom: 10px;
    }

    .holiday-offers .product-article .product-info .product-action a {
        font-size: 12px;
    }

    .holiday-offers .product-article {
        margin-bottom: 30px;
    }

    .holiday-offers .product-article.see-more img.bgr-effect,
    .holiday-offers .product-article.see-more {
        height: 92%;
    }

    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        height: 93%;
    }

    .holiday-offers.slide-offer .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: -19px;
    }
}

@media (max-width: 767px) {
    .holiday-offers {
        padding-top: 25px;
    }

    .holiday-offers .product-article.see-more img.bgr-effect {
        position: static;
        width: 100%;
    }

    .holiday-offers h3 {
        font-size: 22px;
        line-height: 29px;
    }

    .holiday-offers .product-article.see-more img.bgr-effect {
        height: 100%;
    }

    .holiday-offers .category-choice button {
        margin-bottom: 10px;
    }

    .holiday-offers.slide-offer .product-article .product-info:hover {
        transform: none;
    }


    .holiday-offers.slide-offer .gift-image {
        width: 475px;
        margin-top: -253px;
    }

    .product-slide-module .product-info {
        transform: none;
    }

    .partner h2 {
        min-height: auto;
        margin-bottom: 10px;
    }

    .blog-list .owl-item .blog-item h3 {
        min-height: 46px;
    }

    .blog-list .owl-item .blog-item h3 a {
        font-size: 17px;
        line-height: 22px;
    }

    .blog-list .blog-item .blog-post-content {
        padding: 15px 15px;
    }

    .cart .discount-info p {
        margin-top: -35px;
        margin-bottom: 25px;
    }

    .btn-link {
        font-size: 16px;
    }

}

@media (max-width: 575px) {
    .holiday-offers .product-article .product-info .product-name:after {
        margin-bottom: 7px;
    }

    .holiday-offers .product-article.see-more .more-info {
        width: 100%;
        text-align: center;
    }

    .holiday-offers .product-article .product-info .product-desk {
        min-height: 100%;
        padding-top: 7px;
    }

    .holiday-offers .product-article .product-info .product-prices {
        padding: 10px 0 15px 0;
    }

    .holiday-offers .product-article .product-info .product-name {
        padding: 13px 5px 25px 5px;
        height: auto;
    }

    .holiday-offers.slide-offer .product-article .product-info .product-name {
        height: 80px;
    }

    .holiday-offers.slide-offer .product-article .product-info .product-desk {
        min-height: 43px;
    }

    .holiday-offers .product-article.see-more .more-info {
        font-size: 19px;
        line-height: 33px;
    }
}

/*@media(max-width: 537px) {*/
/*    .holiday-offers .product-article.see-more .more-info {*/
/*        width: 398px;*/
/*        height: 66px;*/
/*    }*/
/*}*/

@media (max-width: 540px) {
    .holiday-offers.slide-offer .gift-image {
        display: none;
    }
}

@media (max-width: 425px) {

    .holiday-offers {
        padding-top: 15px;
    }

    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 439px;
    }

    /*.holiday-offers.food-and-wine.slide-offer .owl-theme .owl-nav.disabled+.owl-dots {*/
    /*    margin-top: -77px;*/
    /*}*/
    /*.holiday-offers .product-article.see-more .more-info {*/
    /*    width: 364px;*/
    /*    height: 66px;*/
    /*}*/
    .holiday-offers h3 {
        font-size: 20px;
        line-height: 30px;
        padding: 0 38px;
    }

    .holiday-offers .product-article {
        margin-bottom: 25px;
    }

    .holiday-offers .category-choice {
        padding: 0;
    }


}


@media (max-width: 640px) {
    .product-slide-module {
        padding-top: 45px;
    }
}


@media (max-width: 414px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 423px;
    }
}

@media (max-width: 375px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 376px;
    }
}

@media (max-width: 360px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 360px;
    }
}


@media (max-width: 350px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 343px;
    }
}


@media (max-width: 320px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 306px;
    }
}

/*@media(max-width: 400px) {*/
/*    .holiday-offers.food-and-wine.slide-offer .owl-theme .owl-nav.disabled+.owl-dots {*/
/*        margin-top: -68px;*/
/*    }*/
/*}*/

/*@media(max-width: 375px) {*/
/*    .holiday-offers.food-and-wine.slide-offer .owl-theme .owl-nav.disabled+.owl-dots {*/
/*        margin-top: -56px;*/
/*    }*/
/*}*/

/*@media(max-width: 340px) {*/
/*    .holiday-offers.food-and-wine.slide-offer .owl-theme .owl-nav.disabled+.owl-dots {*/
/*        margin-top: -36px;*/
/*    }*/
/*}*/

/*@media(max-width: 365px) {*/
/*    .holiday-offers .product-article.see-more .more-info {*/
/*        width: 318px;*/
/*        height: 99px;*/
/*    }*/
/*}*/


/*mystery box*/

@media (max-width: 1200px) {
    .mystery-box-module h3 {
        margin-top: 5px;
    }

    .mystery-box-module .mystery-star {
        margin-top: -94px;
        top: 0;
    }
}

@media (max-width: 992px) {
    .mystery-box-module h3 {
        font-size: 20px;
        line-height: 36px;
        margin-bottom: 5px;
    }

    .mystery-box-module .mystery-star {
        margin-top: -92px;
        width: 258px;
        margin-left: -91px;
    }
}

@media (max-width: 850px) {
    .mystery-box-module .mystery-box-img .wine-count {
        margin-left: -31px;
        width: 75px;
        height: 75px;
    }

    .mystery-box-module .mystery-box-img .wine-count span {
        font-size: 44px;
    }
}

@media (max-width: 780px) {
    .mystery-box-module h3 {
        font-size: 19px;
    }
}

@media (max-width: 767px) {
    .mystery-box-module ul {
        margin-bottom: 25px;
    }
}

@media (max-width: 575px) {

    .mystery-box-module {
        padding: 45px 0 55px 0;
    }

    .mystery-box-module .mystery-box-img .box-img h3 {
        font-size: 30px;
        margin-left: -100px;
    }

    .mystery-box-module .mystery-box {
        width: 90px;
    }
}

@media (max-width: 425px) {


    .mystery-box-module .mystery-box-img .box-img img {
        width: 325px;
    }

    .mystery-box-module .mystery-box-img .bottle img {
        width: 140px;
    }

    .mystery-box-module .mystery-box-img .wine-count {
        width: 65px;
        height: 65px;
    }

    .mystery-box-module .mystery-box-img .wine-count span {
        font-size: 36px;
    }

    .mystery-box-module .mystery-star {
        display: none;
    }
}

@media (max-width: 370px) {
    .mystery-box-module .mystery-box-img .box-img h3 {
        font-size: 22px;
        margin-left: -75px;
        margin-top: -27px
    }

    .mystery-box-module .mystery-box-img .wine-count {
        margin-left: -22px;
        width: 57px;
        height: 57px;
    }

    .mystery-box-module .mystery-box-img .wine-count span {
        margin-top: -3px;
        font-size: 28px;
    }

}


/*newsletter.gift*/

@media (max-width: 992px) {
    .newsletter.gift h3 {
        padding: 0 189px;
    }

    .newsletter.gift p {
        padding: 0 75px;
    }
}

@media (max-width: 854px) {
    .newsletter.gift h3 {
        padding: 0 25px;
    }
}

@media (max-width: 767px) {

    .newsletter.gift {
        padding: 50px 0 50px 0;
    }

    .newsletter.gift h3 {
        padding: 0 20px;
    }

    .newsletter.gift p {
        padding: 0 20px;
    }

    .newsletter.gift form {
        padding: 0 25px;
    }

    .newsletter.gift form button {
        margin: 0;
    }

    .newsletter.gift .newsletter-form {
        padding: 26px 0 50px 0;
    }
}

@media (max-width: 575px) {

    .newsletter.gift {
        padding: 40px 0 50px 0;
    }

    .newsletter.gift h3 {
        font-size: 20px;
        line-height: 33px;
        margin-top: 15px;
    }

    .newsletter.gift .form-group.checkbox2 label {
        font-size: 12px;
        line-height: 18px;
    }

    .newsletter .form-control {
        font-size: 13px;
        height: 41px;
    }
}

@media (max-width: 425px) {
    .newsletter.gift h3 {
        padding: 0 11px;
    }
}


/*food-and-wine*/

@media (max-width: 767px) {
    .food-and-wine {
        padding-top: 45px
    }
}

@media (max-width: 575px) {
    .holiday-offers .star-decor {
        display: none;
    }
}


@media (max-width: 425px) {
    .food-and-wine {
        padding-top: 35px
    }

    .holiday-offers.food-and-wine .product-article .product-image a {
        padding: 13px 65px;
    }
}


/*decorated-footer-module*/

@media (max-width: 767px) {
    .decorated-footer-module .footer-decor-3 {
        width: 236px;
    }

    .decorated-footer-module .footer-decor-2 {
        right: 29%;
        width: 142px;
    }
}

@media (max-width: 575px) {

    .decorated-footer-module {
        padding: 15px 0 65px 0;
    }

    .decorated-footer-module .footer-decor-3 {
        width: 203px;
        margin-bottom: -65px;
    }

    .decorated-footer-module .footer-decor-2 {
        display: none;
    }

    .decorated-footer-module .wine-decor {
        width: 200px;
    }
}

@media (max-width: 425px) {
    .decorated-footer-module .footer-decor-3 {
        width: 185px;
        margin-bottom: -87px;
    }

    .decorated-footer-module .footer-decor-2 {
        width: 113px;
        margin-bottom: -9px;
    }

    .decorated-footer-module .wine-decor {
        width: 181px;
    }
}

@media (max-width: 390px) {

    .decorated-footer-module {
        padding: 0 0 65px 0;
    }

    .decorated-footer-module p {
        padding: 0 20px;
    }

    .decorated-footer-module .wine-decor {

        width: 161px;
        margin-bottom: 0;
        margin-left: -36px;

    }
}

@media (min-width: 992px) {
    .product-slide-module.holiday-offers .product-article {
        height: 484px;
    }

    .holiday-offers .product-article {
        height: 514px;
    }

}


/*e-book*/

@media (max-width: 1024px) {
    .intro.intro-book .image-book img:first-child {
        max-width: 680px;
        margin-left: -195px;
        right: 0;
        margin-top: 35px;
    }

    .intro .intro-items .our-logo img {
        max-width: 216px;
    }

    .intro .intro-items .our-logo img:last-child {
        max-height: 30px;
    }

    .intro-book .image-book img.spice {
        margin-left: 373px;
    }

    .intro-book .image-book img.spice {
        margin-top: 74px;
    }

    .from-us.our-team {
        padding: 55px 0;
    }
}

@media (max-height: 992px) and (min-width: 768px) {
    .from-us.our-team .form-container {
        margin: 20px auto 0;
    }
}

@media (max-width: 991px) {

    .from-us.our-team {
        padding: 40px 0;
    }

    .book-landing .intro.intro-book .intro-bg {
        background: url('../../images/lemmon2.png') top 17px left no-repeat, url('../../images/fruit.png') top right no-repeat;
        padding: 30px 0 55px 0;
    }

    .intro .intro-items .our-logo {
        justify-content: center;
    }

    .book-landing .intro.intro-book h1 {
        margin-bottom: 28px;
    }

    .intro-book .image-book {
        text-align: center;
    }

    .from-us.our-team h3 {
        margin-bottom: 12px;
    }

    .book-landing .intro.intro-book .image-book img:first-child {
        position: relative;
        width: 100%;
        margin-right: 0;
        margin-left: -189px;
    }

    .from-us.our-team .our-info {
        padding: 30px 0 0 0;
    }

    .book-landing .intro.intro-book .image-book img.spice {
        position: absolute;
        z-index: 0;
        left: 50%;
        right: auto;
        margin-left: 142px;
        margin-top: 86px;
        width: 71px;
    }

    .from-us.our-team h4 {
        margin: 90px 0 25px 0 !important;
    }


    .from-us.our-team .book-container {
        padding-top: 15px;
    }


    .from-us.our-team .book-container img {
        display: block;
        width: auto;
        max-width: none;
        margin: 0 auto 0 auto;
        position: static;
        padding: 11px 0 0 0;
    }

    .from-us.our-team .book {
        padding-right: 0;
    }


}

@media (max-width: 767px) {
    .from-us.our-team {
        padding: 0 0 35px 0;
    }

    .from-us.our-team .book {
        margin-top: 40px;
    }


}

@media (max-width: 575px) {
    .book-landing .intro.intro-book h1 {
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 15px;
    }

    .book-landing .intro.intro-book .intro-bg {
        padding-top: 10px;
        background: url('../../images/lemmon2.png') top 17px left no-repeat, url('../../images/fruit.png') top -180px right no-repeat;
        padding-bottom: 45px;
    }


    .intro .intro-items .our-logo {
        margin: 24px 0 28px 0;
    }

    .intro .intro-items .our-logo span {
        margin-top: 2px;
        font-size: 15px;
    }

    .intro .intro-items .our-logo img {
        max-width: 165px;
    }

    .intro .intro-items .our-logo img:last-child {
        max-height: 23px;
    }

    .intro-book .image-book img.spice {
        display: none;
    }

    .from-us.our-team .book-container img {
        width: 100%;
        padding: 10px 20px;
    }

    .intro.intro-book .image-book .desktop-book {
        display: none;
    }

    .intro.intro-book .image-book img.mobile-book {
        width: 100%;
        position: static;
        display: block;
        margin: 35px auto 0 auto;
        max-width: 270px;
    }

    .from-us.our-team h4 {
        margin: 55px 0 25px 0 !important;
    }


    .intro.intro-book .free-book {
        padding: 7px 27px;
        font-size: 13px;
    }

    .from-us.our-team .our-info {
        padding: 25px 0 0 0;
    }

    .from-us.our-team .book {
        margin-top: 25px;
    }
}

@media (max-width: 540px) {


    .from-us.our-team h3 {
        line-height: 39px;
    }
}

@media (max-width: 425px) {
    .book-landing .intro.intro-book .image-book img:first-child {
        margin: 35px 0 0 0;
    }

    .intro.intro-book .image-book {
        margin-bottom: 10px;
    }

    .from-us.our-team .form-container,
    .bellini-game .from-us .form-container {
        padding: 30px 20px 15px;
        width: 100%;
        display: block;
    }

    .from-us.our-team h3 {
        line-height: 34px;
    }

    .from-us.our-team .form-container h5 {
        line-height: 30px;
        margin-bottom: 20px;
    }

    .from-us.our-team .form-container .form-check-input {
        margin-left: 0;
    }

    .from-us.our-team .form-container .form-check-label {
        padding-left: 20px;
        margin-bottom: 5px;
    }

    .from-us.our-team .form-container .download-btn {
        padding-left: 0;
        padding-right: 0;
    }

    .from-us.our-team .book {
        height: 258px;
    }
}

@media (max-width: 375px) {
    .from-us.our-team .book {
        height: 227px;
    }

    .intro .intro-items .our-logo span {
        margin-top: -1px;
    }

    .intro .intro-items .our-logo img {
        max-width: 136px;
    }

    .intro .intro-items .our-logo img:last-child {
        max-height: 19px;
    }
}

@media (max-width: 320px) {
    .book-landing .intro.intro-book h1 {
        font-size: 37px;
        line-height: 50px;
    }

    .from-us.our-team .book {
        height: 186px;
    }

    .intro .intro-items .our-logo img {
        margin: 0 10px;
        max-width: 132px;
    }

    .intro .intro-items .our-logo img:last-child {
        max-height: 17px;
    }

    .intro.intro-book .image-book img.mobile-book {
        max-width: 250px;
    }
}

@media (max-height: 640px) and (max-width: 991px) {
    header .menu {
        height: 100vh;
        padding-bottom: 55px;
    }
}