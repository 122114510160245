
* {
    margin: 0;
    padding: 0;
    outline: 0;
}

body,
html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: 'Sofia Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #262020;
}

::selection {
    background: #d7be7e;
    color: #fff;
}

::-moz-selection {
    background: #d7be7e;
    color: #fff;
}

:focus {
    outline: 0
}

img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    border: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

.big-space {
    padding: 200px 0;
}

.space {
    padding: 100px 0;
}

.small-space {
    padding: 50px 0;
}

.space-bot {
    padding-bottom: 100px;
}

/* typography
================================================== */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Moderato G5 L';
    color: #000;
}

h1 {
    font-size: 26px;
    line-height: 32px;
}

h2 {
    font-size: 22px;
    line-height: 28px;
}

h3 {
    font-size: 18px;
    line-height: 28px;
}

h4 {
    font-size: 18px;
    line-height: 28px;
}

h5 {
    font-size: 18px;
    line-height: 28px;
}

h6 {
    font-size: 18px;
    line-height: 28px;
}

strong {
    font-weight: 700;
}

a {
    color: #6A6665;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

a:hover,
a:active,
a:focus {
    color: #9d7a45;
    outline: 0;
    text-decoration: none;
}

p {
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 26px;
}

.heading {
    color: #000;
    font-size: 50px;
    line-height: 50px;
    font-family: 'Moderato G5 L';
    margin: 0 0 35px 0;
    text-transform: uppercase;
    text-align: center;
}

.sub-heading {
    color: #262020;
    font-size: 24px;
    line-height: 30px;
    font-family: 'Moderato E4 L';
    text-align: center;
    width: 60%;
    margin: 0 auto;
    display: table;
}

.btn {
    height: 44px;
    line-height: 44px;
    padding: 0 50px;
    border-radius: 0;
    border-radius: 50px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.btn-link {
    text-transform: uppercase;
    font-family: 'Sofia Sans', sans-serif;
    letter-spacing: 1px;
    position: relative;
    color: #1C1B1B;
    font-size: 18px;
}

.btn-link:after {
    content: "";
    height: 12px;
    width: 12px;
    position: absolute;
    top: 3px;
    right: -22px;
    background: url("../../images/partner-arrow.svg") center center no-repeat;
    background-size: 12px;
}

.btn-link:hover, .btn-link:focus, .btn-link:active {
    color: #9D7A45;
    text-decoration: none;
}

.btn-link:hover:after, .btn-link:focus:after, .btn-link:active:after {
    filter: brightness(0) saturate(100%) invert(44%) sepia(69%) saturate(316%) hue-rotate(357deg) brightness(97%) contrast(88%);
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn-primary {
    background-color: #9d7a45;
    border-color: #9d7a45;
    color: #fff;
}

.btn-primary:hover {
    background-color: #fff;
    border-color: #9d7a45;
    color: #9d7a45;
}

.btn-success:hover,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    background-color: #fff;
    border-color: #28a745;
    color: #28a745;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: #9d7a45;
    color: #9d7a45;
    box-shadow: none;
}

.btn-dark {
    background: #303539;
    border-color: #303539;
    color: #ffffff;
}

.btn-dark:hover {
    background: #181E23;
    border-color: #181E23;
    color: #ffffff;
}

.btn-outline-dark {
    background: #ffffff;
    border-color: #303539;
    color: #303539;
}

.btn-outline-dark:hover {
    background: #303539;
    color: #ffffff;
}

.btn-secondary {
    background-color: #d7be7e;
    border-color: #d7be7e;
    color: #fff;
}

.btn-secondary-outline {
    background-color: transparent;
    border-color: #9d7a45;
    color: #9d7a45;
}

.btn-secondary-outline:hover {
    border-color: #d7be7e;
    background-color: #d7be7e;
    color: #fff;
}

.button-outline-primary {
    color: #059EA5;
    border-color: #059EA5;
    background: #fff;

}

.button-outline-primary:hover,
.button-outline-primary:focus {
    background: #059EA5;
    color: #fff;
}

label {
    margin-bottom: 3px;
}

textarea.form-control {
    padding: 10px 15px;
}

.form-control {
    height: 44px;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #B9B9B9;
    font-size: 16px;
}

.form-control::placeholder {
    color: #b5b2b2;
}

.form-control:focus {
    box-shadow: none;
    border-color: #9d7a45;
}

.form-icon {
    position: relative;
}

.form-icon img {
    position: absolute;
    right: 15px;
    max-height: 20px;
    max-width: 18px;
}

.container {
    max-width: 1300px;
}


#flash-messages-container {
    /*background: white;*/
    /*margin: 0;*/
    /*padding-top: 20px;*/
    /*padding-bottom: 20px;*/
    /*border-right: 1px solid #dcdad9;*/
}

/*top*/
.top {
    background-color: #d7be7e;
    padding: 10px 0;
}

.top .top-item {
    display: inline-block;
    position: relative;
    margin-right: 30px;
}

.top .top-item:last-of-type:after {
    display: none;
}

.top .top-item:after {
    content: "";
    height: 15px;
    width: 1px;
    background-color: #753e86;
    display: block;
    position: absolute;
    top: 3px;
    right: -16px;
}


/*langs*/
.langs ul .lang-item {
    display: inline-block;
    position: relative;
}

.langs .lang-icon {
    margin-right: 10px;
}

.langs ul .lang-item .lang-item-link .nav-arrow {
    margin-left: 2px;
}

.langs ul .lang-item .lang-item-link {
    color: #fff;
    display: block;
    position: relative;
}

.langs ul > .lang-item:hover > .sub-lang {
    display: block;
}

.langs ul .sub-lang:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #9d7a45;
}

.langs ul.sub-lang {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: auto;
    margin-top: 10px;
    padding: 8px 0;
    display: block;
    display: none;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.langs ul.sub-lang:after {
    content: "";
    height: 16px;
    width: 100%;
    position: absolute;
    top: -16px;
    left: 0;
}

.langs ul.sub-lang li:hover > ul.sub-lang {
    display: block;
}

.langs ul.sub-lang li > ul.sub-lang {
    top: 0;
    left: 0;
    display: none;
}

.langs ul .sub-lang .sub-lang-item {
    display: block;
    position: relative;
}

.langs ul .sub-lang .sub-lang-item-link {
    display: block;
    position: relative;
    padding: 5px 20px;
}

.langs ul .sub-lang .sub-lang-item-link:hover {
    color: #9d7a45;
}


/*social*/
.social ul li {
    display: inline-block;
    margin: 0 5px;
}

.social ul li a {
    color: #fff;
}

.social ul li a:hover {
    opacity: .8;
}

/*vouchers*/
.vouchers a {
    color: #fff;
    opacity: .7;
}

.vouchers a:hover {
    text-decoration: underline;
    opacity: 1;
}

/*phone*/
.phone {
    color: #fff;
}

.phone span {
    opacity: .7;
}

.phone a {
    color: #fff;
    margin-left: 10px;
}

.phone a:hover {
    text-decoration: underline;
}


/*delivery hint*/
.delivery-hint {
    background-color: #6a6665;
    padding: 8px 0;
    text-align: center;
    color: #eaeaea;
}

.delivery-hint span {
    margin: 0 10px;
    opacity: .7;
}


/*user widgets*/
.user-widgets {
    text-align: right;
}

.user-widgets.user-widgets-left {
    text-align: left;
}

.user-widgets.user-widgets-left .widget-item:first-of-type {
    padding-left: 0;
}

.user-widgets .widget-item {
    display: inline-block;
    padding: 0 10px;
    position: relative;
}

.user-widgets .widget-item .fav-num {
    position: absolute;
    left: 25px;
    top: -2px;
    background-color: #9d7a45;
    color: #e2e2e2;
    width: 13px;
    height: 13px;
    line-height: 12px;
    font-size: 9px;
    text-align: center;
    border-radius: 100%;
}

.user-widgets .widget-item .cart-num {
    position: absolute;
    left: 21px;
    top: -2px;
    background-color: #B89D65;
    color: #e2e2e2;
    width: 15px;
    height: 15px;
    line-height: 14px;
    font-size: 11px;
    text-align: center;
    border-radius: 100%;
}

.user-widgets .widget-item .cart-sum {
    display: inline-block;
    font-size: 11px;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.user-widgets .widget-item .icn {
    height: 18px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.user-widgets .widget-item:hover .icn {
    opacity: .4;
}


/*user nav*/
.user-widget .user-nav {
    position: absolute;
    top: 43px;
    right: 0;
    z-index: 3;
    background-color: #fff;
    padding: 10px 0 10px;
    text-align: center;
    display: none;
    border-top: 3px solid #9d7a45;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.user-widget .user-nav:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

.user-widget .user-nav ul li {
    white-space: nowrap;
}

.user-widget .user-nav ul li a {
    display: block;
    position: relative;
    padding: 5px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
}

.user-widget:hover .user-nav {
    display: block;
}

.category-text {
    margin: 0 0 50px 0;
}

.category-text h2,
.category-text h3,
.category-text h4,
.category-text h5,
.category-text h6 {
    margin: 0 0 10px 0;
}


.error-page {
    text-align: center;
    padding: 0 50px;
}

.error-page .error-code {
    font-size: 120px;
    line-height: 90px;
    font-weight: bold;
    margin: 0 0 18px 0;
    color: #333;
    font-family: Arial;
}

.error-page .error-text {
    margin: 0 0 20px 0;
    font-size: 14px;
    line-height: 18px;
}


/*header*/
header {
    padding: 25px 0;
    position: fixed;
    width: 100%;

    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;

    z-index: 125;
}

header.fixed .logo {
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

header.fixed .logo img {
    height: 45px;
}

header.fixed ~ .page {
    padding-top: 63px !important;
}

header.fixed {
    padding: 15px 0;
    background-color: #093a52;
}

header.fixed nav {
    margin: 0 auto 0;
}

header .logo {
    position: absolute;
    top: 0;
    left: 50%;

    -ms-transform: translate(-50%, -25%);
    transform: translate(-50%, -25%);
}

header .logo img {
    height: 140px;

    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

header .user-widgets .widget-item {
    padding: 0 17px;
    position: relative;
}

header .user-widgets .widget-item:after {
    content: "";
    position: absolute;
    top: 4px;
    right: -1px;
    width: 1px;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    display: block;
}

header .user-widgets .widget-item:last-of-type:after {
    display: none;
}

header .user-widgets .lang-widget .val {
    position: relative;
    top: 2px;
}

header .user-widgets .lang-widget .val a {
    color: #fff;
}

header .user-widgets .lang-widget .val a:hover {
    opacity: .4;
}

header .lang-widget {
    color: #fff;
}

header.error-page {
    background: #093a52;
}

header.error-page .logo {
    transform: translate(-50%, -30%);
}

header.error-page .logo img {
    height: 110px;
}

/*search*/
.search {
    position: relative;
    max-width: 300px;
}

.search.open {
    display: block;
}

.search input[type=text] {
    border: 0;
    padding: 10px 0;
    width: 100%;
    padding-right: 38px;
    border-bottom: 2px solid #d9d9d9;
}

.search button {
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 3px;
    top: 10px;
    color: #d9d9d9;
    outline: none;
}

.search button img {
    height: 22px;
}

.search .search-results {
    overflow: auto;
    position: relative;
    padding: 10px 0;
}

.search .search-container {
    position: absolute;
    top: 43px;
    padding: 0 15px;
    z-index: 4;
    background-color: #fff;
    width: 100%;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.search .search-results .found {
    font-size: 11px;
}

.search .search-results .result-row {
    padding: 15px 15px;
    background-color: #fff;
    border-top: 0;
    text-align: left;
    border-bottom: 1px solid #d9d9d9;
}

.search .search-results .result-row:last-of-type {
    border-bottom: 0;
}

.search .search-results .result-row .product-title {
    font-size: 11px;
    line-height: 16px;
    font-weight: bold;
    color: #000;
    display: inline-block;
    font-family: 'FSerProB', sans-serif;
}

.search .search-results .result-row .product-title:hover {
    color: #9d7a45;
}

.search .all-results {
    text-align: center;
    display: block;
    font-size: 11px;
    padding: 10px 0;
    border-top: 1px solid #d9d9d9;
    color: #ababab;
}

.search .all-results:hover {
    color: #9d7a45;
}

.search .no-results {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    color: #ababab;
}


/*cart*/
.cart-container {
    position: absolute;
    top: 43px;
    right: 0;
    width: 315px;
    z-index: 11;
    background-color: #fff;
    padding: 10px 15px 20px;
    text-align: left;
    display: none;
    border-top: 3px solid #9d7a45;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.cart-container:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

/*.cart-widget:after {*/
/*    content: "";*/
/*    height: 2px;*/
/*    width: 100%;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    bottom: -22px;*/
/*    background-color: #9d7a45;*/
/*    display: none;*/
/*    z-index: 11;*/
/*}*/

.cart-widget:hover:after {
    display: block;
}

.cart-widget:hover .cart-container {
    display: block;
}

.cart-container .cart-result {
    border-bottom: 1px solid #ddd;

    max-height: 340px;
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}

.ps > .ps__rail-x,
.ps > .ps__rail-y {
    opacity: 0.6;
}

.cart-container .cart-result .result-row {
    padding: 15px 0;
    border-bottom: 1px solid #d9d9d9;
}

.cart-container .cart-result .result-row:last-child {
    border: 0 !important;
}

.cart-container .cart-result .product-title {
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;
    position: relative;
    display: block;
    margin: 0 0 10px 0;
    font-family: 'Sofia Sans', sans-serif;
    color: #000;
}

.cart-container .cart-result .product-remove {
    position: relative;
    top: -4px;
    font-size: 21px;
    line-height: 1;
    cursor: pointer;
    font-weight: normal;
    color: #dcdcdc;
}

.cart-container .cart-result .product-mill {
    color: #acacac;
}

.cart-container .cart-total {
    font-size: 16px;
    padding: 10px 0;
    color: #acacac;
}

.cart-container .cart-result .product-amount {
    color: #acacac;
}

.cart-container .cart-result .product-amount span {
    font-size: 16px;
    line-height: 24px;
    color: #000;
    font-weight: bold;
}

.cart-container .cart-result .product-amount small {
    font-size: 13px;
    line-height: 18px;
    color: #6A6665;
}

.cart-container .empty-cart {
    padding: 20px;
    text-align: center;
    color: #cecece;
}

.cart-container .empty-cart img {
    margin: 0 auto 15px;
    display: block;
    height: 60px;
}

.cart-container .cart-total span {
    font-size: 20px;
    line-height: 26px;
    color: #000;
    float: right;
    font-family: 'Moderato G5 L';
    font-weight: bold;
}

.cart-container .btn {
    width: 100%;
    display: block;
    margin: 5px 0 0 0;
}


.cart-empty {
    text-align: center;
}

.cart-empty img {
    height: 150px;
    margin: 0 0 10px 0;
}

/* menu */
.mega-menu {
    position: fixed;
    width: 100%;
    background-color: #093a52;
    padding: 200px 0;
    text-align: center;
    height: 0;
    display: none;
    z-index: -1;
}

.mega-menu.active {
    height: 100%;
    display: block;
    z-index: 120;
}

.mega-menu .menu-heading {
    color: #fff;
    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0 0 50px 0;
    letter-spacing: 2px;
}

.mega-menu ul li a {
    color: #b3ab9b;
    font-size: 22px;
    line-height: 28px;
    margin: 30px 0;
    display: block;
    font-family: 'Moderato E4 L';
    font-style: italic;
}

.mega-menu ul li a:hover {
    color: #B89D65;
}

.mega-menu .menu-product img {
    height: 170px;
    display: block;
    margin: 0 auto;

    -webkit-transition: all .2s;
    transition: all .2s;
}

.mega-menu .menu-product:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.mega-menu .menu-product span {
    color: #b3ab9b;
    font-size: 22px;
    line-height: 28px;
    margin: 30px 0;
    display: block;
    font-family: 'Moderato E4 L';
    font-style: italic;

    -webkit-transition: all .2s;
    transition: all .2s;
}

.mega-menu .menu-product:hover span {
    margin-top: 40px;
    color: #B89D65;
}


#nav-toggle {

}

#nav-toggle {
    cursor: pointer;
    height: 33px;
    font-family: 'Sofia Sans', sans-serif;
    display: table;
}

#nav-toggle span {
    margin: 15px 0 0 0;
}

#nav-toggle span {
    width: 25px;
}

#nav-toggle.active span:after {
    width: 35px;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 1px;
    background: white;
    position: absolute;
    display: block;
    content: '';
}

#nav-toggle span:before {
    width: 35px;
}

#nav-toggle span:before {
    top: -10px;
}

#nav-toggle span:after {
    bottom: -10px;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
    transition: all .3s ease-in-out;
}

#nav-toggle.active span {
    background-color: transparent;
}

#nav-toggle.active span:before,
#nav-toggle.active span:after {
    top: 0;
}

#nav-toggle.active span:before {
    transform: rotate(45deg);
}

#nav-toggle.active span:after {
    transform: rotate(-225deg);
}


/*intro*/
.intro h2 {
    color: #4e1e50;
    font-size: 44px;
    line-height: 60px;
    font-weight: bold;
    margin: 0 0 30px 0;
}

.intro .btn {
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 0 45px;
}

.intro .carousel-caption {
    bottom: 15%;
}

.intro .carousel-indicators {
    bottom: 30px;
    z-index: 5;
}

.intro .carousel-item {
    background-size: cover;
}

/*benefits*/
.home .benefits {
    border-bottom: 2px solid #efe1d4;
    padding: 60px 0 40px;
}

.home .benefits .benefit {
    position: relative;
}

.home .benefits .benefit p {
    text-align: center;
    font-size: 9px;
    color: #fff;
    position: absolute;
    top: 42%;
    width: 100%;
    padding: 0 10px;
    height: 38px;
    line-height: 38px;
    background-color: #9d7a45;
    border-color: #9d7a45;
    box-shadow: 8px 10px 10px #ddd;
    opacity: 0;
}

.home .benefits .benefit:hover p {
    opacity: 1;
}

.home .benefits .benefit .benefit-img {
    height: 100px;
    margin: 0 0 15px 0;
}

.home .benefits .benefit .benefit-img img {
    height: 100px;
    margin: 0 auto;
    display: block;
}

.home .benefits .benefit h5 {
    border-color: #9d7a45;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    padding: 0 20px;
    height: 44px;
    font-family: 'FedraSansPro', sans-serif;
}

.home .benefits .benefit .btn {
    padding: 0 10px;
    font-size: 12px;
    text-transform: initial;
    display: block;
    letter-spacing: 0;
    opacity: 0;
    position: absolute;
    top: 42%;
    width: 100%;
    box-shadow: 8px 10px 10px #ddd;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.home .benefits .benefit:hover .btn {
    opacity: 1;
}


/*benefits*/
.mystery-box .benefits,
.seewines-magnum .benefits,
.wine-tests-page .benefits {
    border-bottom: 2px solid #efe1d4;
    padding: 100px 0 50px;
}

.mystery-box .benefits .benefit,
.seewines-magnum .benefits .benefit,
.wine-tests-page .benefits .benefit {
    position: relative;
    text-align: center;
    margin: 0 0 50px 0;
}

.mystery-box .benefits .benefit .benefit-img,
.seewines-magnum .benefits .benefit .benefit-img,
.wine-tests-page .benefits .benefit .benefit-img {
    height: 100px;
    margin: 0 0 20px 0;
}

.mystery-box .benefits .benefit .benefit-img img,
.seewines-magnum .benefits .benefit .benefit-img img,
.wine-tests-page .benefits .benefit .benefit-img img {
    height: 100px;
    margin: 0 auto;
    display: block;
}

.mystery-box .benefits .benefit h5,
.seewines-magnum .benefits .benefit h5,
.wine-tests-page .benefits .benefit h5 {
    color: #4e1e50;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    padding: 0 20px;
    font-family: 'FedraSansPro', sans-serif;
    margin: 0 0 15px 0;
}

.mystery-box .benefits .benefit p {
    padding: 0 30px;
}

.mystery-box .package .heading {
    text-transform: inherit;
    color: #4e1e50;
}

.mystery-box .package .package-price {
    background-color: #FAD2D3;
    color: #000;
    height: 250px;
    width: 250px;
    border-radius: 100%;
    font-size: 26px;
    line-height: 38px;
    padding: 70px 0;
    text-align: center;
    text-transform: uppercase;
    font-family: 'FSerProB', sans-serif;
    margin: 0 auto;
    display: block;
}

.mystery-box .package .package-price span {
    font-family: 'FedraSansPro', sans-serif;
    display: block;
    color: #9d7a45;
    font-size: 36px;
}

.mystery-box .package .package-info {
    padding: 0 50px;
}

.mystery-box .package .package-info li {
    position: relative;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    padding-left: 60px;
    margin: 0 0 30px 0;
    color: #000;
    font-family: 'FSerProB', sans-serif;
}

.mystery-box .package .package-info li .item-icon {
    position: absolute;
    left: 0;
    width: 40px;
}

.mystery-box .package .package-info li .item-icon img {
    margin: 0 auto;
    display: block;
    max-width: 40px;
    max-height: 40px;
}

.mystery-box .package .package-info li:last-of-type {
    margin: 0;
}

.mystery-box .package .package-info li span {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    display: block;
    font-family: 'FedraSansPro';
    color: #929292;
}

.mystery-box .package .btn {
    margin: 50px 0 0;
    display: block;
    width: 100%;
}


.mystery-box-order {
    padding: 0 0 100px 0;
}

.gift-package {
    padding: 20px;
    border: 1px solid #e3e2e2;
    background-color: #fff;
}

.gift-card,
.gift {
    padding: 15px 25px;
    border: 1px solid #e3e2e2;
    margin: 0 0 30px 0;
}

.gift-card .switch,
.gift .switch {
    float: right;
}

.gift-card h3,
.gift h3 {
    border-color: #9d7a45;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    padding: 0 0 5px 0;
}

.gift-card p,
.gift p {
    margin: 0;
}

.gift-card-action h3 .switch,
.gift-action h3 .switch {
    float: right;
}

.gift-action h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    border-color: #9d7a45;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.gift-action .choose-period {
    margin: 20px 0 30px 0;
}

.gift-action .choose-period h4 {
    margin: 0 0 5px 0;
}

.gift-action .choose-period p {
    margin: 0 0 20px 0;
}

.gift-card-action .card-text {
    margin: 20px 0 0 0;
}

.gift-card-action .card-text .card-lab {
    padding: 15px 20px;
    border-top: 1px solid #e3e2e2;
    border-left: 1px solid #e3e2e2;
    border-right: 1px solid #e3e2e2;
}

.gift-card-action .card-text .card-lab img {
    margin-right: 15px;
}

.gift-card-action .card-text .card-lab h5 {
    font-size: 16px;
    line-height: 22px;
    font-family: 'FedraSansPro', sans-serif;
}

.gift-card-action .card-text .card-lab p {
    font-size: 13px;
    margin: 0;
}

.gift-card-action .card-text .form-control {
    width: 100%;
}

.gift-card-action h3 {
    border-color: #9d7a45;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    padding: 0 0 15px 0;
}


.mystery-box-order .config {
    padding: 25px;
    background-color: #FBFAF9;
    margin: 0 0 30px 0;
}

.mystery-box-order .config .or {
    font-size: 19px;
    font-weight: 900;
    color: #ffffff;
    display: table;
    margin: 0 auto 0 auto;
    background-color: #d7be7e;
    padding: 0 10px 0 10px;
    letter-spacing: 1px;
}

.mystery-box-order .config h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    border-color: #9d7a45;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.mystery-box-order .choose-date h4,
.mystery-box-order .choose-period h4 {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    font-weight: bold;
    margin: 0 0 15px 0;
}

.mystery-box-order .choose-period ul {
    border: 1px solid #9d7a45;
    font-size: 0;
    display: table;
}

.mystery-box-order .choose-period ul li {
    width: 2%;
    font-size: 12px;
    display: table-cell;
    border-right: 1px solid #9d7a45;
    text-align: center;
    height: 42px;
    line-height: 42px;
    cursor: pointer;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.mystery-box-order .choose-period ul.has-promos li {
    vertical-align: middle;
    position: relative;
}

.mystery-box-order .choose-period ul.has-promos li .badge {
    position: absolute;
    top: -7px;
    left: calc(50% - 18px);
}


.mystery-box-order .choose-period ul li:last-of-type {
    border-right: 0;

}

.mystery-box-order .choose-period ul li:hover {
    background-color: #f3ecf5;
}

.mystery-box-order .choose-period ul li.selected {
    background-color: #d7be7e;
    color: #fff;
}

.mystery-box-order .choose-date,
.mystery-box-order .choose-period {
    background-color: #fff;
    padding: 20px;

    box-shadow: 0 0 10px 7px rgba(103, 103, 103, .05);
}

.mystery-box-order .choose-date .btn {
    width: 100%;
}


.cart {
    padding: 45px 0 100px;
}

.cart h1 {
    font-size: 50px;
    line-height: 58px;
    font-family: 'Moderato G7 L';
}

.maybe {
    padding: 50px 0 0 0;
}

.back-to-shop {
    font-size: 11px;
}

.back-to-shop + .cart-headings {
    margin-top: 20px;
    font-weight: bold;
}

.cart-benefits {
    padding: 30px 0 0;
}

.guarante-cart {
    text-align: center;
    margin: 20px 0 0 0;
}

.guarante-cart img {
    margin-right: 5px;
}

.cart-list {
    background-color: #F7F5F2;
    padding: 10px 25px;
}

.cart-list .product-name {
    color: #262020;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Moderato G5 L';
    display: inline-block;
}

.cart-list .product-name:hover {
    color: #9d7a45;
}

.cart-list .product-old-price {
    color: #ddd;
}

.remove-product:focus {
    outline: none;
}

.amount-total {
    font-weight: bold;
}

.remove-product img {
    width: 16px;
}

.remove-product {
    outline: none;
    border: 0;
    background-color: transparent;
    margin-left: 10px;
    opacity: .5;
    position: absolute;
    top: 0;
    right: 15px;

    width: 16px;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.remove-product:hover {
    opacity: 1;
}

.cart-list .product-desc {
    font-size: 11px;
    line-height: 18px;
    color: #afafaf;
}

.cart-list .product-desc div {
    margin-bottom: 2px;
}

.cart-delivery-hint {
    background-color: #FEF6E8;
    padding: 10px 15px;
    margin: 25px 0 20px 0;
    border: 1px solid #F5BA7F;
    color: #C57844;
}

.cart-headings {
    padding: 10px 25px;
    font-weight: bold;
}

.cart-list .cart-row {
    padding: 25px 0;
    border-bottom: 1px solid #E3E2E2;
}

.cart-list .cart-row .package-discounts .badge {
    font-size: 12px;
    margin: 5px 0;
}

.cart-list .cart-row:last-of-type {
    border: 0;
}


.promo-code {
    padding: 25px;
    border: 1px solid #e3e2e2;
    margin: 0 0 30px 0;
}

.promo-code .btn {
    display: block;
    padding: 0 15px;
}


.checkout {
    padding: 0 0 100px 0;
}

.checkout .payment-method {
    margin: 0 0 15px 0;
}


.total {
    padding: 25px 0 0;
    border: 1px solid #e3e2e2;
    border-bottom: 0;
}

.total.with-border {
    border-bottom: 1px solid #e3e2e2;
}

.total h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 25px 25px 25px;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
}

.total .product-row {
    margin: 0 25px;
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
}

.total .product-row:first-of-type {
    padding-top: 0;
}

.total .product-row:last-of-type {
    border: 0;
}

.total .product-row .product-img {
    margin-right: 15px;
}

.total .product-row .product-name {
    font-family: 'FSerProB', sans-serif;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #000;
}

.total .product-row .product-desc {
    color: #6A6665;
    font-size: 11px;
    line-height: 16px;
}

.total .product-row .product-old-price {
    text-decoration: line-through;
    color: #ddd;
}

.total .product-row .product-price {
    color: #414141;
    font-size: 17px;
    line-height: 26px;
    text-align: right;
    font-weight: bold;
}

.cart .sum {
    padding-top: 0;
}

.total-action .sum {
    margin: 0 0 0;
    padding: 0 0 25px 0;
}

.total-action .checkbox label a {
    text-decoration: underline;
}

.sum {
    padding: 10px 0 25px 0;
    margin: 0 25px 0;
}

.sum ul li span {
    float: right;
    font-weight: bold;
}

.sum ul {
    font-size: 17px;
    line-height: 26px;
}

.sum ul li {
    padding: 3px 0;
}

.sum ul li:last-of-type {
    font-size: 20px;
    line-height: 26px;
    color: #262020;
    font-family: 'Moderato G7 L';
}

.back-to-shop {
    color: #1C1B1B;
    font-size: 16px;
    line-height: 26px;
    text-decoration: underline;
}

.back-to-shop i {
    margin-right: 3px;
}

.sum ul li:last-of-type span {
    font-size: 21px;
    line-height: 24px;
    font-weight: normal;
}

.total .total-hint {
    padding: 20px 25px 20px 60px;
    position: relative;
    border-top: 1px solid #e3e2e2;
    background-color: #FBFAF9;
    margin: 0 0 0 0;
}

.total .total-hint p {
    color: #6A6665;
}

.total .total-hint p:last-of-type {
    margin: 0;
}

.total .total-hint img {
    position: absolute;
    top: 25px;
    left: 20px;
}

.total-action {
    padding: 25px;
    border: 1px solid #e3e2e2;
    background-color: #fff;
}

.total-action .checkbox {
    margin: 0 0 20px 0;
}

.total-action .btn {
    display: block;
    width: 100%;
}

.sticky-top {
    top: 140px;
    z-index: 3;
}

.order-payment {
    background-color: #F7F5F2;
    padding: 25px;
}

.order-payment h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
}

.order-payment .payment-method {
    position: relative;
}

.order-payment .payment-method input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -7.4px;
}

.order-payment .payment-method input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #161615;
}

.order-payment .payment-method label {
    border: 1px solid #E3E2E2;
    padding: 20px 60px 20px 45px;
    width: 100%;
    cursor: pointer;
}

.order-payment .payment-method.payment-card label {
    padding: 20px 20px 20px 35px;
}

.order-payment .payment-method.payment-card label img {
    height: 19px;
}

.order-payment .payment-method label .payment-icon {
    position: absolute;
    top: 28px;
    right: 20px;
}

.order-payment .payment-method label .payment-icon img {
    max-height: 40px;
    display: block;
}

.order-payment .payment-method label .payment-name {
    font-family: 'Moderato G7 L';
    color: #262020;
    font-size: 18px;
    line-height: 26px;
}

.order-payment .payment-method label .payment-desc {
    color: #262020;
    font-size: 15px;
    line-height: 18px;
}


.order-delivery {
    background-color: #F7F5F2;
    padding: 25px;
    margin: 0 0 30px 0;
}

.order-delivery h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
}

.order-delivery .delivery-method {
    position: relative;
    margin: 0 0 20px 0;
}

.order-delivery .delivery-method input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.5px;
}

.order-delivery .delivery-method input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #161615;
}

.order-delivery .delivery-method label {
    border: 1px solid #B9B9B9;
    padding: 20px 20px 20px 45px;
    width: 100%;
    cursor: pointer;
}

.order-delivery .delivery-method label .delivery-name {
    font-family: 'Moderato G7 L';
    color: #262020;
    font-size: 18px;
    line-height: 26px;
}

.order-delivery .delivery-method label .delivery-desc {
    color: #262020;
    font-size: 15px;
    line-height: 18px;
}


.order-delivery h4 {
    margin: 0 0 15px 0;
    border-color: #9d7a45;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
}

.order-delivery .delivery-types {
    margin: 15px 0 0 0;
}

.order-delivery .delivery-type {
    position: relative;
    margin: 0 0 20px 0;
}

.order-delivery .delivery-type input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.5px;
}

.order-delivery .delivery-type input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #161615;
}

.order-delivery .delivery-type label {
    border: 1px solid #B9B9B9;
    padding: 20px 10px 20px 45px;
    width: 100%;
    margin: 0;
    cursor: pointer;
}

.order-delivery .delivery-type label .delivery-type-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -15px;
}

.order-delivery .delivery-type label .delivery-type-icon img {
    max-height: 25px;
    display: block;
}

.order-delivery .delivery-type label .delivery-type-name {
    font-family: 'Moderato G7 L';
    color: #262020;
    font-size: 18px;
    line-height: 26px;
}

.order-delivery .delivery-type label .delivery-type-desc {
    color: #6A6665;
    font-size: 11px;
    line-height: 16px;
}


.order-info {
    background-color: #F7F5F2;
    padding: 25px;
    margin: 0 0 30px 0;
}

.date-info {
    background-color: #FBFAF9;
    padding: 25px;
    margin: 0 0 30px 0;
}

.order-info.payment-info {
    padding: 0;
}

.order-info h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
}

.order-info label {
    margin: 0 0 4px 0;
    font-weight: bold;
}

.order-info .form-control {
    border-color: #B9B9B9;
}

.order-info .form-msg {
    color: #9d7a45;
    font-size: 12px;
    margin: 5px 0 0 0;
}


.products {
    padding-bottom: 100px;
}

.products .hero-area {
    padding: 50px 0;
}

.products .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
}

.products .hero-area p {
    color: #fff;
}

.products .hero-area a {
    color: #fff;
    text-decoration: underline;
}


.sort h3 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sort.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sort h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sort.active .sort-result {
    display: block;
}

.sort .sort-result {
    margin: 0 0 30px 0;
    display: none;
}

.sort .results-count {
    line-height: 44px;
}


.countries h3 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 20px 0;
    position: relative;
}

.countries.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.countries h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.countries.active .countries-result {
    display: block;
}

.countries .countries-result {
    display: none;
}

.countries {
    margin: 0 0 30px 0;
}

.countries .view-all {
    text-align: center;
    padding: 11px 0;
}

.countries .view-all a {
    color: #afafaf;
    cursor: pointer;
    text-decoration: underline;
}

.countries .view-all a:hover {
    color: #9d7a45;
}

.countries .clear-filter {
    padding: 11px 0;
    text-align: left;
}

.countries .clear-filter button img {
    height: 20px;
    position: relative;
    top: -1px;
    margin-right: 1px;
}

.countries .clear-filter button {
    color: #afafaf;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: 0;
    transition: all .4s;
}

.countries .clear-filter button:hover {
    color: #9d7a45;
}

.countries h3 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
}

.countries .country {
    padding: 10px;
    border: 1px solid #e3e2e2;
    margin: 0 0 20px 0;
    text-align: center;
    border-color: #9d7a45;
    cursor: pointer;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.countries .country.active {
    background-color: #d7be7e;
    color: #fff;
    border-color: #9d7a45;
}

.countries .country:hover {
    border-color: #9d7a45;
}


/* product */
.product .product-img {
    position: relative;
    position: sticky;
    top: 70px;
    left: 0;
    margin: 0 0 50px 0;
}

.product .product-add-fav {
    background-color: transparent;
    border: 0;
    padding: 10px 0;
    outline: none;
    transition: all .4s;
}

.product .product-add-fav:hover {
    opacity: .4;
}

.product .assortment {
    padding-bottom: 100px;
}

.product .product-info {
    padding: 40px 0;
}

.product h1 {
    font-size: 50px;
    line-height: 60px;
    font-weight: bold;
    color: #262020;
    text-transform: uppercase;
}

.product .product-src {
    margin: 15px 0;
}

.product .product-stock.in-stock {
    color: #179767;
    margin: 0 0 15px 0;
}

.product .product-stock.not-in-stock {
    color: #9d7a45;
    margin: 0 0 15px 0;
}

.product .product-stock.in-stock i, .product .product-stock.not-in-stock i {
    margin-right: 5px;
}

.product .product-rate {
    position: relative;
    display: table;
}

.product .product-rate .rate-tooltip {
    position: absolute;
    top: 40px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #343a40;
    z-index: 3;
    display: none;
    width: 320px;

    box-shadow: 0 7px 10px 0 rgba(162, 162, 162, 0.12),
    0 0 1px 0 rgba(144, 144, 144, 0.25);
}

.product .product-rate .rate-tooltip:after {
    content: "";
    position: absolute;
    top: -23px;
    left: 0;
    height: 23px;
    width: 100%;
}

.product .product-rate .rate-tooltip:before {
    content: "";
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    position: absolute;
    top: -11px;
    left: 93px;
    background-color: #fff;
    border-left: 1px solid #343a40;
    border-top: 1px solid #343a40;
}

.product .product-rate:hover .rate-tooltip {
    display: block;
}

.product .product-rate .rate-tooltip .btn {
    margin: 15px auto 0;
    display: table;
    text-decoration: none;
}

.product .product-rate .rate-tooltip .rate-list ul {
    padding: 0 30px;
}

.product .product-rate .rate-list ul li {
    margin-bottom: 10px;
}

.product .product-rate .rate-list ul li i {
    border-color: #9d7a45;
    font-size: 16px;
}

.product .product-rate .rate-list ul li .val {
    font-family: 'FedraSansPro', sans-serif;
    display: inline-block;
}

.product .product-rate .rate-list ul li .rate-progress-bar {
    display: inline-block;
    background-color: #3c4046;
    height: 5px;
    position: absolute;
}

.product .product-rate .rate-list ul li .rate-progress {
    position: relative;
    background-color: #CAC7C6;
    width: 100%;
    height: 5px;
}

.product .product-rate > ul {
    display: inline-block;
}

.product .product-rate > ul li {
    display: inline-block;
}

.product .product-rate > ul li i {
    color: #4E1E50;
}

.product .product-rate .rate {
    display: inline-block;
    margin: 0 0 0 10px;
}

.product .product-rate a {
    display: inline-block;
    font-size: 11px;
    text-decoration: underline;
}


.product .product-desc {
    margin: 20px 0 0;
}


.variant-error {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 24px;
    color: #9d7a45;
}

.variant-error i {
    margin-right: 3px;
    font-size: 12px;
    position: relative;
    top: -1px;
}


.product .product-variants {
    background-color: #FBFAF9;
    padding: 15px;
    margin: 20px 0;
}

.product .product-variants.alerts-border {
    border: 1px #ff0000 solid;
    animation: blink 1s;
    animation-iteration-count: 1;
}

@keyframes blink {
    50% {
        border-color: #FBFAF9;
    }
}


.product .product-variants .lab {
    margin: 0;
}

.product .product-variants .product-variant {
    display: inline-block;

    margin: 10px 30px 5px 0;
    padding: 5px 20px;
    background-color: #fff;
    color: #4E1E50;
    border: 2px solid #e3e2e2;
    cursor: pointer;
    position: relative;
    opacity: 0.7;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.product .product-variants .product-variant.selected {
    border-color: #000;
    color: #000;
    opacity: 1;
}

.product .product-variants .product-variant.unavailable {
    opacity: 0.6;
}

.product .product-variants .product-variant:hover {
    border-color: #000;
    background-color: #FBFAF9;
}

.product .product-variants .product-variant img {
    height: 22px;
    margin-right: 10px;
}

.product .product-variants .product-variant .variant-discount {
    position: absolute;
    top: -14px;
    right: -18px;
    background-color: #E81E25;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 100%;
    font-size: 15px;
}

.product .product-prices {
    margin: 0 0 20px 0;
}

.product .product-info .buy {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-size: 1em;
}

.product .product-prices .new-price {
    font-size: 36px;
    line-height: 36px;
    font-family: 'Moderato G7 L';
    color: #000;
    display: inline-block;
    margin-right: 10px;
}

.product .product-prices .old-price {
    display: inline-block;
    text-decoration: line-through;
    color: #bbb;
}

.product .product-delivery-hint {
    margin: 20px 0;
}

.product .product-delivery-hint h5 {
    font-size: 14px;
    line-height: 20px;
}

.product-quantity {
    position: relative;
    border: 1px solid #e3e2e2;
}

.product .product-features {
    padding: 70px 0;
}

.product .product-features.gray {
    background-color: #FBFAF9;
}

.product .product-features h4 {
    font-size: 18px;
    line-height: 24px;
    color: #4E1E50;
    margin: 0 0 15px 0;
}

.product .temperature img {
    margin: 0 0 8px 0;
}

.product .temperature span {
    display: block;
}

.product .region-info span {
    display: block;
    color: #c1c1c1;
}

.product .region-info span a {
    display: block;
    color: #c1c1c1;
}

.product .region-info .flag {
    display: block;
    margin: 5px 0 5px 0;
}

.product .product-sort {
    padding: 0 0 6px 0;
    margin-bottom: 50px;
    border-bottom: 1px solid #e3e2e2;
}

.product .product-aromas ul li {
    display: inline-block;
    text-align: center;
    line-height: 16px;
}

.product .product-aromas ul li img {
    display: block;
    margin: 0 auto 10px;
}

.product .suitable-foods ul li {
    display: inline-block;
    text-align: center;
    line-height: 18px;
}

.product .suitable-foods ul li img {
    display: block;
    margin: 0 auto 10px;
}

.product .product-profile ul li {
    padding: 12px 0 12px 0;
    border-bottom: 1px solid #e3e2e2;
}

.product .product-profile ul li .ingredient {
    display: inline-block;
    width: 95px;
}

.product .product-profile ul li .dot.empty {
    background-color: #DBD8DB;
}

.product .product-profile ul li .dot {
    background-color: #E81E25;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin: 0 4px;
    display: inline-block;
}

.review-modal button.close {
    color: #fff;
    opacity: 1;
}

.review-modal button.close:focus {
    border: 0;
    outline: none;
}

.review-modal .review-info select.form-control {
    display: inline-block;
    width: auto;
    padding: 0 8px;
    margin-left: 10px;
}

.add-review .modal-body {
    padding: 0;
}

.add-review .review-info {
    background-color: #FBFAF9;
    border-left: 1px solid #DCDBDA;
    padding: 30px 40px;
}

.add-review .product-rate {
    padding: 30px 0;
    font-size: 17px;
    font-weight: bold;
    color: #222;
}

.add-review .product-rate > ul {
    display: inline-block;
    margin-left: 15px;
    position: relative;
    top: 5px;
}

.add-review .product-rate > ul li {
    display: inline-block;
    margin-right: 7px;
}

.add-review .product-rate > .product-stars {
    position: absolute;
    display: inline-block;
    margin-left: 15px;
}

.add-review .product-rate > .product-stars input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
}

.add-review .product-rate > .product-stars .fa {
    color: #4E1E50;
    font-size: 30px;
    margin-right: 7px;
    font-weight: normal;
    float: right;
    margin-bottom: 0;
    cursor: pointer;
}


.star-rating:hover:before,
.star-rating:hover ~ .star-rating:before,
.star-rating-input:checked ~ .star-rating:before {
    content: "\f005";
}

.add-review .form-hint {
    color: #b9b9b9;
    font-size: 13px;
    margin-bottom: 10px;
}

.add-review .form-group {
    margin-bottom: 30px;
}

.add-review label, .add-review legend {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #222;
}

.add-review button {
    margin: 60px auto 0;
    display: table;
}

.add-review .review-img {
    margin: 30px auto 0;
    display: block;
    padding-left: 30px;
}

.add-review .lab-no {
    float: left;
}

.add-review .lab-yes {
    float: right;
}

.add-review .eval {
    margin: 0 0 5px 0;
    display: table;
    width: 100%;
}

.add-review .eval li {
    float: left;
    width: 10%;
}

.add-review .eval li label {
    text-align: center;
    padding: 10px 0;
    background-color: #F2F2F2;
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    border-left: 1px solid #C9C9C9;
    cursor: pointer;
    display: block;
    font-size: 14px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-review .eval li label:hover {
    opacity: .7;
    border-color: #9d7a45;
}

.add-review .eval li input {
    display: none;
}

.add-review .eval li input:checked + label {
    background-color: #d7be7e;
    border-top-border-color: #d7be7e;
    border-bottom-border-color: #d7be7e;
    color: #fff;
}

.add-review .eval li input:checked + label:hover {
    opacity: 1;
}

.add-review .eval li:last-of-type label {
    border-right: 1px solid #C9C9C9;
}


.add-review .rec-fr {
    display: table;
    width: 100%;
}

.add-review .rec-fr li {
    float: left;
}

.add-review .rec-fr li label {
    text-align: center;
    padding: 10px 25px;
    background-color: #F2F2F2;
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    border-left: 1px solid #C9C9C9;
    cursor: pointer;
    display: block;
    font-size: 14px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-review .rec-fr li label:hover {
    opacity: .7;
    border-color: #d7be7e;
}

.add-review .rec-fr li input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
}

.add-review .rec-fr li input:checked + label {
    background-color: #d7be7e;
    border-top-border-color: #d7be7e;
    border-bottom-border-color: #d7be7e;
    color: #fff;
}

.add-review .rec-fr li input:checked + label:hover {
    opacity: 1;
}

.add-review .rec-fr li:last-of-type label {
    border-right: 1px solid #C9C9C9;
}


.modal-content {
    border: 0;
    border-radius: 0;
}

.modal-header {
    border-radius: 0;
}

.add-review .modal-header {
    background-color: #d7be7e;
}

.add-review .modal-header h5 {
    color: #fff;
    text-align: center;
    display: block;
    width: 100%;
}


.comments {
    background-color: #FBFAF9;
}

.comments .rating .lab {
    margin: 16px 0 25px;
}

.comments .rating .rate {
    font-size: 36px;
    font-weight: bold;
    border-color: #d7be7e;
}

.comments .rating .count {
    font-size: 12px;
    color: #bbb;
}

.comments .rate-list {
    margin: 0 0 50px 0;
}

.comments .rate-list .btn {
    margin: 0 0 29px auto;
    display: table;
}

.comments .rate-list ul li {
    margin-bottom: 10px;
}

.comments .rate-list ul li i {
    border-color: #d7be7e;
    font-size: 15px;
}

.comments .rate-list ul li .val {
    font-family: 'FedraSansPro', sans-serif;
    display: inline-block;
}

.comments .rate-list ul li .rate-progress-bar {
    display: inline-block;
    background-color: #3c4046;
    height: 5px;
    position: absolute;
}

.comments .rate-list ul li .rate-progress {
    position: relative;
    background-color: #CAC7C6;
    width: 100%;
    height: 5px;
}

.comments .comment-row {
    padding: 35px 0;
    border-bottom: 1px solid #e3e2e2;
}

.comments .comment-row .wine-recommended {
    margin: 20px 0 0 0;
}

.comments .comment-row .wine-recommended.yes {
    color: #179767;
}

.comments .comment-row .wine-recommended.no {
    color: #B70F14;
}

.comments .comment-row .wine-recommended i {
    margin-right: 2px;
}

.comments .comment-row .reviews-count {
    color: #c5c5c5;
    font-size: 11px;
}

.comments .comment-row .comment-author {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: #414141;
}

.comments .comment-row .comment-heading {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    font-weight: bold;
    color: #414141;
}

.comments .comment-row .comment-rate {
    margin: 0 0 25px 0;
}

.comments .comment-row .comment-rate ul {
    display: inline-block;
}

.comments .comment-row .comment-rate ul li {
    display: inline-block;
}

.comments .comment-row .comment-rate ul li i {
    color: #4E1E50;
}

.comments .comment-row .comment-rate span {
    color: #c5c5c5;
    margin-left: 15px;
    font-size: 12px;
}

.comments .more {
    color: #6A6665;
    padding: 35px 0 0;
    margin: 0 auto;
    display: table;
    text-decoration: underline;
}

.comments .more:hover {
    color: #9d7a45;
}

.comments .empty-comments {
    text-align: center;
}

.comments .empty-comments img {
    height: 60px;
    margin: 0 0 15px 0;
}

.comments .empty-comments h3 {
    margin: 0 0 3px 0;
}

.comments .comment-btn {
    text-align: center;
    margin: 30px 0 0 0;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.product-quantity input[type=number] {
    width: 100%;
    text-align: center;
    border: 0;
    height: 42px;
    font-size: 16px;
    line-height: 42px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.product .product-quantity input[type=number] {
    height: 50px;
    font-size: 23px;
    line-height: 42px;
}

.product-quantity button {
    background-color: rgb(70 23 84);
    background-color: #f5f3ef;
    border: 0;
    font-weight: bold;
    /*color: #fff;*/
    position: absolute;
    height: 42px;
    width: 28px;
    top: 0;
    font-size: 19px;
    outline: none;
}

.product .product-quantity button {

    height: 50px;
    width: 50px;
}

.product-quantity .minus {
    left: 0;
}

.product-quantity .plus {
    right: 0;
}


/*product list*/
.products-list .product-item {
    text-align: center;
    position: relative;
    margin-bottom: 50px;
}

.products-list .owl-item .product-item {
    margin-bottom: 0;
}

.products-list .product-item .product-prices {
    margin: 10px 0 15px;
}

.products-list .product-item .product-name {
    color: #262020;
    font-size: 24px;
    line-height: 30px;
    min-height: 66px;
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
    font-family: 'Moderato G7 L';
}

.products-list .product-item .product-name:after {
    /*content: "";*/
    width: 30px;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -15px;
}

.products-list .product-item .product-desc {
    color: #777272;
    display: flex;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    min-height: 63px;
    font-family: 'Sofia Sans', sans-serif;
}

.products-list .product-item .product-prices .old-price {
    color: #bdbdbd;
    text-decoration: line-through;
}

.products-list .product-item .product-prices .price,
.products-list .product-item .product-prices .old-price {
    display: inline-block;
    margin: 0 5px;
    font-size: 25px;
    line-height: 34px;
}

.products-list .product-item .product-prices .price {
    color: #262020;
    font-family: 'Moderato G7 L';
}

.products-list .product-item {
    overflow: hidden;
    position: relative;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-img {
    overflow: hidden;
    position: relative;
    margin: 0 0 20px 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

/*.products-list .product-item .product-img:after {
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
    top: 130px;
    left: 60%;
    margin-left: -40px;
    border-radius: 100%;
    background-color: rgba(255,192,194,0.7);
    display: block;
    z-index: 3;
    opacity: 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item:hover .product-img:after {
    top: 45px;
    margin-left: -60px;
    opacity: 1;
}

.products-list .product-item:hover .product-img {
    transform: scale(1.2);
}*/

.products-list .product-item .product-info {
    position: relative;
    background-color: #fff;
    padding: 10px 0 0 0;
    z-index: 3;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item:hover .product-info {
    /*margin: -80px 0 0 0;*/


    /*-moz-transform: translate(0, -80px);*/
    /*-ms-transform: translate(0, -80px);*/
    /*-o-transform: translate(0, -80px);*/
    /*-webkit-transform: translate(0, -80px);*/
    /*transform: translate(0, -80px);*/
}

.products-list .product-item:hover .product-img {
    /*transform: scale(0.75);*/
    transform: scale(1.2);
}

.upsell {

}

.upsell .upsell-product {
    background-color: #F7F5F2;
    padding: 15px 10px;
    margin: 0 0 30px 0;
}

.upsell .upsell-product .product-prices .price {
    font-weight: bold;
}

.upsell .upsell-product .product-name {
    color: #262020;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Moderato G5 L';
    display: inline-block;
}

.upsell .upsell-product .btn {
    font-size: 13px;
    padding: 0 20px;
    height: 34px;
    line-height: 34px;
    letter-spacing: 1px;
}

.upsell .products-list .product-item .product-action {
    opacity: 1;
}

.products-list .product-item .product-action {
    opacity: 0;
}

.products-list .product-item:hover .product-action {
    opacity: 1;
}

.products-list .product-item .product-action .btn {
    width: 100%;
    display: block;
    margin: 0 0 10px 0;
    padding: 0 10px;
    font-size: 15px;
    letter-spacing: 0;
    border-radius: 50px;
}

.products-list .product-item .product-action .btn:last-of-type {
    margin: 0;
}

.products-list.product-recently .signature img {
    height: 20px;
}

.products-list .product-item:hover .add-fav {
    opacity: 1;
}

.add-fav {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    opacity: 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-fav img {
    height: 15px;
}

.product-labels {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}

.product-labels .product-label {
    width: 50px;
    height: 50px;
    line-height: 17px;
    color: #fff;
    background-color: #000;
    border-radius: 100%;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


.product-labels .product-label.label-new {
    background-color: #d7be7e;
}

.product-labels .product-label.label-discount {
    background-color: #e81e25;
}

.product-labels .product-label.label-discount.discount-fixed {

    width: auto;
    max-width: 100px;
    padding: 2px 5px;
    border-radius: 4px;
    height: auto;
    line-height: inherit;
}

.product-labels .product-label.label-score {
    border: 2px solid #ddd;
    background-color: #fff;
    color: #333;
    font-size: 13px;
    line-height: 36px;
}

.product-labels li {
    margin-bottom: 5px;
}

.signature {
    margin: 10px 0 0 0;
}

.signature img {
    height: 28px;
    width: inherit !important;
}


/*product recently*/
.similar-products {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 50px;
    padding-bottom: 50px;
}


/*product recently*/
.product-recently {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 100px;
    padding-bottom: 25px;
}


/*new offers*/
.new-offers {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 100px;
    padding-bottom: 25px;
}

.new-offers.btn {
    padding: 0 10px;
}


/*special offers*/
.special-offers {
    background-color: #FBFAF9;
    padding-bottom: 0;
}

.special-offers.products-list .product-item .product-info {
    background-color: #FBFAF9;
}


/*assortment*/
.assortment .assortment-item .assortment-img img {
    width: 100%;
}

.assortment .assortment-item {
    text-align: center;
    min-height: 100%;
    background-color: #fbfaf9;
    position: relative;
}


.assortment .assortment-item .assortment-info {
    background-color: #fbfaf9;
    padding: 20px 30px 70px;
}


.assortment .assortment-item .assortment-info p {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.assortment .assortment-item .assortment-info input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.assortment .assortment-item .assortment-info input:focus ~ label {
    outline: -webkit-focus-ring-color auto 5px;
}

.assortment .assortment-item .assortment-info input ~ label {
    margin-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin-top: -20px;
}

.assortment .assortment-item .assortment-info input:checked + p {
    -webkit-line-clamp: unset;
}

.assortment .assortment-item .assortment-info input:checked ~ label {
    display: none;
}

.assortment .assortment-item h3 {
    position: relative;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    margin: 0 0 30px 0;
    border-color: #d7be7e;
    min-height: 52px;
}

.assortment .assortment-item h3:after {
    content: "";
    width: 30px;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -15px;
}


/*offers*/
.offers {
    padding: 0 0 40px 0;
}

.offers .main-offer {
    margin: 0 0 30px 0;
    position: relative;
    overflow: hidden;
}

.offers .main-offer:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%));
    pointer-events: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.offers .main-offer:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 70%));
}

.offers .main-offer-text {
    position: absolute;
    left: 0;
    bottom: 18%;
    background-color: rgba(105, 29, 94, .8);
    height: auto;
    padding: 20px 0;
    width: 100%;
    padding-left: 15%;
    z-index: 3;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.offers .main-offer-text h3 {
    font-family: 'FedraSansPro', sans-serif;
    color: #fff;
    font-size: 28px;
    letter-spacing: 13px;
    text-transform: uppercase;
}

.offers .main-offer,
.offers .small-offer {
    position: relative;
}

.offers .small-offer:hover img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.offers .small-offer img {
    -webkit-transition: all .4s;
    transition: all .4s;
}

.offers .small-offer {
    overflow: hidden;
}

.offers .small-offer .lab {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 55px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    color: #fff;
    text-transform: uppercase;
    font-family: 'Sofia Sans', sans-serif;
}

.offers .small-offer:hover .lab:after {
    left: 107%;
    opacity: 1;
}

.offers .small-offer .lab:after {
    content: "";
    background: url("../../images/icons/lp-arrow.svg") center center no-repeat;
    background-size: 24px;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    opacity: 0;

    -webkit-transition: all .4s;
    transition: all .4s;

}

/*newsletter*/
.newsletter {
    background: url('../../images/newsletter-bgr.jpg') top center no-repeat;
    background-size: cover;
    position: relative;
}

.form-check {
    position: initial;
    display: initial;
    padding: initial;
}

.newsletter:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.newsletter .container {
    position: relative;
    z-index: 3;
}

.newsletter .heading {
    color: #fff;
    margin-bottom: 20px;
}

.newsletter .form-control {
    margin: 0 0 10px 0;
}

.newsletter label {
    color: #fff;
}

.newsletter .btn {
    height: 38px;
    line-height: 36px;
}

.newsletter .hint {
    text-align: center;
    color: #fff;
    margin: 0 0 30px 0;
    padding: 0 250px;
    opacity: .8;
    font-size: 15px;
}


/* EVENT */
.event .event-content {
    background-color: #fff;
    margin-top: -250px;
    box-shadow: 0 1px 8px 2px rgba(82, 88, 93, 15%);
}

.event .event-short {
    background-color: #FBFAF9;
    height: 100%;
    border-right: 1px solid #bbb;
}

.event .event-desc {
    word-break: break-word;
    padding: 40px 20px 50px 20px;

}

.event .event-desc .event-meta p {
    margin: 0;
}

.event .event-desc .event-meta .event-price small {
    font-size: 15px;
    line-height: 22px;
}

.event .event-desc .event-meta .event-price {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    color: #222;
    font-family: 'FSerProB', sans-serif;
}

.event .event-desc .event-meta {
    padding: 15px;
    background-color: #FBFAF9;
    margin: 0 0 20px 0;
}

.event .event-desc .event-meta span {
    font-size: 11px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: #222;
    display: block;
    margin: 0 0 5px 0;
}

.event .event-desc ul li {
    margin: 0 0 10px 0;
}

.event .event-desc h3 {
    border-color: #d7be7e;
    margin: 0 0 10px 0;
}

.event .event-desc h1 {
    font-size: 36px;
    line-height: 50px;
    margin: 0 0 30px 0;
}

.event .event-info {
    padding: 5px 30px;
    font-size: 15px;
    line-height: 22px;
    font-weight: bold;
}

.event .event-date {
    padding: 30px 30px 25px 30px;
    margin: 0 0 20px 0;
    position: relative;
    color: #333;
    border-bottom: 1px solid #bbb;
}

.event .event-date span {
    font-size: 64px;
    line-height: 70px;
    font-weight: bold;
    display: inline-block;
    margin-right: 15px;
}

.event .event-date small {
    display: inline-block;
}

.event .event-action-container {
    border-left: 1px solid #bbb;
    height: 100%;
}

.event .event-action {
    margin: 0;
    padding: 40px 20px 30px 20px;
}

.event .event-action {
    text-align: center;
}

.event .event-action .btn {
    display: block;
    width: 100%;
    margin: 0 0 15px 0;
}

.event .event-action .from-us.our-team {
    padding: 0;
}

.event .event-action .from-us.our-team .form-cont {
    display: table;
    margin: 0 auto;
}

.event .event-action .from-us.our-team .form-container {
    width: 100%;
}


/*events*/
.events {
    background-color: #FBFAF9;
    background-image: url('../../images/events-bgr.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
}

.events .event-block:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 90%));

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.events .event-block:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 100%));
}

.events .event-block .event-info {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 25px 30px 0;
}

.events .event-block .event-info h3 {
    position: relative;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #fff;
}

.events .event-block .event-info .event-meta .date {
    display: inline-block;
    color: #fff;
}

.events .event-block .event-info .event-meta .slots.slots-end {
    color: #E81E25;
    text-transform: uppercase;
}

.events .event-block .event-info .event-meta .slots {
    display: inline-block;
    margin-left: 10px;
    color: #fff;
}


.events .event-block:hover .event-info .btn {
    max-height: 46px;
    border-width: 1px;
    margin-bottom: 30px;
    opacity: 1;
}

.events .event-block .event-info .btn {
    max-height: 0;
    overflow: hidden;
    position: relative;
    border-width: 0;
    opacity: 0;
    margin-top: 10px;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.events .event-block {
    position: relative;
}

.events .event-space {
    margin-bottom: 30px;
}

.events .view-all {
    margin: 50px auto 0;
    display: table;
}


/* blog view */
.blog-view h1 {
    font-size: 68px;
    line-height: 78px;
    text-align: center;
    margin: 0 0 10px 0;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
}

.blog-view .blog-main-img {
    float: left;
    margin: 0 20px 20px 0;
}

.blog-view .meta {
    text-align: center;
    margin: 0 0 30px 0;
}

.blog-view .meta .date {
    color: #5D5959;
    font-family: 'Moderato G5 L';
    text-transform: uppercase;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 5px 0;
}

.blog-view .meta .author {
    font-size: 15px;
    line-height: 22px;
    color: #000;
}

.blog-view .blog-content figure.image_resized {
    margin: auto;
    margin-bottom: 10px;
}

.blog-view .blog-content figure.image-style-align-left {
    float: left;
    margin-right: 10px;
}

.blog-view .blog-content figure.image-style-align-right {
    float: right;
    margin-left: 10px;
}

.blog-view .blog-content i {
    font-style: italic;
}

.blog-view .blog-content ul {
    list-style: disc;
    padding: 20px;
}

.blog-view .blog-content ol {
    list-style: decimal;
    padding: 20px;
}

.blog-similar {
    background-color: #F5F3EF;
}

.blog-similar.blog-list .blog-item {
    margin: 0;
    border: 0;
}

.blog-similar.blog-list .blog-item h3 {
    min-height: 72px;
    text-align: center;
}

.blog-similar.blog-list .meta-post {
    text-align: center;
}

.owl-events .owl-item:hover .blog-item .meta-post ul li img,
.owl-projects .owl-item:hover .blog-item .meta-post ul li img,
.owl-special-products .owl-item:hover .blog-item .meta-post ul li img {
    transform: none !important;
}


/*blog*/
.home .blog-list .blog-item {
    margin: 0 0 0 0;
}

.blog-list .blog-item {
    margin: 0 0 30px 0;
    border: 1px solid #9D7A45;
}

.blog-list .blog-item-img {
    display: block;
    position: relative;
    overflow: hidden;
}

.blog-list .blog-item-img picture {
    display: block;
}

.blog-list .blog-item h3 {
    min-height: 90px;
}

.blog-list .blog-item h3 a {
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    color: #000000;
    display: inline-block;
}

.blog-list .blog-item .blog-post-content {
    padding: 20px 20px;
}

.blog-list .blog-item .meta-post {
    margin: 12px 0 0 0;
}

.blog-list .blog-item .meta-post ul li {
    display: block;
    margin: 2px 0;
}

.blog-list .blog-item .meta-post ul li img {
    display: inline-block;
    margin-right: 5px;
    max-height: 20px;
    max-width: 20px;
}

.more-posts {
    margin: 30px auto 0;
    display: table;
}

.src-wine {
    color: #5D5959;
    font-family: 'Moderato G5 L';
    font-size: 20px;
    line-height: 26px;
}

.blog-list .blog-item h3 a:hover {
    color: #9d7a45;
}

.blog-list .view-all {
    margin: 50px auto 0;
    display: table;
}

.blog-list .hero-area {
    padding: 110px 0;
}

.blog-list .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}

.blog-list .blog-filter {
    margin: 0 0 30px 0;
}

.blog-list .blog-filter li {
    display: inline-block;
    margin: 0 5px 0 0;
}

.blog-list .blog-filter li a {
    padding: 9px 15px 6px;
    border: 1px solid #B9B9B9;
    margin: 0 0 20px 0;
    text-align: center;
    color: #1C1B1B;
    cursor: pointer;
    display: block;
    text-transform: uppercase;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.blog-list .blog-filter li.active a:hover,
.blog-list .blog-filter li.active a {
    background-color: #093A52;
    color: #fff;
    border-color: #093A52;
}

.blog-list .blog-filter li a:hover {
    background-color: transparent;
    color: #d7be7e;
    border-color: #d7be7e;
}

.blog-list .blog-filter li:first-of-type {
    margin-left: 0;
}

.blog-list .blog-filter li:last-of-type {
    margin-right: 0;
}


.event .page-info {
    margin-bottom: 0;
}


.events-list {
    padding-bottom: 100px;
}

.events-list .heading {
    text-align: left;
    margin-bottom: 25px;
}

.events-list .cstm-pagination {
    margin-top: 50px;
}

.event-row {
    position: relative;
    margin: 0 0 30px 0;
    overflow: hidden;
    display: block;
}

/*.event-row:last-of-type {
    margin: 0 0 0 0;
}*/

.event-row:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 90%));
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.event-row:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 100%));
}

.event-row .event-label {
    background-color: #E81E25;
    color: #fff;
    width: 90px;
    height: 90px;
    text-align: center;
    line-height: 88px;
    border-radius: 100%;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 3;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
}

.event-row .event-info {
    position: absolute;
    bottom: 0;
    padding: 15px 10px;
}

.event-row .event-info .event-date {
    padding: 0 20px;
    margin: 20px 30px 20px 0;
    color: #fff;
    position: relative;
}

.event-row .event-info .event-capacity {
    color: #fff;
    text-align: center;
}

.event-row .event-info .event-desc:before {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    left: -32px;
    background-color: #ffffff7a;
    width: 1px;
}

.event-row .event-info .event-date span {
    font-size: 64px;
    line-height: 40px;
    font-weight: bold;
    color: #fff;
    display: inline-block;
    margin-right: 15px;
}

.event-row .event-info .event-date small {
    display: inline-block;
}

.event-row .event-info .event-desc {
    padding: 0 20px;
    margin: 20px 0;
    position: relative;
}

.event-row .event-info .event-desc h3 {
    color: #fff;
    margin: 0 0 10px 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
}

.event-row .event-info .event-desc p {
    color: #fff;
    /*word-break: break-all;*/
}

.events-list .hero-area {
    padding: 110px 0;
}

.events-list .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}

.events-filter h4 {
    text-transform: uppercase;
    font-family: 'FedraSansPro', sans-serif;
    margin: 0 0 25px 0;
}

.event-checkout .total-action {
    border-top: 0;
}

.total-action .checkbox label {
    line-height: 22px;
}

.event-checkout .cart-delivery-hint img {
    height: 18px;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.event-checkout .cart-delivery-hint {
    margin-top: 0;
    background-color: #f1e5f1;
}

.event-checkout .sum {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #e3e2e2;
}


.mystery-box .hero {
    position: relative;
}

.mystery-box .hero-text {
    /*background-color: rgba(105, 29, 94, .8);*/
    padding: 20px 15px;
    text-align: center;
    width: 100%;
    z-index: 3;
    display: flex;
    height: 765px;
    justify-content: center;
    align-items: center;
    position: static;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.mystery-box .hero-text h1 {
    color: #9d7a45;
    font-weight: bold;
    font-size: 85px;
    text-align: center;
    line-height: 105px;
    margin-bottom: 10px;
}

.mystery-box .hero-text h2 {
    font-family: 'FedraSansPro', sans-serif;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    letter-spacing: 4px;
    line-height: 36px;
}

.mystery-box .hero-text a.btn {
    display: table;
    margin: 25px auto 0 auto;
    color: #fff;
    font-size: 15px;
    background: #502253;
    height: 54px;
    padding-top: 4px;
}

.mystery-box .hero-text a.btn:hover {
    background: #75328A;
}

.mystery-box .hero h3 {
    font-family: 'FedraSansPro', sans-serif;
    color: #fff;
    font-size: 28px;
    letter-spacing: 13px;
    text-transform: uppercase;
}

.mystery-box .video .heading {
    text-align: left;
    text-transform: inherit;
    margin-bottom: 30px;
    color: #4e1e50;
}

.mystery-box .video p {
    margin-bottom: 30px;
}

.mystery-box .video iframe {
    width: 100%;
    height: 450px
}

.back {
    margin: 15px 0 0 0;
    display: inline-block;
}

.back i {
    margin-right: 5px;
}

.register h1,
.login h1 {
    font-size: 50px;
    line-height: 58px;
    font-family: 'Moderato G7 L';
    margin: 0 0 30px 0;
    text-align: center;
}

.login .space, .register .space {
    padding: 50px 0;
}

.social-buttons .btn {
    width: 100%;
    margin: 5px 0;
    position: relative;
}

.social-buttons .btn .fa {
    margin-right: 10px;
    font-size: 12px;
}

.social-buttons .btn.facebook {
    background: #415a94;
    color: #fff;
}

.social-buttons .btn.facebook:hover {
    background: #314471;
    color: #fff;
}

.social-buttons .btn.google {
    background: #cf553d;
    color: #fff;
}

.social-buttons .btn.google:hover {
    background: #ae412b;
}

.social-buttons .btn.apple {
    background: #000;
    color: #fff;
}

.social-buttons .btn.apple:hover {
    background: #333333;
}

.order-auth-login .forgot-password-link,
.login .forgot-password-link {
    float: right;
    text-decoration: underline;
    color: #969595;

    transition: all .4s;
}

.order-auth-login .forgot-password-link:hover,
.login .forgot-password-link:hover {
    color: #9d7a45;
    text-decoration: none;
}

.order-auth-login .icon-mail,
.login .icon-mail {
    top: 15px;
}

.order-auth-login .icon-password,
.login .icon-password {
    top: 11px;
}

.order-auth-login button,
.login button {
    display: block;
    width: 100%;
}

.login .new-reg {
    text-align: center;
    margin: 40px 0 0 0;
}

.login .new-reg span {
    display: block;
}

.login .new-reg a {
    display: inline-block;
    text-decoration: underline;
    color: #969595;
    margin: 2px 0 0 0;
}

.login .new-reg a:hover {
    text-decoration: none;
    color: #9d7a45;
}

.order-auth .heading {
    margin: 0 0 30px 0;
}

.order-auth .heading-icon {
    height: 30px;
    vertical-align: baseline;
    margin: 0 10px 0 0;
}

.order-auth-login {
    padding: 60px 30px;
}

.order-auth-reg {
    padding: 60px 50px;
    background-color: #F5F3EF;
}

.order-auth-reg .or {
    text-align: center;
    margin: 11px 0;
}

.order-auth h1 {
    font-size: 50px;
    line-height: 58px;
    font-family: 'Moderato G7 L';
    text-align: center;
    margin: 0 0 50px 0;
}

.order-auth .heading {
    text-align: left;
}

.order-auth-reg p {
    text-align: left;
    margin-bottom: 15px;
}

.order-auth-reg .btn {
    display: block;
}

.page-info {
    padding: 10px 0;
}

.page-info ol li {
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    margin: 0 10px;
    position: relative;
    color: #000000;
    font-family: 'Moderato G5 L';
}

.page-info ol li:first-of-type {
    margin-left: 0;
}

.page-info ol li a {
    text-decoration: none;
    font-size: 15px;
    line-height: 20px;
    color: #5D5959;
    ffont-family: 'Sofia Sans', sans-serif;
}

.page-info ol li::after {
    content: "›";
    position: absolute;
    top: 0;
    right: -12px;
}

.page-info ol li:last-of-type:after {
    display: none;
}

.sidebar .active-filters h4 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sidebar h3 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sidebar.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .active-filters {
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

.sidebar .active-filters ul li {
    margin: 0 0 5px 0;
}

.sidebar .active-filters .filter-type {
    font-size: 12px;
    display: inline-block;
}

.sidebar .active-filters .filter-value {
    padding: 3px 5px;
    border: 1px solid #e3e2e2;
    text-align: center;
    border-color: #d7be7e;
    transition: all .4s;
    display: inline-block;
    font-size: 12px;
    margin: 3px 0 0 5px;
}

.sidebar .active-filters .filter-value span {
    position: relative;
    top: -1px;
    cursor: pointer;
}

.sidebar .active-filters .filter-value span img {
    height: 10px;
    margin-left: 5px;
}

.sidebar .active-filters .filter-value span .icn {
    display: inline-block;
}

.sidebar .active-filters .filter-value span:hover .icn {
    display: none;
}

.sidebar .active-filters .filter-value span .icn-hover {
    display: none;
}

.sidebar .active-filters .filter-value span:hover .icn-hover {
    display: inline-block;
}

.sidebar .active-filters .filter-value:hover {
    border-color: #d7be7e;
}

.sidebar.active .widgets {
    display: block;
}

.sidebar .widget:first-of-type {
    padding-top: 0;
}

.sidebar .widget {
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
}

.sidebar .widget .widget-box {
    margin: 10px 0 0 0;
    display: none;
}

.sidebar .widget.widget-active .widget-box {
    display: block;
}

.sidebar .widget h4 {
    font-size: 14px;
    line-height: 20px;
    padding-right: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #222;
    font-family: 'FedraSansPro', sans-serif;
    position: relative;
    cursor: pointer;
}

.sidebar .widget h5 {
    font-size: 14px;
    line-height: 20px;
    padding: 3px 0;
    color: #222;
    font-family: 'FedraSansPro', sans-serif;
    position: relative;
    cursor: pointer;
}

.sidebar .widget .category-icon {
    font-size: 16px;
    margin-right: 5px;
}

.sidebar .widget.widget-active h4:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget h4:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget .aside-dropdown > li {
    position: relative;
    padding: 0 0 0 15px;
}

.sidebar .widget .aside-dropdown > li.dd-menu > a {

}

.sidebar .widget .aside-dropdown > li.dd-menu ul {
    display: none;
}

.sidebar .widget .aside-dropdown > li.dd-menu:after {
    content: "\f105";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget .aside-dropdown > li.dd-simple-menu:after {
    content: "\f105";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget .aside-dropdown > li.dd-menu-active ul {
    display: block;
}

.sidebar .widget .aside-dropdown > li.dd-menu-active:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
}

.sidebar .widget .aside-dropdown > li:last-of-type {
    border: 0;
}

.sidebar .widget .aside-dropdown > li > a {
    padding: 3px 0;
    display: block;
    font-size: 13px;
    line-height: 20px;
}

.sidebar .widget .aside-dropdown > li > a:hover {
    color: #9d7a45;
}

.sidebar .widget .aside-dropdown > li > ul {
    padding: 0 0 15px 0;
}

.sidebar .widget .aside-dropdown > li > ul > li > a {
    padding: 4px 15px 4px 10px;
    display: block;
    line-height: 18px;
    color: #afafaf;
}

.sidebar .widget .aside-dropdown > li > ul > li > a:hover {
    color: #9d7a45;
}


.sidebar .widget .sort-search {
    position: relative;
    margin: 0 0 20px 0;
}

.sidebar .widget .sort-search img {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 18px;
}

.sidebar .widget .sort-search .form-control {
    font-size: 12px;
    padding-left: 35px;
}


.sidebar .widget .filter-list {
    max-height: 200px;
    overflow-x: auto;
    position: relative;
}

.sidebar .widget .filter-list .filter-item {
    padding: 0 0 0 28px;
    position: relative;
    margin: 10px 0;
}

.sidebar .widget .filter-list .filter-item a {
    color: #afafaf;
    -webkit-appearance: none;
}

.sidebar .widget .filter-list .filter-item a:hover {
    color: #9d7a45;
}

.sidebar .widget .filter-list .filter-item.selected a {
    color: #9d7a45;
}

.sidebar .widget .filter-list .filter-item.selected:after {
    content: "";
    background: url(../../images/icons/check.svg) center center no-repeat;
    background-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 20px;
}

.sidebar .widget .filter-list .filter-item:before {
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid #e3e2e2;
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
}

.sidebar .widget .filter-list .filter-item.selected:before {
    border-color: #d7be7e;
}


aside .clear-all {
    margin: 20px 0;
}

aside .clear-all a {
    color: #afafaf;
    font-size: 12px;
    text-decoration: underline;
}

aside .clear-all a:hover {
    color: #9d7a45;
}


/*footer*/
footer {
    background-color: #093a52;
    padding: 85px 0 50px;
    position: relative;
    z-index: 3;
}

footer h3 {
    color: #9D7A45;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

footer ul li {
    margin-bottom: 8px;
    display: block;
}

footer ul li a {
    color: #fff;
    font-size: 13px;
    line-height: 18px;
}

footer ul li a:hover {
    text-decoration: underline;
    color: #9D7A45;
}

footer .footer-contacts.visit-us a {
    color: #b3b3b3;
}

footer .footer-contacts.visit-us a:hover {
    text-decoration: underline;
    color: #cecece;
}

footer .footer-contacts.visit-us span {
    font-size: 16px;
    display: block;
}

footer .footer-contacts.visit-us span img {
    position: absolute;
    left: 0;
}

footer .footer-contacts li {
    color: #b3b3b3;
    margin-bottom: 30px;
    padding-left: 28px;
    position: relative;
}

footer .footer-contacts li a {
    color: #9d7a45;
    display: block;
    font-size: 16px;
    line-height: 24px;
}

footer .footer-logo {
    height: 150px;
}


/*form components*/
.radio,
.checkbox {
    padding: 0 0 0 26px;
    position: relative;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    position: absolute;
}

.checkbox label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    border: 1px solid #cccccc;
    background-color: #fff;
}

.checkbox label::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 3px;
    top: 0;
    font-size: 12px;
    color: #555555;
}

.checkbox input[type="checkbox"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";
}

.radio label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;

    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;

    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio label::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    background-color: #555555;

    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);

    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}


/*owl*/
.owl-carousel .owl-nav {
    width: 100%;
}

.owl-carousel .owl-nav button .fa {
    font-size: 55px;
    line-height: 60px;
    height: 63px;
    display: block;
}

.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 50%;
    left: -60px;
    margin-top: -32px;
}

.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 50%;
    right: -60px;
    margin-top: -32px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: none;
    color: #869791;
}

.owl-theme .owl-nav [class*=owl-] {
    outline: none;
}

.carousel-indicators li {
    border: 3px solid #d5d1cf;
    background-color: #d5d1cf;
    border-radius: 100%;
    height: 7px;
    width: 7px;
    margin: 0 6px;
}

.carousel-indicators li.active {
    border: 2px solid #d7be7e;
    background-color: transparent;
}

.user-widget .user-nav,
.sub-lang {
    z-index: 125;
}

.fix-phone span {
    width: 0;
    font-size: 0;
}

.fix-phone i {
    display: block;
    font-size: 28px;
    line-height: 60px;
}

.fix-phone {
    width: 60px;
    height: 60px;
    display: block;
    background-color: #d7be7e;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 105;
}

.fix-phone:hover,
.fix-phone:active,
.fix-phone:focus {
    color: #fff;
}


.switch label {
    cursor: pointer;
}

.switch label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch label input[type="checkbox"]:not(:checked),
.switch label input[type="checkbox"]:checked {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}

.switch label .lever {
    position: relative;
    display: inline-block;
    margin: 0 1rem;
    margin-right: .625rem;
    vertical-align: middle;
    content: "";
    background-color: #E2E1E1;
    border-radius: .9375rem;
    width: 2.5rem;
    height: .9375rem;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
}

.switch label .lever:after {
    position: absolute;
    top: -.1875rem;
    left: -.3125rem;
    display: inline-block;
    content: "";
    background-color: #8C8B8B;
    border-radius: 1.3125rem;
    width: 1.3125rem;
    height: 1.3125rem;
    -webkit-transition: left .3s ease, background .3s ease, -webkit-box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, -webkit-box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, box-shadow 1s ease, -webkit-box-shadow 1s ease;
}

.switch label input[type="checkbox"]:checked + .lever {
    background-color: #d8c9ac;
}

.switch label input[type="checkbox"]:checked + .lever:after {
    left: 1.5rem;
    background-color: #b89d65;
}


.cstm-pagination {
    margin: 0 auto;
    display: table;
}

.cstm-pagination ul {
    font-size: 0;
}

.cstm-pagination ul li {
    display: inline-block;
    font-size: 12px;
}

.cstm-pagination ul li .page-numbers {
    font-size: 17px;
    line-height: 36px;
    display: block;
    width: 38px;
    height: 38px;
    text-align: center;
    margin: 0 6px;
    border: 1px solid #E3E3E3;
    border-radius: 100%;
}

.cstm-pagination ul li .page-numbers:hover {
    border: 1px solid #d7be7e;
    border-color: #d7be7e;
}

.cstm-pagination ul li.active .page-numbers {
    border: 1px solid #262020;
    background-color: #262020;
    color: #fff;
}

.page-content .select2-container--bootstrap4 .select2-selection {
    border-radius: 0;
}


.ps__thumb-y {
    border-radius: 0;
}

.ps .ps__rail-y,
.ps .ps__rail-y {
    background-color: #eee;
    opacity: 0.6;
    width: 8px;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
    width: 15px;
}


.owl-stage {
    padding-left: 0 !important;
}


/* ============================================= */
.thankyou h1 {
    font-size: 50px;
    line-height: 58px;
    font-family: 'Moderato G7 L';
    margin: 0 0 30px 0;
    text-align: center;
}

.thankyou .thankyou-cups {
    margin: 0 auto 30px;
    display: block;
}

.thankyou .total {
    margin-top: 50px;
}


.register h1,
.login h1 {
    border-color: #d7be7e;
}

.register .icon-name {
    top: 11px;
}

.register .icon-mail {
    top: 15px;
}

.register .icon-password {
    top: 10px;
}

.reset-password .icon-password {
    top: 10px;
}

.reset-password h1 {
    border-color: #d7be7e;
}

.reset-password h3 {
    font-family: 'FedraSansPro', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.reset-password {
    background-image: url(../../images/login-sharps.svg);
    background-repeat: repeat-y;
    background-position: bottom left;
    background-size: 150px;
}

.reset-password .back {
    margin: 30px auto 0;
    display: table;
}

.reset-password button {
    display: block;
    width: 100%;
}

.register button {
    display: block;
    width: 100%;
}

.register .exist-reg {
    text-align: center;
    margin: 40px 0 0 0;
}

.register .exist-reg span {
    display: block;
}

.register .exist-reg a {
    display: inline-block;
    text-decoration: underline;
    color: #969595;
    margin: 2px 0 0 0;
}

.register .exist-reg a:hover {
    text-decoration: none;
    color: #9d7a45;
}


.faq h1 {
    border-color: #d7be7e;
}

.faq .faq-category {
    margin: 0 0 30px 0;
}

.faq .faq-category:last-of-type {
    margin: 0 0 0 0;
}

.faq .faq-category h3 {
    margin: 0 0 15px 0;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'FedraSansPro', sans-serif;
}

.faq .question {
    background-color: #fff;
    padding: 13px 55px 13px 0;
    cursor: pointer;
    position: relative;
    font-family: "FedraSansPro", sans-serif;
    font-weight: 600;
    color: #414141;
}

.faq .question.collapsed {
    box-shadow: none;
    background-color: #fff;
}

.faq .question.active {
    background: #fafafa;
}

.faq .accordion {
    margin: 0 0 50px 0;
}

.faq .accordion .accordion-item {
    border-bottom: 1px solid #eaeaea;
}

.faq .accordion:last-of-type {
    margin: 0;
}

.faq .accordion .question:after {
    content: "-";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -15px;
    font-size: 17px;
}

.faq .accordion .question.collapsed:after {
    content: "+";
    margin-top: -13px;
}

.faq .answer .answer-content {
    padding: 10px 0 30px;
    opacity: .6;
}

.faq .faq-heading {
    font-size: 15px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
    text-transform: uppercase;
}


.forgot-password h1 {
    border-color: #d7be7e;
}

.forgot-password h3 {
    font-family: 'FedraSansPro', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.forgot-password {
    background-image: url(../../images/login-sharps.svg);
    background-repeat: repeat-y;
    background-position: bottom left;
    background-size: 150px;
}

.forgot-password .icon-mail {
    top: 13px;
}

.forgot-password .back {
    margin: 30px auto 0;
    display: table;
}

.forgot-password button {
    display: block;
    width: 100%;
}


.card-payment h1 {
    border-color: #d7be7e;
}


.card-payment .back {
    margin: 0;
}


.empty-category {
    text-align: center;
}

.empty-category .btn {
    margin: 10px auto 0;
    display: table;
}

.empty-category h3 {
    margin: 0 0 5px 0;
    border-color: #d7be7e;
}

.empty-category img {
    margin: 0 auto;
    display: block;
}


.textpage {
    background-image: url(../../images/textpage-sharps.svg);
    background-repeat: no-repeat;
    background-position: right 90px;
    background-size: 100px;
}

.textpage h1 {
    border-color: #d7be7e;
}

.textpage h3 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 10px 0;
    font-family: 'FedraSansPro', sans-serif;
    font-weight: bold;
}


.contacts {
    background-color: #F5F3EF;
    position: relative;
}

.contacts:after {
    content: "";
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 30%;
    height: 100%;
}

.contacts .map {
    position: relative;
    z-index: 2;
    height: 400px;
    width: 100%;
}

.contacts .heading {
    border-color: #d7be7e;
    margin: 0 0 20px 0;
    text-align: left !important;
}

.contacts h4 {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 10px 0;
    font-weight: bold;
    font-family: 'FedraSansPro', sans-serif;
}

.contact-form .form-group {
    margin-bottom: 30px;
}

.contacts .contact-form {
    background-color: #fff;
    padding: 100px 0 100px 100px;
    border-left: 1px solid #DCDAD9;
    z-index: 2;
    position: relative;
}

.contact-form .heading {
    text-align: center;
    margin: 0 0 20px 0;
}

.contact-form .sub-heading {
    margin: 0 auto 40px auto;
}

.contact-form label {
    font-size: 16px;
    line-height: 16px;
    font-family: 'Moderato G5 L';
    font-weight: bold;
}

.contact-form p {
    margin: 0 0 40px 0;
}

.contacts .contact-info {
    padding: 77px 0 0 0;
}

.contacts .contact-info ul li {
    border-bottom: 1px solid #DCDAD9;
    padding: 25px 15px 25px 30px;
}

.contacts .contact-info ul li .val a {
    color: #262020;
}

.contacts .contact-info ul li .val img {
    margin: 0 10px 0 0;
    width: 22px;
    height: 22px;
}

.contacts .contact-info ul li .val {
    color: #262020;
    font-size: 24px;
    line-height: 28px;
    font-family: 'Moderato G5 L';
    padding: 0 15px 0 0;
}

.contacts .contact-info ul li .lab {
    color: #1C1B1B;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Sofia Sans', sans-serif;
    display: block;
}


.favorites.products {
    padding-bottom: 0;
}

.favorites h1 {
    border-color: #d7be7e;
}


.team {
    background-image: url(../../images/textpage-sharps.svg);
    background-repeat: no-repeat;
    background-position: right 90px;
    background-size: 100px;
    padding-bottom: 30px;
}

.team .person {
    margin: 0 0 70px 0;
}

.team .person-name {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    border-color: #d7be7e;
    font-family: 'FSerProB', sans-serif;
    margin: 20px 0 8px 0;
}

.team .person-pos {
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #000;
    margin: 0 0 20px 0;
}

.team a {
    margin: 0 auto;
    display: table;
    text-transform: uppercase;
    opacity: .6;
    text-decoration: underline;
}

.team a:hover {
    color: #9d7a45;
}

.team .person-text {
    text-align: center;
    margin: 0 0 25px 0;
}

.team .person-text p:last-of-type {
    margin: 0;
}


.payment-bank {
    padding: 25px;
    border: 1px solid #e3e2e2;
    margin-top: 50px;
}

.payment-bank h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    border-color: #d7be7e;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
}

.payment-bank ul li {
    clear: both;
    border-top: 1px solid #e3e2e2;
    padding: 10px 0;
    display: table;
    width: 100%;
}

.payment-bank ul li:last-of-type {
    padding-bottom: 0;
}

.payment-bank ul li:first-of-type {
    border: 0;
    padding-top: 0;
}

.payment-bank ul li .lab {
    float: left;
}

.payment-bank ul li .val {
    float: right;
    font-weight: bold;
    color: #000;
}


/*newsletter*/
.events-action {
    background: url('../../images/newsletter-bgr.jpg') top center no-repeat;
    background-size: cover;
    position: relative;
}

.events-action:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.events-action .container {
    position: relative;
    z-index: 3;
}

.events-action .heading {
    color: #fff;
    margin-bottom: 20px;
}


.events-action {
    text-align: center;
}

.events-action p {
    color: #fff;
    font-size: 15px;
    margin: 0 0 35px 0;
}


.where-are-we {
    background: url('../../images/where-are-we.jpg') center center no-repeat;
    background-size: cover;
}

.where-are-we .heading {
    color: #fff;
    margin: 0;
}


.gallery-text {
    background-color: #FBFAF9;
}

.gallery-text .sup-heading {
    margin: 0 0 10px 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
}

.gallery-text .heading {
    margin-bottom: 30px;
}

.gallery-text .text-block {
    margin: 20px 0;
}


.gallery .hero-area {
    padding: 120px 0;
}

.gallery .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}

.short-map iframe {
    height: 550px;
}

.short-contacts {
    text-align: center;
}

.short-contacts img {
    margin: 0 0 20px 0;
}

.short-contacts a {
    font-weight: bold;
}

.short-contacts .lab {
    font-weight: bold;
    color: #000;
    margin: 0 0 7px 0;
}

.short-contacts .val {
    padding: 0 50px;
}


.consultants {
    padding-bottom: 30px;
}

.consultants .consultant {
    margin: 0 0 70px 0;
}

.consultants .consultant .consultant-content {
    padding: 0 30px;
}

.consultants .consultant .consultant-content.md-cl-pl {
    padding-left: 0;
}

.consultants .consultant .consultant-name {
    font-family: 'FSerProB', sans-serif;
    font-size: 21px;
    line-height: 28px;
    border-color: #d7be7e;
    margin: 0 0 7px 0;
}

.consultants .consultant .consultant-pos {
    text-transform: uppercase;
    color: #000;
    font-size: 12px;
}

.consultants .consultant .consultant-desc {
    margin: 20px 0 0 0;
}


.mb-cart-heading {
    display: none;
}

.range-slider {
    position: relative;
    height: 70px;
}

.irs--round .irs-from, .irs--round .irs-to,
.irs--round .irs-single {
    background-color: #d7be7e;
    border-radius: 0;
}

.irs--round .irs-from:before, .irs--round .irs-to:before,
.irs--round .irs-single:before {
    border-top-border-color: #d7be7e;
}

.irs--round .irs-min, .irs--round .irs-max {
    border-radius: 0;
    color: #fff;
    background-color: #9d7a45;
}

.irs--round .irs-bar {
    background-color: #9d7a45;
}

.irs--round .irs-handle {
    border: 4px solid #d7be7e;
}

.extra-controls {
    padding: 0 30px;
}

.extra-controls input {
    width: 100%;
    text-align: center;
    height: 30px;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #e3e2e2;
    font-size: 14px;
}

.extra-controls input:focus {
    box-shadow: none;
    border-color: #d7be7e;
}


/*Profile*/
.profile {
    background-color: #FBFAF9;
    position: relative;
}

.profile .heading {
    text-align: left;
    margin: 0 0 40px 0;
    border-color: #d7be7e;
}

.profile:after {
    content: "";
    min-height: 100%;
    width: 30%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}

.profile .profile-content {
    background-color: #fff;
    padding: 65px 55px 100px 55px;
    border-left: 1px solid #DCDAD9;
    position: relative;
    height: 100%;
    z-index: 1;
}

.profile .profile-content .order-row .order-num {
    margin-bottom: 15px;
}

.profile .profile-content .order-row .order-num span {
    padding-right: 15px;
}

.profile .profile-content .order-row .order-num span.btn-text {
    max-width: 140px;
    display: block;
    padding: 0 7px 0 0;

}

.profile .profile-content .order-row .order-num a.replay,
.profile .profile-content .order-row .order-num a.cancel,
.profile .profile-content .order-row .order-num a.pay-again {
    position: relative;
    font-size: 11px;
}

.profile .profile-content .order-row .order-num a.replay:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_1.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(50%, -50%);
    margin-left: 3px;
}

.profile .profile-content .order-row .order-num a.cancel:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_2.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(50%, -50%);
    margin-left: 3px;
}

.profile .profile-content .order-row .order-num a.cancel:hover:before {
    background: url("../../images/icon_2-white.svg") center no-repeat;
}

.profile .profile-content .order-row .order-num a.pay-again span {
    position: relative;
}

.profile .profile-content .order-row .order-num a.pay-again span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_3.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -64%);
    margin-left: -15px;
}

.profile .profile-content .order-row .order-num a.pay-again:hover span:before {
    background: url("../../images/icon_3-greenn.svg") center no-repeat;
}


.profile .form-group {
    margin: 0 0 30px 0;
}


.profile-orders .order-row {
    padding: 20px;
    border: 1px solid #ECECEC;
    margin-bottom: 30px;
}

.profile-orders .order-row:last-of-type {
    margin-bottom: 0;
}

.profile-orders .order-num {
    font-family: 'FSerProB', sans-serif;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #ECECEC;
    font-size: 17px;
    line-height: 26px;
    font-weight: bold;
    border-color: #d7be7e;
}

.profile-content .order-row {
    padding: 10px 20px 20px 20px;
    border: 1px solid #ECECEC;
    margin-bottom: 30px;
}

.profile-content .order-row a.btn {
    height: auto;
    line-height: 17px;
    padding: 6px 6px 6px 38px;
    max-width: 147px;
    text-align: left;
    min-height: 48px;
    display: flex;
    align-items: center;

}

.profile-content .order-row a.btn.pay-again {
    max-width: 180px;
}

.profile-content .order-row a.btn span {

}

.profile-content .order-row:last-of-type {
    margin-bottom: 0;
}

.profile-content .order-num {
    font-family: 'FSerProB', sans-serif;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #ECECEC;
    font-size: 17px;
    line-height: 26px;
    font-weight: bold;
    border-color: #d7be7e;
}

.profile .order-details.silver-bgr {
    border: 0;
    background-color: #FBFAF9;
}

.profile .order-details:last-of-type {
    margin-bottom: 0;
}

.profile .order-details h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    border-color: #d7be7e;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.profile .order-details .lab {
    color: #909090;
}

.profile .order-details .val {
    font-weight: bold;
    font-size: 13px;
    color: #000;
}

.profile .order-details .active {
    font-weight: bold;
    font-size: 13px;
    color: white;
    background-color: forestgreen;
    text-align: center;
    max-width: 130px;
    border-radius: 4px;
}

.profile .order-details .inactive {
    font-weight: bold;
    font-size: 13px;
    color: white;
    background-color: #dc3545;
    text-align: center;
    max-width: 130px;
    border-radius: 4px;
}

.profile .order-details {
    padding: 20px;
    border: 1px solid #ECECEC;
    margin: 0 0 30px 0;
}

.profile-order .order-products h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    border-color: #d7be7e;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.profile-order .order-products {
    padding: 0 20px;
    border: 1px solid #ECECEC;
    margin: 0 0 30px 0;
}

.profile-order .order-products .product-row:last-of-type {
    border-bottom: 0;
}

.profile .order-products {
    border-top: 1px solid #ECECEC;
}

.profile .order-products .product-row {
    padding: 20px 0;
    border-bottom: 1px solid #ECECEC;
}

.profile .order-products .product-row .product-name {
    font-weight: bold;
    font-size: 13px;
    color: #000;
    margin: 0 0 5px 0;
}

.profile .order-products .product-row .product-img {
    max-width: 80px;
}

.profile .order-products .product-row .lab {
    margin: 0 0 7px 0;
    color: #909090;
}

.profile .order-row .view-order {
    display: table;
    margin: 17px 0 0 auto;
}

.profile .order-row .view-order i {
    margin-left: 5px;
}

.profile .order-products .product-row .product-desc {
    color: #909090;
}

.profile .product-price {
    text-align: right;
}

.profile-address-list .btn {
    margin: 30px 0 0 0;
    display: table;
}

.profile-address-list .edit-address {
    display: block;
}

.profile-address-list .primary-address {
    font-family: 'FedraSansPro', sans-serif;
    font-weight: bold;
    color: #fff;
    padding: 2px 15px;
    background-color: #d7be7e;
    float: right;
    font-size: 11px;
    text-transform: uppercase;
}

.profile .add-address {
    padding: 20px;
    background-color: #FBFAF9;
}

.profile .add-address h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    border-color: #d7be7e;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
}

.profile.profile-cards .btn-sm {
    padding: .25rem .5rem;
    font-size: 12px;
    line-height: 1.5;
    height: auto;
    letter-spacing: 0;
    text-transform: none;
}

.user-menu {
    margin: 60px 0 60px 0;
}

.user-menu li {
    display: block;
    border-bottom: 1px solid #DCDAD9;
}

.user-menu li a:hover,
.user-menu li.active a {
    background-color: #fff;
    border-color: #d7be7e;
}

.user-menu li a {
    display: block;
    padding: 12px 10px;
    text-transform: uppercase;
    position: relative;
    color: #909090;
}

.user-menu li a img {
    height: 18px;
    vertical-align: sub;
    margin-right: 7px;
    min-width: 25px;
}

.user-menu li a img.icn {
    display: inline-block;
}

.user-menu li a:hover img.icn {
    display: none;
}

.user-menu li a img.icn-hover {
    display: none;
}

.user-menu li a:hover img.icn-hover {
    display: inline-block;
}

.user-menu li a:hover {
    border-color: #d7be7e;
}

.user-menu li a:after {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 19px;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #b5b5b5;
}

.carousel-control-prev-icon {
    background: url('../../images/icons/arrow-left.svg');
    background-size: 30px;
}

.carousel-control-next-icon {
    background: url('../../images/icons/arrow-right.svg');
    background-size: 30px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 30px;
    height: 30px;
}

.carousel-item {
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    /*padding-top: 30.46%;*/
}

.age-gate {
    text-align: center;
}

.age-gate .modal-body {
    padding: 40px 30px;
}

.age-gate h5 {
    color: #4E1E50;
    margin: 0 0 30px 0;
    font-size: 22px;
    line-height: 28px;
}

.age-gate img {
    margin: 0 auto 30px;
    display: block;
    height: 150px;
}

.age-gate .btn {
    margin: 0 5px;
    min-width: 130px;
}

.modal-backdrop {
    background-color: #4E1E50;
}

.custom-calendar header {
    position: initial;
    padding: 0;
}

.custom-calendar.vdp-datepicker__calendar .cell.selected {
    background-color: #d7be7e;
    color: #fff;
}

.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid #d7be7e;
}

.grecaptcha-badge {
    bottom: 95px !important;
}

.page-content .select2-container--bootstrap4 .select2-selection {
    min-height: 44px;
    border-color: #e7e6e6;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
    line-height: calc(2.1em + .75rem);
}


/*main-page - book-landing*/


.book-landing {
}

.newsletter .row .alert {
    width: 100%;
}


/*view page lp*/


.slider-module .owl-carousel .carousel-block {
    position: absolute;
    right: 15%;
    bottom: 25%;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 18px;
    color: #fff;
    text-align: center;
}


.slider-module .owl-carousel .carousel-block h1 {
    color: #E5BA5F;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 15px;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 11px;


}

.slider-module .owl-carousel .carousel-block h2 {
    color: white;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 38px;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    line-height: 45px;
    margin-bottom: 23px;
}

.slider-module .owl-carousel .carousel-block p {
    font-family: Montserrat, sans-serif;
    color: white;
    margin: 0 auto 22px auto;
    width: 300px;
    font-size: 14px;
    line-height: 23px;
}

.slider-module .owl-carousel .owl-dots {
    position: absolute;
    left: 50%;
    margin-left: -60px;
    bottom: 0;
    margin-bottom: 27px;
}

.slider-module .owl-theme .owl-dots .owl-dot span {
    width: 14px;
    height: 14px;
    margin: 5px 5px;
    background: #AD9587;
}

.slider-module .owl-theme .owl-dots .owl-dot.active span {
    border: 2px solid white;
    background: transparent;

}

.slider-module .owl-carousel .owl-dots button {
    outline: none;
}

.slider-module .owl-carousel .owl-nav {
    margin: 0;
}

.slider-module .owl-carousel .owl-nav button {
    position: absolute;
    top: 50%;
    margin-top: -32px;

    width: 47px;
    height: 47px;
    border-radius: 0;

}

.slider-module .owl-carousel .owl-nav button span {
    font-size: 0;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    display: block;
    width: 16px;
    height: 16px;
}

.slider-module .owl-carousel .owl-nav button.owl-next {
    right: 0;
    margin-right: 20px;
    background: #493C34;

}

.slider-module .owl-carousel .owl-nav button.owl-next:hover {
    background: #493C34;
    opacity: 0.7;
}

.slider-module .owl-carousel .owl-nav button.owl-next span {

    left: 10px;
    transform: rotate(225deg);
    position: relative;

}


.slider-module .owl-carousel .owl-nav button.owl-prev {
    left: 0;
    background: #2A211E;
    margin-left: 20px;
}

.slider-module .owl-carousel .owl-nav button.owl-prev:hover {
    opacity: 0.7;
    background: #2A211E;

}


.slider-module .owl-carousel .owl-nav button.owl-prev span {
    transform: rotate(45deg);
    position: relative;
    left: 18px;
}


.slider-module .owl-carousel .owl-nav button span {
    color: white;
    font-size: 0;
}

a.btn.buy {
    background: #9d7a45;
    color: white;
    padding: 0 50px;
    position: relative;
    z-index: 1;
}

a.btn.buy:hover {
    color: #9d7a45;
    background: white;
}


/*two-banners-module*/

.two-banners-module {
    padding: 70px 0;
    position: relative;
}

.two-banners-module .module-banner {
    position: relative;
}

.two-banners-module .module-banner a.gift-box {
    position: relative;
    width: 100%;
    display: block;
}

.two-banners-module img.left-decoration {
    position: absolute;
    left: 0;
    width: 180px;
    top: 0;
    margin-top: 10px;
    margin-left: -31px;
    z-index: 1;
}

.two-banners-module img.right-decoration {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -144px;
    width: 180px;
    z-index: 1;

}

.two-banners-module .module-banner:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .0)), to(rgba(0, 0, 0, .4)));
    background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .4));
    pointer-events: none;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.two-banners-module .module-banner:hover:before {
    height: 100%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .0)), to(rgba(0, 0, 0, .7)));
    background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .7));
    z-index: 1;
}

.two-banners-module .module-banner .module-banner-choice {
    position: absolute;
    bottom: 15%;
    margin-left: 55px;
    margin-top: -14px;
}

.two-banners-module .module-banner .module-banner-choice h3 {
    text-align: right;
}

.two-banners-module .module-banner .module-banner-choice.right-banner {
    right: 0;
    margin-right: 55px;
}

/*holiday-offers*/

.holiday-offers {
    padding: 40px 0 33px 0;
    position: relative;

}

.holiday-offers h3 {
    color: #000000;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 34px;
    font-family: FSerProB, sans-serif;
    font-weight: 700;
}

.holiday-offers .gift-image {
    position: absolute;
    left: 0;
    top: 50%;
    width: 475px;
    margin-left: -331px;
    margin-top: -220px;
    z-index: 2;
}

/*.holiday-offers ul {*/
/*    text-align: center;*/
/*    margin: 0 0 32px 0px;*/
/*}*/

/*.holiday-offers ul li {*/
/*    display: inline-block;*/
/*    padding: 0 10px;*/
/*}*/

/*.holiday-offers ul li a {*/
/*    display: block;*/
/*    border: 1px solid #D2D2D2;*/
/*    padding: 7px 34px;*/
/*    color: #4E1E50;*/
/*    background: white;*/
/*}*/

/*.holiday-offers ul li a:hover {*/
/*    border: 1px solid #4E1E50;*/
/*}*/

.holiday-offers .category-choice {
    text-align: center;
    padding: 0 105px;
    margin-bottom: 30px;
}

.holiday-offers .category-choice button {
    display: block;
    border: 1px solid #D2D2D2;
    padding: 8px 0;
    color: #4E1E50;
    cursor: pointer;
    width: 100%;
    outline: none;
    background: white;
}

.holiday-offers .category-choice button:hover {
    border: 1px solid #4E1E50;
}

.holiday-offers .category-choice button.active {
    border: 1px solid #4E1E50;
}

.holiday-offers .product-article {
    overflow: hidden;
    position: relative;
    -webkit-transition: all .4s;
    transition: all .4s;
}

.holiday-offers .product-article .product-info:hover {
    margin-bottom: 0;
    /*-ms-transform: translateY(-80px);*/
    /*-webkit-transform: translateY(-80px);*/
    /*transform: translateY(-80px);*/
}

.holiday-offers .product-article .product-image {
    overflow: hidden;
    position: relative;
    -webkit-transition: all .4s;
    transition: all .4s;
    z-index: 3;
}

.holiday-offers .product-article .product-image a {
    display: block;
    background: #FCFCFC;
    padding: 13px 30px;
    position: relative;
    text-align: center;
}

.holiday-offers .product-article .product-image a img {

}

.holiday-offers .product-article .product-image a img.new-icon {
    position: absolute;
    left: 0;
    max-width: 35px;
    margin-left: 14px;
    top: 0;
    margin-top: 20px;
}

.holiday-offers .product-article .product-info {
    z-index: 4;
    -webkit-transition: all .4s;
    transition: all .4s;
    position: relative;
    background: white;
    overflow: hidden;
}

.holiday-offers .product-article .product-info:hover {
    -ms-transform: translateY(-80px);
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
    /*margin-top: -80px;*/
}

.holiday-offers .product-article .product-info .product-action {
    height: 0;
    transition: all .2s;
    opacity: 0;
}

.holiday-offers .product-article .product-info:hover .product-action {
    position: inherit;
    opacity: 1;
    height: 115px;
}


.holiday-offers .product-article .product-info .product-name {
    font-size: 11px;
    line-height: 20px;
    padding: 13px 15px 20px 15px;
    font-weight: lighter;
    height: 96px;
    margin: 0;

    position: relative;
    font-family: FSerProB, sans-serif;
    color: #000;
    /* display: -webkit-box; */
    text-align: center;
}


.holiday-offers .product-article .product-info .product-name:after {
    content: "";
    width: 30px;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -15px;
}

.holiday-offers .product-article .product-info .product-desk {
    text-align: center;
    color: #6a6665;
    display: -webkit-box;
    display: flex;
    font-size: 12px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 50px;
    padding-top: 22px;
}


.holiday-offers .product-article .product-info .product-prices {
    text-align: center;
    padding: 17px 0 15px 0;
    background: white;
}

.holiday-offers .product-article .product-info .product-prices span {
    color: #000000;
}

.holiday-offers .product-article .product-info .product-action a {
    display: block;
    width: 100%;
    padding: 0;
    text-transform: none;
    font-size: 13px;
}

.holiday-offers .product-article .product-info .product-action a.buy {
    margin-bottom: 10px;

}

.holiday-offers .product-article .product-info .product-action a.view {
    display: block;
    text-align: center;
    width: 100%;
    padding: 11px 0 10px 0;
    border: 1px solid #d7be7e;
    border-color: #d7be7e;
}

.holiday-offers .product-article .product-info .product-action a.view:hover {
    background: #9d7a45;
    color: white;
}


.holiday-offers .see-more {
    position: relative;
}

.holiday-offers .see-more .info-link {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.holiday-offers .product-article.see-more img.bgr-effect {
    position: absolute;
    right: 0;
    height: 100%;
    bottom: 0;
}

.holiday-offers .product-article.see-more .more-info {
    color: white;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    z-index: 100;
    top: 50%;
    font-size: 14px;
    line-height: 26px;
    margin-top: -39px;
    padding: 0 18px;
}

.holiday-offers .product-article.see-more a {
    display: block;
    position: absolute;
    z-index: 100;
    bottom: 0;
    right: 0;
    width: 42px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.holiday-offers .product-article .product-info.tequila .product-desk {
    /*padding: 0 21px;*/
}

.holiday-offers .product-article .product-info.tequila .product-name {
    padding: 13px 45px 20px 45px;
}


/*product-slide-module*/


.product-slide-module {
    background: #FFE8CF;
    position: relative;
    padding: 55px 0;

}


.product-slide-module .left-mask {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

.product-slide-module .products-list .product-item {
    background: #fff;
    padding: 5px;
    height: 440px
}

.product-slide-module .products-list .product-item .product-action {
    opacity: 0;
    display: none;
}

.product-slide-module .products-list .product-item:hover .product-action {
    opacity: 1;
    display: block;
}

.product-slide-module .product-labels {
    top: 5px;
    left: 5px;
}

.product-slide-module .add-fav {
    top: 5px;
    right: 5px;
}

.product-slide-module.holiday-offers .product-article .product-image a {
    padding: 13px 28px 26px 28px;
}

.product-slide-module h2 {
    color: #471755;
    text-transform: uppercase;
    font-size: 34px;
    line-height: 60px;
    width: 476px;
    margin: 64px 0 0 100px;

}

.product-slide-module h3 {
    margin-top: 60px;
    font-size: 22px;
    line-height: 30px;
}

.product-slide-module .product-info {
    transition: all .4s;
}

.product-slide-module .wine-img img {
    width: 250px;
}

.product-slide-module .owl-carousel .owl-nav button {
    position: absolute;
    top: 38%;
    width: 20px;
    height: 47px;
    border-radius: 0;

}

.product-slide-module .owl-carousel .owl-nav button span {
    font-size: 0;
    border-left: 2px solid #A8A8A8;
    border-bottom: 2px solid #A8A8A8;
    display: block;
    width: 16px;
    height: 16px;
}

.product-slide-module .owl-carousel .owl-nav button.owl-next {
    right: 0;
    margin-right: -22px;

}

.product-slide-module .owl-carousel .owl-nav button.owl-next:hover {
    opacity: 0.7;
}

.product-slide-module .owl-carousel .owl-nav button.owl-next span {

    left: 10px;
    transform: rotate(225deg);
    position: relative;

}


.product-slide-module .owl-carousel .owl-nav button.owl-prev {
    left: 0;
    margin-left: -48px;
}

.product-slide-module .owl-carousel .owl-nav button.owl-prev:hover {
    opacity: 0.7;

}

.product-slide-module .owl-carousel .owl-nav button.owl-prev span {
    transform: rotate(45deg);
    position: relative;
    left: 18px;
}


.product-slide-module .owl-carousel .owl-nav button span {
    color: #A8A8A8;
    font-size: 0;
}

.product-slide-module .owl-carousel .owl-dots {
    display: none;
}


.product-slide-module .see-more {
    text-align: center;
    margin-top: 35px;
}

.product-slide-module .see-more a:hover {
    background: transparent;
    color: #9d7a45;
    border: 1px solid #9d7a45;;

}


.product-slide-module .decoration {
    position: absolute;
    top: 0;
    left: 50%;
    width: 176px;
    z-index: 1;
    margin-left: -78px;
    margin-top: -35px;
}

.product-slide-module .star-decoration {
    position: absolute;
    left: 0;
    width: 137px;
    top: 21%;

}

.product-slide-module .gift-box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 130px;
    margin-bottom: -137px;
    z-index: 1;
}


/*food-and-wine*/


.holiday-offers .star-decor {
    position: absolute;
    top: 0;
    width: 146px;
    right: 0;
    margin-top: -40px;
}

.holiday-offers .star-decor-sec {
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 57%;
    width: 140px;
    margin-left: -73px;
    margin-bottom: -40px;

}

/*product-slide-module copied*/

.product-slide-module .gift-box-sec {
    position: absolute;
    bottom: 0;
    left: 26%;
    margin-bottom: -40px;
    z-index: 1;
    width: 145px;
}


/*mystery-box-module*/


.mystery-box-module {
    padding: 65px 0 70px 0;
    background: #FAF7FA;
    position: relative;
}

.mystery-box-module h3 {
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 10px;
    font-size: 23px;
    line-height: 48px;
    margin-top: 35px;
}

.mystery-box-module .mask-gr {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

}

.mystery-box-module .mystery-star {
    position: absolute;
    left: 0;
    margin-top: -205px;
    top: 50%;
    z-index: 1;
    width: 280px;
    margin-left: -112px;
}

.mystery-box-module .mystery-box {
    position: absolute;
    bottom: 0;
    width: 120px;
    right: 0;
    margin-bottom: -64px;
    z-index: 1;

}

.mystery-box-module ul {

}

.mystery-box-module ul li {
    position: relative;
    padding: 27px 0 0 42px;
    color: #000000;
    font-family: FedraSerifAPro, sans-serif;
}

.mystery-box-module ul li .list-logo-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 46px;
    text-align: center;
    padding: 0 14px 0 0;
    margin-top: 31px;
}

.mystery-box-module ul li:nth-child(1) .list-logo-image {
    margin-top: 28px;
}

.mystery-box-module ul li img {
    max-height: 36px;
}

.mystery-box-module ul li span {
    display: block;
    font-family: 'FedraSansPro', sans-serif;
    color: #6A6665;
    font-size: 12px;
}

.mystery-box-module .mystery-box-img {
    display: flex;
    align-items: flex-end;
}

.mystery-box-module .mystery-box-img .bottle {

}

.mystery-box-module .mystery-box-img .bottle img {
    width: 180px;
    margin-bottom: 15px;
    margin-right: 15px;
}

.mystery-box-module .mystery-box-img .box-img {
    position: relative;
}

.mystery-box-module .mystery-box-img .box-img h3 {
    position: absolute;
    color: #9d7a45;
    top: 25%;
    font-size: 40px;
    text-transform: CAPITALIZE;
    left: 50%;
    margin-left: -133px;
    margin-top: -40px;
}

.mystery-box-module .mystery-box-img .box-img img {
    width: 405px;
}


.mystery-box-module .mystery-box-img .wine-count {
    position: absolute;
    background: white;
    border-radius: 100px;
    bottom: 0;
    width: 96px;
    height: 96px;
    border: 1px solid #E81E25;
    left: 0;
    margin-left: -48px;
}

.mystery-box-module .mystery-box-img .wine-count span {
    color: #E81E25;
    font-size: 55px;
    display: block;
    text-align: center;
    /* font-weight: 600; */
    margin-top: -5px;
}


.product-slide-module .star-sec {
    position: absolute;
    bottom: 0;
    right: 7%;
    width: 110px;
    margin-bottom: -27px;

}

/*.product-slide-module.fourth*/

.product-slide-module .left-mask-2 {
    position: absolute;
    left: 0;
    width: 325px;
    top: 9%;
    margin-left: -234px;

}

.product-slide-module .right-mask {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}


/*newsletter.gift*/


.newsletter.gift {
    padding: 80px 0 60px 0;
    background: white;
    border: none;
    position: relative;
    margin-bottom: 0;
}

.newsletter.gift .container {
    position: initial;
}


.newsletter.gift:before {
    display: none;
}

.newsletter.gift:after {
    display: none;
}

.newsletter.gift h3 {
    font-family: 'FSerProB', sans-serif;
    color: #FFFFFF;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    padding: 0 232px;
    line-height: 37px;
    margin-bottom: 18px;
    margin-top: 25px;
}

.newsletter.gift p {
    font-size: 14px;
    line-height: 24px;
    padding: 0 170px;
    opacity: 1;
}

.newsletter.gift .news-cont {
    padding: 0 120px;
}

.newsletter.gift .newsletter-form {
    background-size: cover;
    padding: 70px 0;
}

.newsletter.gift .newsletter-star-bgr {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 38px;
}

.newsletter.gift form button {
    background: #E81E25;
    height: 43px;
    color: white;
    padding: 3px 0;
    width: 160px;
    font-size: 11px;
}

.newsletter.gift form button:hover {
    border: 1px solid white;
    background: transparent;
    letter-spacing: 2px;
}


.newsletter.gift form .form-group .checkmark2 {
    position: absolute;
    height: 21px;
    width: 21px;
    background-color: white;
    border-radius: 0;
    border: 0.5px solid #BBC1C5;
    top: 0;
    left: 0;
    margin-left: 0;
    appearance: none;
    -webkit-border-image: none;
    -webkit-appearance: none;
    margin-top: -1px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.newsletter.gift .form-group .checkmark2:after {
    content: "";
    position: absolute;
    display: none;
    -webkit-appearance: none;
    appearance: none;
}

/* Show the checkmark when checked */
.newsletter.gift .form-group .form-check2 input:checked ~ .checkmark2:after {
    display: block;
    -webkit-appearance: none;
    appearance: none;

}

/* Style the checkmark/indicator */
.newsletter.gift .form-group .form-check2 .checkmark2:after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 10px;
    border: solid #163746;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-appearance: none;
    appearance: none;
}


.newsletter.gift .form-group.checkbox2 {
    margin-left: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    margin-top: 14px;
}

.newsletter.gift .form-group.checkbox2 input {
    position: absolute;
    left: 0;
    top: 5px;
    margin-left: 5px;
    z-index: -2;

}

.newsletter.gift .form-group.checkbox2 label {
    color: white;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    padding-left: 28px;
}


/*decorated-footer-module*/


.decorated-footer-module {
    padding: 65px 0;
    position: relative;
}

.decorated-footer-module p {
    text-align: center;
    color: #242322;
    font-size: 12px;
    margin-bottom: 0;
}

.decorated-footer-module .wine-decor {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 260px;
}

.decorated-footer-module .footer-bgr-mask {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 300px;
}

.decorated-footer-module .footer-decor-2 {
    position: absolute;
    right: 20%;
    bottom: 0;
    width: 180px;
}


.decorated-footer-module .footer-decor-3 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 290px;
    margin-bottom: -65px;

}


/*e-book page*/


.book-landing .intro.intro-book {
    background: url('../../images/e-book-bgr.png') center no-repeat, url('../../images/e-book-bgr-mask.png') center no-repeat;
    background-size: cover;
}

.book-landing .intro.intro-book .intro-bg {
    background: url('../../images/lemmon2.png') top 65px left no-repeat, url('../../images/fruit.png') top right no-repeat;
}

.book-landing .intro.intro-book h1 {
    color: #4E1E50;
    font-family: 'FSerProB', sans-serif;
    width: 100%;
    font-size: 59px;
    line-height: 78px;
    margin-bottom: 35px;
}

.intro.intro-book .image-book img.mobile-book {
    display: none;
}


.intro.intro-book .free-book {
    padding: 8px 27px;
    font-size: 14px;
    letter-spacing: 2px;
}

.intro .intro-items .our-logo {
    margin-bottom: 35px;
    display: flex;
    align-items: flex-start;

}

.intro-book a.download-btn img.purple-logo {
    display: none;
}

.intro-book a.download-btn:hover {
    border-color: #d7be7e;
    border: 1px solid #d7be7e;
}

.intro-book a.download-btn:hover img:first-child {
    display: none;
}

.intro-book a.download-btn:hover img.purple-logo {
    display: inline-block;
}


.intro .intro-items {
    position: relative;
    z-index: 20;
}

.intro .intro-items .our-logo span {
    text-transform: uppercase;
    display: table;
    font-size: 16px;
    margin-top: 5px;
    font-family: 'FSerProB', sans-serif;
    color: #2D2A2A;
}

.intro .intro-items .our-logo img {
    max-width: 234px;
    margin: 0 17px;
}


.intro.intro-book .image-book img {
    position: absolute;
    top: 0;
    max-width: 790px;
    left: 0;
    margin-left: -265px;
    margin-top: -45px;
    z-index: 2;
}

.intro-book .image-book img.spice {
    right: 0;
    margin-left: 400px;
    top: 0;
    margin-top: 25px;
    z-index: 1;
}


/*from-us-our-team*/

.from-us.our-team {
    padding: 100px 0;
}

.from-us.our-team h3 {
    width: 100%;
}

.from-us.our-team h3 span {
    display: block;
}

.from-us.our-team .our-info p {
    margin-bottom: 0;
    width: 100%;
    padding-right: 15px;
}

.from-us.our-team .book {
    margin-top: 48px;
    height: 385px;
}

.from-us.our-team .our-info {
    padding-bottom: 40px;
}


.from-us.our-team .book-container img {
    position: absolute;
    max-width: 485px;
    display: table;
    bottom: 0;
    left: 50%;
    margin-left: -224px;
    margin-bottom: -77px;
}


.from-us .form-container .form-check {
    position: relative;
}

.from-us .form-container .form-check input {
    left: 0;
    margin: 4px 0 0 0;
    height: auto !important;
}

.from-us .form-container .form-check label {
    padding-left: 20px;
}

.from-us .form-container .download-btn {
    margin-top: 20px !important;
}

.from-us.our-team .form-group input:focus {
    /*border: 2px solid blue;*/
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}


#flash-messages-wrapper {
    position: relative;
    float: right;
    width: 100%;
}

#flash-messages-container {
    position: relative;
    z-index: 2 !important;
    width: 100%;
    padding-top: 20px;
}

.alert-success {
    margin-bottom: 50px;
}

.container .message-div {
    height: 50px;
    text-align: center;
    padding: 12px 0;
    background-color: #FEF6E8;
    border: 1px solid #F5BA7F;
    text-decoration: solid;
    color: #C57844;
    font-size: 16px;
    margin: 15px 0 15px 0;
}


/*our-sommelier*/

.our-sommelier {
    padding: 30px 0 80px 0;
}

.our-sommelier h2 {
    color: #4E1E50;
    text-transform: inherit;
}

.our-sommelier .sommelier-img {
    position: relative;
    padding: 0 10px;
}

.our-sommelier .sommelier-img img {
    position: relative;
    width: 100%;
}

.our-sommelier .sommelier-img:before {
    content: "";
    position: absolute;
    background: url("../../images/person-effect.svg") center no-repeat;
    background-size: 100%;
    width: 48px;
    height: 48px;
    bottom: 0;
    right: 0;
    margin-bottom: -16px;
    z-index: 1;
    margin-right: -13px;
}

.our-sommelier .sommelier-info {
    text-align: center;
    padding: 24px 0 0 0;
}

.our-sommelier .sommelier-info h3 {
    font-family: "FedraSerifAPro", sans-serif;
    border-color: #d7be7e;
    font-size: 21px;
    margin-bottom: 12px;
}

.our-sommelier .sommelier-info p {
    font-size: 15px;
    line-height: 25px;

}

.mystery-box-info p,
.our-sommelier p {
    font-family: "FedraSansProBook", sans-serif;
}

.our-sommelier .sommeliers-img img {
    max-height: 685px;
}

.mystery-box .video .heading {
    text-align: left;
    text-transform: inherit;
    margin-bottom: 30px;
    color: #4e1e50;
}

.mystery-box .mystery-box-info {
    background: #FBFAF9;
}

.mystery-box .mystery-box-info h3 {
    text-align: left;
    text-transform: inherit;
    margin-bottom: 25px;
    color: #4e1e50;
}

.mystery-box .mystery-box-info p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0;
}

.mystery-box .mystery-box-info a.btn {
    margin: 20px 0;
}

.mystery-box .mystery-box-info img {
    width: 100%;
}

.mystery-box .hero {
    background: url("../../images/mystery-box-new.jpg") center no-repeat;
    background-size: cover;
    overflow: inherit;
}

.choose-mystery-box h2 {
    color: #4E1E50;
    margin-bottom: 75px;
    text-transform: inherit;
}

.mystery-box .mystery-box-info .video-holder iframe {
    width: 100%;
    height: 455px;
}

.choose-mystery-box .mystery-box-package.first .mystery-tittle {
    background: #75328A;
}

.choose-mystery-box .mystery-box-package.sec {
    margin-top: -23px;
}

.choose-mystery-box .mystery-box-package.sec .mystery-tittle {
    background: #65137E;
    padding: 25px 0 20px 0;
}

.choose-mystery-box .mystery-box-package.third .mystery-tittle {
    background: #471755;
}

.choose-mystery-box .mystery-box-package .mystery-tittle {
    color: #ffffff;
    text-align: center;
    padding: 15px 0;
}

.choose-mystery-box .mystery-box-package .mystery-tittle h3 {
    color: #fff;
    font-size: 19px;
    line-height: 30px;
}

.choose-mystery-box .mystery-box-package .mystery-tittle span.wine-type {
    font-family: 'FSerProBold', sans-serif;
    font-size: 19px;
}

.choose-mystery-box .mystery-box-package .price {
    color: #9d7a45;
    font-weight: 600;
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #D9D9D9;
    font-size: 23px;
}

.choose-mystery-box .mystery-box-package .info-box {
    border: 1px solid #D9D9D9;
    padding: 0 25px 25px 25px;
    background: #FBFAF9;
}

.choose-mystery-box .mystery-box-package.sec .info-box {
    background: #ffffff;
}

.choose-mystery-box .mystery-box-package ul {
    padding: 18px 0;
    min-height: 410px;
}

.choose-mystery-box .mystery-box-package ul li {
    position: relative;
    padding: 8px 0 8px 25px;
    font-size: 15px;
}

.choose-mystery-box .mystery-box-package ul li:before {
    content: "";
    background: url("../../images/mystery-box-check.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 13px;
}

.choose-mystery-box .mystery-box-package a.btn {
    display: block;
}

.choose-mystery-box .mystery-box-package form .form-group select {
    border: 1px solid #C2C2C2;
    color: #9C9C9C;
    font-size: 15px;
    background: #fff url("../../images/down-arrow-select.svg") right 0.75rem center/8px 10px no-repeat;
    background-position-x: 97%;
    background-size: 12px;
    background-position-y: 19px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

}

.choose-mystery-box .mystery-box-package form .form-group select:hover {
    border: 1px solid #9C9C9C;
}

.choose-mystery-box .mystery-box-package form .form-group select::-ms-expand {
    display: none;
}

.mystery-box .comments h3 {
    font-size: 24px;
    margin-bottom: 35px;
}

.comments .mystery-box-comments {
    font-family: "FSerProB", sans-serif;

}

.comments .mystery-box-comments .comment-block {
    background: #ffffff;
    padding: 35px 25px;
    height: 100%;

}

.comments .mystery-box-comments .comment-block ul {
    margin-bottom: 15px;
}

.comments .mystery-box-comments .comment-block ul li {
    display: inline-block;
}

.comments .mystery-box-comments .comment-block ul li img {
    width: 20px;
}


.comments .mystery-box-comments .comment-block p.comment-title {
    font-size: 15px;
    line-height: 24px;
    min-height: 42px;
    font-family: 'FedraSansPro', sans-serif;
}

.comments .mystery-box-comments .comment-block p {
    font-size: 15px;
    line-height: 24px;
    min-height: 121px;
    font-family: 'FedraSansPro', sans-serif;
}

.comments .mystery-box-comments .comment-block h5 {
    font-size: 17px;
    font-family: "FSerProB", sans-serif;
}

.mystery-box .comments a.btn {
    display: table;
    margin: 20px auto 0 auto;
}

.comments .mystery-box-comments.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 25px;
}

.comments .mystery-box-comments.owl-theme .owl-dots .owl-dot {
    outline: none;
}

.comments .mystery-box-comments.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 5px 4px;
    background: transparent;
    border: 2px solid #CBCACA;
}

.comments .mystery-box-comments.owl-theme .owl-dots .owl-dot.active span,
.comments .mystery-box-comments.owl-theme .owl-dots .owl-dot:hover span {
    background: transparent;
    border: 3px solid #502253;
}

/*.comments .mystery-box-comments.owl-theme .owl-dots .owl-dot.active span {*/
/*    border: 2px solid #502253;*/
/*}*/


/*seewines-magnum*/

.seewines-magnum {

}

.intro-seewines-magnum {
    background: url("../../images/magnum-intro-min.jpg") center no-repeat;
    background-size: cover;
}

.intro-seewines-magnum .intro-text {
    text-align: center;
    padding: 120px 0 80px 0;
}


.intro-seewines-magnum .intro-text h2:after {
    content: "";
    background: #873275 url("../../images/intro-eclipse.svg") center no-repeat;
    background-size: 77%;
    width: 34px;
    height: 34px;
    position: relative;
    right: 0;
    display: inline-block;
    bottom: 0;
    margin-bottom: -16px;
    border-radius: 100%;
    margin-left: -14px;
}


.intro-seewines-magnum .intro-text .heading {
    text-transform: inherit;
    font-size: 47px;
    line-height: 59px;
    margin-bottom: 0;
    letter-spacing: 1px;
    color: #ffffff;
    position: relative;
}

.intro-seewines-magnum .intro-text h2.heading {
    margin: 10px auto 20px auto;
    display: table;
}

.intro-seewines-magnum .intro-text h2.heading img {
    margin-left: -26px;
    margin-bottom: -26px;
    width: 41px;
}


.intro-seewines-magnum .intro-text p {
    font-size: 17px;
    line-height: 1.6;
    color: #ffffff;
    letter-spacing: 1px;
}

.intro-seewines-magnum .intro-text a {
    min-width: 180px;
}

.intro-seewines-magnum .intro-text a:hover {
    letter-spacing: 3px;
}


/*magnum-info*/

.magnum-info {

}

.magnum-info .heading {
    text-align: left;
    margin-bottom: 30px;
}

.magnum-info p {
    margin-bottom: 25px;
    line-height: 1.7;
    font-size: 14px;
}

.magnum-info img {

}

.seewines-magnum .benefits {
    padding: 0 0 50px 0;
    border-bottom: none;
}

.seewines-magnum .benefits .benefit h5 {
    border-color: #d7be7e;
}


/*magnum-offer*/

.magnum-offer {
    padding-bottom: 100px;
}

.magnum-offer .info-title {
    padding: 10px 20px;
}

.magnum-offer .info-title h5 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
}

.magnum-offer .info-title h5.level {
    text-align: left;
}

.magnum-offer .magnum-offer-cont {
    border: 1px solid #D9D9D9;
    background: #FBFAF9;
    border-bottom: none;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block {
    border-bottom: 1px solid #D9D9D9;
    padding: 30px 20px;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .person-type {
    color: #000;
    font-family: 'FSerProBold', sans-serif;
    font-size: 18px;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .person-type img {

}

.magnum-offer .magnum-offer-cont .magnum-offer-block .user-type-image {
    display: block;
    margin: 0 auto;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block span {
    display: block;
    color: #000000;
}

.magnum-offer .loan,
.magnum-offer .discount {
    width: 110px;
    text-align: center;
}

.magnum-offer h5.loan,
.magnum-offer h5.discount {
    text-align: center;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift {
    text-align: center;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift img {
    margin-right: 5px;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift span {
    display: inline-block;
    max-width: 100px;
    text-align: left;
    vertical-align: middle;
}

.seewines-magnum .checkbox label::before {
    margin-top: 2px;
}

.seewines-magnum .checkbox label::after {
    margin-top: 2px;
    left: 2px;
}

/*register-form*/

.register-form {
    background: url("../../images/Form-bgr.jpg") center no-repeat;
    background-size: cover;
    position: relative;
}

.register-form .heading {
    color: #ffffff;
    margin: 0 0 50px 0;
}

.register-form form button {
    display: table;
    margin: 0 auto;
    font-size: 13px;
    letter-spacing: 2px;
}

.register-form form .form-group {
    margin-bottom: 25px;
}

.register-form .form-control {
    height: 50px;
    border: 1px solid #C2C2C2;
}

.register-form form .form-group label {
    color: #ffffff;
}

.register-form .register-link {
    text-align: center;
    color: #ffffff;
    font-family: 'FedraSansPro', sans-serif;
    margin: 30px 0 0 0;
}

.register-form .register-link a {
    color: #ffffff;
    margin-left: 10px;
    text-transform: uppercase;
    text-decoration: underline;
}

.register-form .register-link a:hover {
    text-decoration: none;
    color: #9d7a45;;
}

.register-form form .invalid-feedback {
    margin-bottom: 5px
}

/*bellini-game*/

.bellini-intro {
    background: url("../../images/bellini-game-inro.jpg") center no-repeat;
    background-size: cover;
    padding: 25px 0;
}

.bellini-intro .banner-intro {

}

.bellini-intro .banner-intro img {

}

.bellini-intro .intro-bottle {
    position: relative;
}

.bellini-intro .intro-bottle img {
    position: absolute;
    z-index: 10;
    left: 50%;
    margin-left: -155px;
}

.how-to-participate {

}

.how-to-participate h3 {
    border-color: #9d7a45;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 25px;
    text-align: center;
}

.participate-steps {

}

.participate-steps .step {
    text-align: center;
    padding: 20px 0 55px 0;
    position: relative;
}

.participate-steps .step:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1px;
    margin-left: -1px;
    height: 40px;
    background: #BBBBBB;
}

.participate-steps .step:last-of-type:after {
    display: none;
}

.participate-steps .step span {
    color: #fff;
    background: #059EA5;
    display: table;
    margin: 0 auto 20px auto;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    font-size: 45px;
    font-family: "FedraSerifAPro", sans-serif;
    padding: 1px 0 0 0;

}

.participate-steps .step p {
    color: #242322;
    font-family: "FedraSansProBook", sans-serif;
    font-size: 19px;
    margin: 0 auto;
    padding: 0 58px;
}

.how-to-participate .info-btn {
    text-align: center;
}

.how-to-participate .info-btn .btn {
    letter-spacing: 2px;
    margin: 0 10px;
}

.bellini-game .from-us {
    padding: 0;
    float: right;
}

.bellini-game .from-us .form-container {
    background: #125F72;
    width: 460px;
}


.bellini-game .from-us .form-container h5 {
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 49px;
}

.bellini-game .from-us .form-container .promotion-period {
    font-family: "Fedra-SansB", sans-serif;
    font-size: 12px;
    margin-bottom: 20px;
    text-align: center;

}

.bellini-game .from-us .form-container .example {
    text-align: right;
    margin: -13px 0 20px 0;
    font-size: 14px;
    font-family: "Fedra-SansB", sans-serif;
}

.bellini-game .from-us .form-container label {
    font-family: "FedraSansProBook", sans-serif;
    padding-left: 28px;
    font-size: 15px;
}

.bellini-game .from-us .form-container .form-check {
    position: relative;
    margin-bottom: 20px;
    display: block;
}

.bellini-game .register-btn {
    background: #15CFD8;
    width: 100%;
    color: #fff;
    letter-spacing: 2px;
}

.bellini-game .register-btn:hover {
    background: #fff;
    color: #15CFD8;
    border-color: #15CFD8;
}

.bellini-game .checkbox {
    padding: 0;
}

.bellini-game .checkbox label::before,
.bellini-game .checkbox label::after {
    margin-top: 2px;
}

nav .menu .menu-item.bottom-menu {
    padding: 18px 40px 18px 15px;
    border-bottom: 1px solid #7a4e7b;
    color: #fff;
    background: #390E44;
    text-transform: uppercase;
    font-size: 15px;
}

nav .menu .bottom-menu span {
    display: block;
    text-transform: none;
    font-size: 15px;
    margin-top: 3px;
    line-height: 24px;
}

nav .menu .bottom-menu ul li {

}

nav .menu .bottom-menu ul li a {
    display: block;
    text-transform: none;
    font-size: 15px;
    margin-top: 3px;
    line-height: 24px;
    color: #fff;
}


.select-product {
    background-color: #E2F7F1;
    position: relative;
    padding-bottom: 5rem;
}

/*.select-product:after {*/
/*    content: "";*/
/*    background-color: #fff;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    width: 100%;*/
/*    height: 400px;*/
/*    display: block;*/
/*    z-index: 1;*/
/*}*/

.select-product .heading {
    font-family: Montserrat, Sans-serif;
    font-weight: 700;
    color: #135e71;
    font-size: 2.5rem;
    text-transform: capitalize;
    line-height: 3.2rem;
}

.select-product .product-item img {
    max-height: 70rem;
    width: initial;
    margin: 0 auto;
    display: block;
}

.select-product .product-item {
    padding: 50px 0;
}

.select-product .product-item .heading {
    color: #1F4853;
    margin-bottom: 1rem;
    text-align: left;
}

.select-product .product-item .product-meta {
    color: #4F5E63;
    font-family: "Roboto", sans-serif;
    font-size: 17px;
}

.select-product .product-item .product-info {
    color: #1F4853;
    font-family: "Roboto", sans-serif;
}

.select-product .product-item .product-info p {
    font-size: 15px;
}

.select-product .product-item .product-price {
    color: #5AA29F;
    font-size: 1.8rem;
    line-height: 1.5;
    margin: 0 0 1rem 0;
    font-family: "Roboto", sans-serif;
}

.select-product .product-item .product-old-price {
    color: #7b7b7b;
    text-decoration: line-through;
    font-size: 17px;

}

.select-product h5 {
    text-align: center;
    margin: 0 0 1rem 0;
    color: #515151;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}

.select-product .bot-info {
    margin: 0 0 1.5rem 0;
}

.select-product .bot-info img {
    height: 1.5rem;
    display: inline-block;
    width: initial;
    margin: 0 1rem 0 0;
    position: relative;
    top: -2px;
}

.select-product .bot-info span {
    color: #515151;
}

.select-product .bot-info .bot {
    background-color: #fff;
    padding: 0.8rem 3rem;
    text-align: center;
    margin: 0 0 .5rem 0;
    border: 1px solid #fff;
    cursor: pointer;
}

.select-product .bot-info .bot:hover {
    border-color: #c0d1cc;
}

.select-product .bot-info .bot.selected {
    border-color: #36a19a;
}

.select-product .qty-holder {
    border: 1px solid #B8C9C4;
    position: relative;
}

.select-product .qty-holder button {
    outline: none;
    border: none;
    background: transparent;
}

.select-product .qty-holder .minus {
    position: absolute;
    left: 0;
    width: 3.5rem;
    height: 49px;
}

.select-product .qty-holder .plus {
    position: absolute;
    right: 0;
    width: 3.5rem;
    height: 49px;
}

.select-product .qty-holder input {
    border: 0;
    background: none;
    width: 100%;
    text-align: center;
    height: 49px;
}

.select-product .cta {
    display: block;
    width: 100%;
    margin: 1.4rem 0 0 0;
    background-color: #36a19a;
    border: 2px solid #36a19a;
    color: #fff;
    padding: 0 1rem;
    height: 51px;
    line-height: 45px;
}

.select-product .cta:hover {
    color: #fff;
    background-color: #308e88;
    border-color: #308e88;
}

.select-product .owl-thumbs {
    text-align: center;
    margin: 0 0 3rem 0;
}

.select-product .owl-thumb-item {
    margin: 0 1.7rem;
    opacity: .2;
    border: none;
    outline: none;
}

.select-product .owl-thumb-item.active {
    opacity: 1;
}

.select-product .owl-thumb-item img {
    height: 10rem;
}


.select-product .owl-carousel .owl-nav button .fa {
    font-size: 45px;
}

.select-product .owl-carousel .owl-nav button {
    margin-top: 0;
}

.select-product .owl-carousel .owl-nav button.owl-next {
    right: -40px;
}

.select-product .owl-carousel .owl-nav button.owl-prev {
    left: -40px;
}

/*wine-tests-landing*/

.intro.intro-wine-tests h2 {
    color: #fff;
    max-width: 747px;
    margin: 0 auto 30px auto;
}

.intro.intro-wine-tests .owl-nav {
    display: none;
}

.intro.intro-wine-tests .container-fluid {
    padding: 0;
    margin: 0;
}

.intro.intro-wine-tests .owl-dots {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 30px;
}

.intro.intro-wine-tests .owl-dots button {
    outline: none;
}

.intro.intro-wine-tests .owl-dots button span {
    width: 15px;
    height: 15px;
    margin: 5px 5px;
    background: #939088;
}

.intro.intro-wine-tests .owl-dots button.active span {
    background: transparent;
    border: 3px solid #fff;
}

.intro.intro-wine-tests .carousel-caption {
    /*bottom: 50%;*/
    /*transform: translateY(50%);*/
    position: static;
    padding: 140px 0;
}

.intro.intro-wine-tests .item {
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.wine-tests-page .heading-2 {
    border-color: #9d7a45;
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.surprises .heading-2 {
    text-align: center;
}

.wine-tests-page p {
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 1.7;
}

.special-holiday {
    background: #FBFAF9;
}

.special-holiday img {
    margin-top: -65px;
}

.wine-tests-page .benefits {
    border: none;
}

.wine-tests-page .benefits .benefit p {
    padding: 0 25px;
}

.tasting-sommelier {
    background: #FBFAF9;

}

.tasting-sommelier .person-name .heading {
    text-align: left;
    text-transform: inherit;
    margin-bottom: 5px;
    color: #4e1e50;
    font-family: "FedraSerifAPro", sans-serif;
    font-weight: 500;
}

.tasting-sommelier .person-name span {
    color: #000;
    display: block;
    text-transform: uppercase;
    font-size: 15px;
}

.wine-tests-page .where-are-we {
    background: url("../../images/where-are-we2.jpg") center center no-repeat;
    background-size: cover;
}

.inquiry-form {
    background: url("../../images/form-bgr-holiday.jpg") center center no-repeat;
    background-size: cover;
}

.inquiry-form form .form-group {
    margin-bottom: 25px;
}

.inquiry-form form .form-group label {
    width: 100%;
}

.inquiry-form .form-control {
    height: 50px;
}

.inquiry-form input[type="date"],
.inquiry-form input[type="time"] {
    padding-left: 40px;
    padding-right: 5px;
}

.inquiry-form .form-group.date {
    position: relative;
    background-size: 18px 19px !important;
    background-position-x: 14px !important;
    background-position-y: center !important;
}

.inquiry-form .form-group.time {
    position: relative;
    background-size: 18px 18px !important;
    background-position-x: 14px !important;
    background-position-y: center !important;
}

.inquiry-form .form-group.date input,
.inquiry-form .form-group.time input {
    background: transparent;
}

/*.inquiry-form .form-group.date:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    background: url("../../images/icons/calendar2.svg") no-repeat;*/
/*    background-size: 100%;*/
/*    width: 18px;*/
/*    height: 19px;*/
/*    margin-left: 25px;*/
/*    z-index: 0;*/

/*}*/

.inquiry-form input[type="date"]::-webkit-calendar-picker-indicator,
.inquiry-form input[type="time"]::-webkit-calendar-picker-indicator {
    right: auto;
    left: 0;
    position: absolute;
    /* background: url("../../images/icons/calendar2.svg") no-repeat;*/
    /* background-size: 100%;*/
    /* width: 18px;*/
    /* height: 19px;*/
    width: 100%;
    height: 100%;
    background: #fff;
    display: block;
    opacity: 0;

}

.inquiry-form label {
    margin-bottom: 0;
}

.inquiry-form .invalid-feedback {
    margin-bottom: 4px;
}


/*.inquiry-form input[type="time"]::-webkit-calendar-picker-indicator {*/
/*    background: url("../../images/icons/time.svg") no-repeat;*/
/*    background-size: 100%;*/
/*    width: 18px;*/
/*    height: 18px;*/
/*}*/


.inquiry-form input[type="date"],
.inquiry-form input[type="time"],
.inquiry-form .form-control::placeholder {
    color: #9C9C9C;

}

.inquiry-form select {
    color: #9C9C9C;
}

.inquiry-form option:not(:first-of-type) {
    color: #495057;
}

.wine-tests-page .faq .question {
    font-size: 16px;
    line-height: 25px;
}

.wine-tests-page .faq .answer .answer-content {
    color: #6A6665;
    font-size: 15px;
    line-height: 25px;
    opacity: 1;
}

.profile-address .profile-content .magnum-offer {
    width: 100%;
    overflow-x: auto;
}

.profile-address .profile-content .magnum-offer .magnum-offer-cont {
    min-width: 1100px;
}

/*SUBSCRIBE*/

.subscribe-land {
    background: url("../../images/seewines-october-bgr.png") center no-repeat, #F3EDFE;
    background-size: cover;
    position: relative;
    min-height: 1250px;
}

.subscribe-land .left-shape {
    position: absolute;
    left: 0;
    top: 0;
}

.subscribe-land .right-shape {
    position: absolute;
    right: 0;
    bottom: 30%;
}

.subscribe-land .heading {
    font-size: 38px;
    line-height: 57px;
}

.subscribe-land p {
    font-family: "FedraSansProBook", sans-serif;
    margin-bottom: 25px;
    text-align: center;
}

.subscribe-steps .subscribe-step span {
    display: block;
    background: #E81E25;
    width: 50px;
    height: 50px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    border-radius: 100px;
    font-size: 24px;
    font-family: "FedraSansProBook", sans-serif;
}

.subscribe-steps .subscribe-step .step-text {
    font-family: "FedraSansProBook", sans-serif;
    font-size: 16px;
    color: #000000;
}

.subscribe-land form input {
    border-color: #AFAFAF;;
}

.subscribe-land form .form-group.checkbox {
    display: table;
    margin: 0 auto;
}

.subscribe-land form .form-group.checkbox label:before,
.subscribe-land form .form-group.checkbox label:after {
    margin-top: 2px;
}

.subscribe-land p {
    font-size: 15px;
}

.subscribe-land .bottom-desc {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    margin-bottom: 12px;
    display: block;
    width: 100%;
}

.mystery-box .box-for-us .heading {
    text-transform: inherit;
    color: #4e1e50;
    line-height: 38px;
}

.mystery-box .box-for-us .box-block {
    position: relative;
}

.mystery-box .box-for-us .box-block h5 {
    position: absolute;
    color: #fff;
    top: 0;
    margin-top: 50px;
    width: 100%;
    left: 0;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    padding: 0 55px;
}

header.fixed.christmas-header ~ .page {
    padding-top: 0 !important;
}

.christmas-header {
    position: relative !important;
    background: transparent !important;
    border: none;
    display: block !important;
    padding: 35px 0 25px 0 !important;
}

.christmas-header .logo img {
    height: 40px;
}

.christmas-header .christmas-nav {
    text-align: right;
}

.christmas-header .christmas-nav ul {

}

.christmas-header .christmas-nav ul li {

}

.christmas-header .christmas-nav ul li a {
    color: #4E1E50;
    text-transform: uppercase;
    font-style: italic;
    font-size: 17px;
    letter-spacing: 1px;
}

.christmas-header .christmas-nav ul li a:hover {
    opacity: 0.6;
}

.christmas-header .christmas-nav ul li a img {
    margin-top: -22px;
    margin-left: 4px;
    height: 45px;
}

.christmas-landing {
    background: url("../../images/christmas-bgr1.jpg") center top no-repeat;
    background-size: cover;
    position: relative;
    min-height: 940px;
    margin-top: -104px;

    transition: all .8s;
}

.christmas-landing.questionnaire {
    background: url("../../images/christmas-bgr2.jpg") center top no-repeat;
    background-size: cover;
}

.christmas-landing .cont {
    padding-top: 180px;
}

.christmas-landing .heading {
    color: #4E1E50;
    font-family: 'El Messiri', sans-serif;
    font-size: 48px;
    line-height: 58px;
    text-transform: inherit;
    margin: 0 0 40px 0;
    font-weight: bold;
}

.christmas-landing .heading-2 {
    color: #4E1E50;
    font-family: 'El Messiri', sans-serif;
    font-size: 90px;
    line-height: 100px;
    text-transform: inherit;
    margin: 0 0 45px 0;
    font-weight: bold;

}

.christmas-landing .btn {
    font-family: "FedraSansProBook", sans-serif;
    font-size: 15px;
    min-width: 280px;
    min-height: 50px;
    line-height: 49px;
}

.christmas-landing .btn img {
    height: 14px;
    margin-left: 10px;
}

.christmas-landing .btn img.hover {
    display: none;
}

.christmas-landing .btn:hover img.hover {
    display: inline-block;
}

.christmas-landing .btn:hover img.show-img {
    display: none;
}

.christmas-landing .heading-2 span {
    display: block;
    font-size: 108px;
    padding: 28px 0;
    line-height: 92px;
    color: #9d7a45;
    text-transform: capitalize;
}

.christmas-footer .logo img {
    height: 35px;
    margin-bottom: 10px;
}

.christmas-landing .block-title {
    text-align: center;
    position: relative;
    top: 36px;
    color: #4E1E50;
    font-family: 'El Messiri', sans-serif;
    font-weight: bold;
    font-size: 30px;
}

.christmas-landing .block-title span {
    color: #9d7a45;
}

.christmas-landing.questionnaire form .form-group label {
    border: 1px solid #D4C3BA;
    width: 100%;
    text-align: center;
    color: #000000;
    font-family: "FedraSansProBook", sans-serif;
    font-size: 16px;
    padding: 30px 15px;
    background: #fff;
    position: relative;
    transition: all .6s;
}

.christmas-landing.questionnaire form .form-group label:hover {
    border-color: #9d7a45;
    box-shadow: 0 10px 14px 0 rgba(227, 6, 19, 0.13);
    -webkit-box-shadow: 0 10px 14px 0 rgba(227, 6, 19, 0.13);
    -moz-box-shadow: 0 10px 14px 0 rgba(227, 6, 19, 0.13);
}

.christmas-landing.questionnaire form .form-group label .checked-img {
    display: none;
}

.christmas-landing.questionnaire form .form-group input {
    opacity: 0;
    position: absolute;
}

.christmas-landing.questionnaire form .form-group label.active {
    border-color: #9d7a45;
    background: #9d7a45;
    color: #fff;
}

.christmas-landing.questionnaire form .form-group label.active .show-img {
    display: none;
}

.christmas-landing.questionnaire form .form-group label.active .checked-img {
    display: inline-block;
}

.christmas-landing.questionnaire form .form-group label .quest-img {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 23px;
}

.christmas-landing.questionnaire form .form-group label img {
    width: 55px;
}

/*.christmas-landing.questionnaire form label.checkbox-label input:checked ~ .checkbox-label {*/
/*    background: green !important;*/
/*}*/

/*.christmas-landing.questionnaire form label.checkbox-label input [type="checkbox"]:checked + label*/
/*.christmas-landing.questionnaire form label.checkbox-label input [type="checkbox"]:not(:checked) + label {*/
/*    background: green !important;*/
/*}*/

.christmas-landing .questionnaire-pagination {
    margin-top: 35px;
}

.christmas-landing .questionnaire-pagination li {
    display: inline-block;
}

.christmas-landing .questionnaire-pagination li a {
    display: block;
    border: 1px solid #D4C3BA;
    width: 60px;
    height: 60px;
    text-align: center;
    margin: 0 5px;
    line-height: 55px;
    background: #fff;
    transition: all .6s;
}

.christmas-landing .questionnaire-pagination li.disabled a {
    opacity: 0.32;
}

.christmas-landing .questionnaire-pagination li.disabled a:hover {
    border: 1px solid #D4C3BA;
    box-shadow: none;
}

.christmas-landing .questionnaire-pagination li.disabled a:hover img {
    transform: none !important;
    opacity: 0.44;
}

.christmas-landing .questionnaire-pagination li a img {
    opacity: 0.44;
    transition: all .6s;
}

.christmas-landing .questionnaire-pagination li a:hover {
    border-color: #9d7a45;
    box-shadow: 0 10px 14px 0 rgba(227, 6, 19, 0.13);
    -webkit-box-shadow: 0 10px 14px 0 rgba(227, 6, 19, 0.13);
    -moz-box-shadow: 0 10px 14px 0 rgba(227, 6, 19, 0.13);
}

.christmas-landing .questionnaire-pagination li.next a:hover img {
    transform: translateX(5px);
    opacity: 1;
}

.christmas-landing .questionnaire-pagination li.prev a:hover img {
    transform: translateX(-5px);
}

.christmas-landing .questionnaire-pagination li a.disabled {

}


/* new css */
#nav-toggle {
    color: #fff;
    font-family: 'Sofia Sans', sans-serif;
    text-transform: uppercase;
    background-color: transparent;
    border: 0;
    outline: none;
}

#nav-toggle .lab {
    font-size: 16px;
    line-height: 16px;
}


.home-about .heading {
    width: 60%;
    margin: 0 auto 50px;
    display: table;
}

.logo-segment {
    margin: 0 auto 15px;
    display: block;
    height: 40px;
}

.home-about .special-text {
    color: #262020;
    font-size: 24px;
    line-height: 1.5;
    font-style: italic;
    font-family: 'Moderato E4 L';
}

.home-about a {
    text-transform: uppercase;
    font-family: 'Sofia Sans', sans-serif;
    letter-spacing: 1px;
    font-size: 15px;
    line-height: 16px;
    position: relative;
    color: #262020;
}

.home-about a:hover {
    color: #9d7a45;
}

.home-about a:hover:after {
    filter: brightness(0) saturate(100%) invert(44%) sepia(69%) saturate(316%) hue-rotate(357deg) brightness(97%) contrast(88%);
}

.home-about a:after {
    content: "";
    height: 12px;
    width: 12px;
    position: absolute;
    top: 1px;
    right: -22px;
    background: url('../../images/icons/partner-arrow.svg') center center no-repeat;
    background-size: 12px;
}

.last-projects {
    background-color: #F5F3EF;
}


.owl-fix-pos {
    padding-left: 15%;
    overflow: hidden;
    position: relative;
}

.page-hide {
    display: none;
}

.mega-menu .lang-widget {
    text-transform: uppercase;
    padding: 0 0 30px 0;
    color: #fff;
}

.mega-menu .lang-widget div {
    display: inline-block;
}

.mega-menu .lang-widget .lab {
    margin-right: 5px;
}

.mega-menu .lang-widget a {
    color: #fff;
    font-weight: bold;
}

.suggested-products {
    background: #F5F3EF;
}

.suggested-products .products-list .product-item .product-info {
    background: transparent;
}

/*.user-lang {*/
/*    font-size: 0;*/
/*    top: 2px;*/
/*}*/

/*.user-lang li {*/
/*    display: inline-block;*/
/*    padding: 0 7px;*/
/*    position: relative;*/
/*}*/

/*.user-lang li:before {*/
/*    content: "";*/
/*    height: 10px;*/
/*    width: 1px;*/
/*    background-color: #707070;*/
/*    position: absolute;*/
/*    top: 2px;*/
/*    left: -1px;*/
/*}*/

/*.user-lang li:first-of-type:before {*/
/*    display: none;*/
/*}*/

/*.user-lang li a {*/
/*    display: block;*/
/*    color: #707070;*/
/*    font-size: 16px;*/
/*    line-height: 1;*/
/*}*/

/*.user-lang li a:hover {*/
/*    color: #fff;*/
/*}*/

/*.user-lang li.active a {*/
/*    color: #fff;*/
/*}*/

.owl-events .owl-item,
.owl-projects .owl-item {
    opacity: .1;
}

.owl-events .owl-item.active,
.owl-projects .owl-item.active,
.owl-special-products .owl-item.active
{
    opacity: 1;
}

.owl-events .owl-item img,
.owl-projects .owl-item img,
.owl-special-products .owl-item img
{
    -webkit-transition: all .4s;
    transition: all .4s;
}

.owl-events .owl-item:hover img,
.owl-projects .owl-item:hover img
{
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}


.team-page .page-intro {
    background: url("../../images/team-intro.png") center center no-repeat;
    padding: 250px 0 150px 0;
    background-size: cover;
    position: relative;
}

.categories .page-intro {
    padding: 250px 0 150px 0;
    background: url("../../images/categories-intro.jpg") center center no-repeat;
    background-size: cover;
    position: relative;
}

.blog-list .page-intro {
    padding: 250px 0 150px 0;
    background: url("../../images/blog-intro.jpg") center center no-repeat;
    background-size: cover;
    position: relative;
}

.partners .page-intro {
    padding: 250px 0 150px 0;
    background: url("../../images/partners-intro.jpg") center center no-repeat;
    background-size: cover;
    position: relative;
}

/*.distillery .page-intro {*/
/*    padding: 250px 0 150px 0;*/
/*    background: url("../../images/distillery-intro.jpg") center center no-repeat;*/
/*    background-size: cover;*/
/*    position: relative;*/
/*}*/

.contacts .page-intro {
    padding: 250px 0 150px 0;
    background: url("../../images/contacts-intro.jpg") center center no-repeat;
    z-index: 2;
    background-size: cover;
    position: relative;
}


.about-us .page-intro {
    padding: 250px 0 150px 0;
    background: url("../../images/about-intro.jpg") center center no-repeat;
    background-size: cover;
    position: relative;
}

.page-intro h1 {
    color: #fff;
    font-size: 100px;
    line-height: 100px;
    font-family: 'Moderato G7 L', sans-serif;
    font-weight: lighter;
    text-align: center;
    letter-spacing: 15px;
    text-transform: uppercase;
    position: relative;
}

.page-intro h1:after {
    content: "";
    background: url("../../images/icons/heading-arrow-down.svg") center center no-repeat;
    background-size: 20px;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: -40px;
    left: 50%;
    margin-left: -10px;
}


.distillery .page-intro {
    padding: 250px 0 150px 0;
    background: url("../../images/vineyards-bgr.jpg") center center no-repeat;
    background-size: cover;
    position: relative;
}

.information {
    text-align: center;
}

.information p {
    font-family: 'Sofia Sans', sans-serif;
}


.partners .section-01:after {
    background: url("../../images/partner-info.jpg") center center no-repeat;
}


.distillery .section-01 {
    background: url("../../images/vision-bgr.jpg") center center no-repeat;
}

.distillery .section-01:after {
    background: url("../../images/distillery-location.jpg") center center no-repeat;
}

.distillery .section-02 {
    position: relative;
    background: url("../../images/goal-bgr.jpg") center center no-repeat;
}

.distillery .section-02:after {
    background: url("../../images/distillery-equipment.jpg") center center no-repeat;
}

.section-01 {
    position: relative;
    background: url("../../images/vision-bgr.jpg") center center no-repeat;
    text-align: center;
}

.section-01:after {
    content: "";
    background: url("../../images/vision-01.jpg") center center no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
}

.section-01 .text-left-side {
    padding: 0 200px 0 0;
}

.section-01 .section-01-middle {
    position: relative;
    z-index: 2;
    bottom: -260px;
    left: -170px;
}


.section-02 {
    position: relative;
    background: url("../../images/goal-bgr.jpg") center center no-repeat;
    text-align: center;
}

.section-02:after {
    content: "";
    background: url("../../images/goal-01.jpg") center center no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}

.section-02 .text-right-side {
    padding: 0 0 0 200px;
}


.history {
    color: #fff;
    text-align: center;
    background: url("../../images/history-bgr.jpg") center center no-repeat;
}

.history .heading {
    color: #fff;
}

.history .sub-heading {
    color: #fff;
}


.team {
    text-align: center;
    background: url("../../images/team-bgr.jpg") center top no-repeat;
    background-size: cover;
}

.team p {
    width: 60%;
    margin: 0 auto;
    display: table;
    min-height: 700px;
}


.timeline {
    margin-top: -30px;
}

.timeline .year {
    font-family: 'Moderato E4 L';
    font-size: 70px;
    line-height: 70px;
    color: #B5B5B5;
    margin: 0 0 30px 0;
}

.timeline .story {
    font-size: 17px;
    line-height: 1.5;
}

.timeline .timeline-stage .timeline-image {
    min-height: 700px;
}

.timeline .timeline-stage .timeline-nav {
    text-align: right;
    font-size: 0;
}

.timeline .timeline-stage .timeline-nav li {
    display: block;
}

.timeline .timeline-stage .timeline-nav li:hover a,
.timeline .timeline-stage .timeline-nav li.active a {
    opacity: 1;
}

.timeline .timeline-stage .timeline-nav li:hover a {
    color: #B89D65;
    border-color: #B89D65;
}

.timeline .timeline-stage .timeline-nav li a {
    font-family: 'Moderato G5 L';
    display: block;
    padding: 15px 15px 15px 0;
    border-right: 2px solid #707070;
    font-size: 20px;
    line-height: 20px;
    opacity: .16;
    color: #262020;
}


.vineyards .page-intro {
    padding: 250px 0 150px 0;
    background: url("../../images/vineyards-intro-bgr.jpg") center center no-repeat;
    background-size: cover;
}

.vineyards .vineyards-map {
    width: 70%;
    display: block;
    margin: 50px auto 0;
}

.vineyards .plantations-top {
    background: url("../../images/plantations-01-bgr.jpg") center center no-repeat;
    padding: 150px 0;
    background-size: cover;
}
.vineyards .plantations-top.siva-reka {
    background: url("../../images/siva_reka_bgr.jpg") center center no-repeat;
}

.vineyards .plantations-top h3 {
    font-size: 46px;
    line-height: 46px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
}


.vineyards .plantations .plantation-text {
    padding: 60px 80px 60px 0;
    text-align: center;
}

.vineyards .plantations .plantation-text p,
.vineyards .plantations .plantation-small-text p {
    margin: 0;
}

.vineyards .plantations .plantation-small-text {
    padding: 0 80px 0 0;
    text-align: center;
}

.vineyards .plantations .plantation-small-text h5 {
    font-family: 'Moderato E4 L';
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 10px 0;
}

.plantation-info {
    padding: 50px 0;
    background: url("../../images/plantation-info.jpg") center center no-repeat;
    background-size: cover;
    overflow: hidden;
}

.plantation-info .lab {
    font-family: 'Moderato G5 L';
    color: #898787;
    font-size: 20px;
    line-height: 26px;
    font-style: italic;
}

.plantation-info .val {
    font-family: 'Moderato G5 L';
    font-size: 30px;
    line-height: 30px;
}

.plantation-info img {
    height: 50px;
}

.plantation-work {
    position: relative;
    padding-bottom: 230px;
}

.lozq-plantation-work {
    background-color: #F5F3EF;
}

.plantation-work.distillery-plant {
    padding-bottom: 100px;
}

.plantation-work .new-imgs {
    max-height: 230px;
}

.plantation-work .plantation-work-img {
    padding: 0 100px 0 0;
}

.plantation-work h2 {
    font-family: 'Moderato E4 L';
    font-size: 30px;
    line-height: 36px;
    margin: 80px 0 20px 0;
    font-style: italic;
}

.plantation-work .img-bottom {
    position: absolute;
    bottom: -110px;
    right: 0;
    width: 70%;
}

.plantation-work .plantation-work-text {
    padding: 0 80px 0 0;
    text-align: center;
}


.partners-list {
    background-color: #F5F3EF;
}

.partner {
    padding: 40px 20px 15px 20px;
    background-color: #fff;
    margin: 0 0 30px 0;
}

.partner .partner-logo {
    width: 150px;
    margin: 0 0 15px 0;
}

.partner .partner-action {
    padding: 15px 0 0 0;
    border-top: 1px solid #d9d9d9;
}

.partner .partner-title {
    font-size: 28px;
    line-height: 28px;
    font-family: 'Moderato G7 L';
    margin: 0 0 30px 0;
}

.partner .partner-address-title {
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: 'Moderato G5 L';
    letter-spacing: 1px;
    margin: 0 0 10px 0;
}

.partner .partner-address-title img,
.partner .partner-action img {
    vertical-align: sub;
    margin: 0 8px 0 0;
    height: 15px;
}

.partner .partner-action {
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: 'Moderato G5 L';
    letter-spacing: 1px;
    table-layout: fixed;
}

.partner .partner-action a {
    display: table-cell;
    width: 2%;
}

.partner .partner-address {
    font-size: 20px;
    line-height: 26px;
    font-family: 'Sofia Sans', sans-serif;
    margin: 0 0 30px 0;
    min-height: 70px;
}

.partner h2 {
    font-family: 'Sofia Sans', sans-serif;
    font-weight: 600;
    color: #262020;
    min-height: 56px;
}

.partner .partner-text {
    font-size: 17px;
    line-height: 26px;
    font-family: 'Sofia Sans', sans-serif;
    margin: 0 0 20px 0;
    min-height: 104px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.partner .partner-text.long {
    display: block;
    min-height: auto;
    overflow: visible;
}

.partner picture {
    font-size: 0;
}


.locations {
    background-color: #F5F3EF;
}

.gmap {
    margin: 0 0 50px 0;
}

.partners-online .partner-shop {
    padding: 20px 25px;
    margin: 0 0 30px 0;
    background-color: #F5F3EF;

    height: 130px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.partners-online .partner-shop .partner-holder {
    display: flex;
    align-items: center;
}

.partners-online .partner-shop .partner-logo {
    padding: 0 50px 0 0;
}

.partners-online .partner-shop .partner-arrow {
    width: 20px;
}

.search-location {
    margin: 0 0 50px 0;
}

.search-location form {
    position: relative;
}

.search-location form input {
    width: 100%;
    height: 60px;
    border: 1px solid #B9B9B9;
    padding: 0 25px;
}

.search-location form button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #B89D65;
    outline: none;
    padding: 15px 20px;
    height: 100%;
    border: 0;
}

.search-location form button img {
    height: 20px;
}

.intro-text {
    text-align: center;
}

.intro-text h1 {
    font-size: 120px;
    line-height: 120px;
    text-transform: uppercase;
    font-family: 'Moderato G7 L';
    letter-spacing: 20px;
}

.intro-text p {
    font-family: 'Moderato E4 L';
    font-size: 26px;
    line-height: 32px;
    font-style: italic;
    width: 70%;
    margin: 0 auto;
}

.lp {
    background: url("../../images/lp-bgr.jpg") center center no-repeat;
    background-size: cover;
    min-height: 900px;
}

.lp-product {
    text-align: center;
    display: block;
    padding: 100px 0;
    position: relative;
}

.lp-product:hover .lp-product-name {
    color: #B89D65;
}

.lp-product .lp-product-name {
    color: #fff;
    font-size: 60px;
    line-height: 60px;
    font-family: 'Moderato G5 L';
    text-transform: uppercase;
    margin: 15px 0 0 0;
    position: relative;

    -webkit-transition: all .4s;
    transition: all .4s;
}

.lp-product .lp-product-name:after {
    content: "";
    background: url("../../images/icons/lp-arrow.svg") center center no-repeat;
    background-size: 20px;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-bottom: -50px;

    -webkit-transition: all .4s;
    transition: all .4s;

    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
}

.lp-product:hover .lp-product-name:after {
    margin-bottom: -40px;
    background: url("../../images/icons/lp-color-arrow.svg") center center no-repeat;
    background-size: 20px;
}

.lp-product:hover img {
    transform: scale(1.2);
}

.lp-product:hover .lp-product-name {
    margin: 40px 0 0 0;
}

.lp-product img {
    max-height: 400px;

    -webkit-transition: all .4s;
    transition: all .4s;
}

.read-more:hover, .read-less:hover {
    color: #9d7a45;
}

.read-more, .read-less {
    color: #1C1B1B;
    font-family: 'Sofia Sans', sans-serif;
    text-decoration: underline;
    margin: 0;
    display: table;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    cursor: pointer;
}

.characteristics {
    background-color: #F5F3EF;
}

.characteristics .characteristic {
    background-color: #fff;
    padding: 15px 10px;
    margin: 0 0 30px 0;
    min-height: 130px;
}

.characteristics .characteristic .characteristic-icon img {
    margin: 0 10px 0 0;
    height: 30px;
}

.characteristics .characteristic .lab {
    font-size: 16px;
    line-height: 20px;
    color: #5D5959;
    font-family: 'Sofia Sans', sans-serif;
}

.characteristics .characteristic .val {
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    font-family: 'Moderato G5 L';
}


.go-to-modal .modal-dialog {
    text-align: center;
    max-width: 680px;
}

.go-to-modal .modal-dialog .modal-body {
    padding: 40px 10px;
}

.go-to-modal .modal-dialog img {
    height: 60px;
}

.go-to-modal .modal-dialog .lab {
    color: #A4A4A4;
    margin: 15px 0 20px 0;
    font-family: 'Sofia Sans', sans-serif;
}

.go-to-modal .go-to-nav button {
    margin: 0 5px;
}

.modal .close {
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 36px;
    outline: none;

}

.footer-social li {
    display: inline-block;
    margin-right: 8px;
}

.footer-social li a {
    display: block;
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    background-color: #fff;
    color: #161615;
    border-radius: 100%;
}


.carousel-indicators {
    display: block !important;;
    top: 50% !important;;
    right: 3%;
    left: initial !important;;
    bottom: initial !important;;
    margin: 0 !important;
}

.carousel-indicators li {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/icons/dot.svg");
    background-color: transparent;
    background-size: 8px;
    margin: 7px 0;
    border: 0;
    height: 8px;
    width: 8px;
    position: relative;
    left: 5px;
    opacity: 1;

    -webkit-transition: all .6s;
    transition: all .6s;
}

.carousel-indicators li:hover {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/icons/dot-hover.svg");
    background-color: transparent;
    background-size: 10px;
    border: 0;
    height: 8px;
    width: 8px;
    left: 5px;
}

.carousel-indicators li.active {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../images/icons/dot-active.svg");
    background-color: transparent;
    background-size: 18px;
    border: 0;
    height: 18px;
    width: 18px;
    left: 0;
}

.scroll-bottom {
    background: url('../../images/icons/scroll-bottom.svg') center no-repeat;
    background-size: 15px;
    position: absolute;
    bottom: -15px;
    left: 50%;
    width: 15px;
    height: 20px;

    -ms-transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
}

.scroll-bottom:hover {
    bottom: -20px;
}

.partner-contacts {
    background: url("../../images/partner-contacts.jpg") center center no-repeat;
    background-size: cover;
}

.contacts-holder {
    margin: -47px 0 0 0;
}


.owl-events .owl-nav.disabled,
.owl-projects .owl-nav.disabled,
.owl-special-products .owl-nav.disabled
{
    display: block !important;
}

.owl-events .owl-nav button.owl-prev:hover,
.owl-events .owl-nav button.owl-next:hover,
.owl-projects .owl-nav button.owl-prev:hover,
.owl-projects .owl-nav button.owl-next:hover,
.owl-special-products .owl-nav button.owl-prev:hover,
.owl-special-products .owl-nav button.owl-next:hover
{
    opacity: .7;
    background-color: #fff;
}

.owl-projects .owl-nav,
.owl-events .owl-nav,
.owl-special-products .owl-nav
{
    width: 100%;
}

.owl-events .owl-nav button.owl-next,
.owl-projects .owl-nav button.owl-next,
.owl-special-products .owl-nav button.owl-next
{
    position: absolute;
    top: 36%;
    right: 5%;
    background-color: #fff;
    border-radius: 100%;
    width: 50px;
    height: 50px;

    box-shadow: 0px 2px 3px #a6a6a6;

    -webkit-transition: all .4s;
    transition: all .4s;
}

.owl-events .owl-nav button.owl-prev,
.owl-projects .owl-nav button.owl-prev,
.owl-special-products .owl-nav button.owl-prev
{
    position: absolute;
    top: 36%;
    left: 1%;
    background-color: #fff;
    border-radius: 100%;
    width: 50px;
    height: 50px;

    box-shadow: 0px 2px 3px #a6a6a6;

    -webkit-transition: all .4s;
    transition: all .4s;
}
.owl-special-products .owl-nav button.owl-next{
    right: 0;
}
.owl-special-products .owl-nav button.owl-prev{
    left: 0;
}
.owl-events .owl-nav button.owl-prev img,
.owl-projects .owl-nav button.owl-prev img,
.owl-special-products .owl-nav button.owl-prev img
{
    transform: rotate(-180deg);
}

.owl-events .owl-nav button.owl-prev img,
.owl-projects .owl-nav button.owl-next img,
.owl-special-products .owl-nav button.owl-next img
{
    width: 20px;
}

.order-num-val {
    text-align: right;
    font-weight: bold;
}

.product .product-desc:not(.full) p {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.product .product-desc:not(.full) p:not(:first-of-type) {
    display: none;
}

.product .product-desc.full {
    display: block;
}

.product .product-desc.full + .read-more {
    display: none;
}

.partner_box_buttons_wrapper {
    border-top: 2px solid #f0f0f0;
    margin-top: auto;
    display: flex;
    flex-flow: row;
}

.partner_wrapper_box {
    background: white;
    aspect-ratio: 1;
    display: flex;
    flex-flow: column;
}

.partner_wrapper_box .partner_box_title {
    font-size: 26px;
    font-weight: bold;
}

.partner_wrapper_box p {
    font-family: 'Sofia Sans', sans-serif;
}

.partner_box_address_title {
    text-transform: uppercase;
    font-weight: bold;
    margin: 1em 0 0;
}

.partner_box_address {
    margin: 0;
}

.page.arkana .full-background-banner {
    background: url("../../images/background-blue.jpg");
}

.page.arkana .single_bottle .bottle_title {
    color: white;
    font-size: 3em;
    text-transform: uppercase;
}

.page.arkana .single_bottle img {
    transform: scale(0.5);
    transition: transform 0.5s;
}

.page.arkana .single_bottle:hover img {
    transform: scale(1);
}

.page.arkana .single_bottle:hover .bottle_title,
.page.arkana .single_bottle:hover:after {
    color: #d7be7e;
}

.page.arkana .single_bottle:after {
    content: '→';
    position: absolute;
    color: white;
    font-size: 2em;
}

.single_product_feature {
    background: white;
    display: flex;
}

.gm-style-iw {
    max-width: 400px !important;
}

.gm-style-iw-d {
    min-width: 300px;
}

.gm-style-iw-d .partner,
.gm-style-iw-d .partner .partner-title {
    padding: 0;
    margin-bottom: 15px;
}

.gm-style-iw-d .partner .partner-address {
    padding: 0;
    margin-bottom: 15px;
    min-height: 40px;
}

.team-list .team-block:nth-child(even) {
    background-color: #F5F3EF;
}

.team-list .team-block .person-desc p:last-of-type {
    margin: 0;
}

.team-list .team-block .person-name {
    font-size: 23px;
    line-height: 32px;
    font-weight: bold;
    color: #000000;
    margin: 0 0 15px 0;
    text-align: center;
    padding: 0 50px;
}

.team-list .team-block .person-desc {
    text-align: center;
    padding: 0 50px;
}

.team-list.team-block img {
    max-height: 300px;
    border-radius: 100%;
}

.tiny-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.tiny-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.tiny-scroll::-webkit-scrollbar-thumb {
    background: #888;
}

.tiny-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#map-filter-wrapper .tiny-scroll {
    max-height: 330px;
    overflow: hidden;
    overflow-y: auto;
}

#map-filter-wrapper .partner {
    padding: 30px 20px 25px 20px;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
}

#map-filter-wrapper .partner.selectedLocation {
    background: #F7F7F7;
}

#map-filter-wrapper .partner .partner-title {
    margin: 0 0 20px 0;
}

#map-filter-wrapper .partner .partner-action {
    border: none;
}

.textpage-nav {
    background-color: #F7F5F2;
    padding: 50px 0 0 30px;
}

.textpage-nav .position-sticky {
    top: 100px;
}

.textpage-nav h3 {
    padding: 0 0 20px 25px;
}

.textpage-nav ul {
    padding: 0 0 0 25px;
    border-left: 1px solid #104057;
}

.textpage-nav ul li {
    display: block;
}

.textpage-nav ul li a:hover,
.textpage-nav ul li.active a {
    color: #000000;
}

.textpage-nav ul li a {
    position: relative;
    padding: 5px 0;
    display: block;
}

.textpage-nav ul li.active a:before {
    content: "";
    background-color: #B89D65;
    position: absolute;
    top: 0;
    left: -25px;
    width: 4px;
    height: 100%;
}

#wo-breadcrumbs {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 5px;
    margin: 20px 0;
}

/*#wo-breadcrumbs .separator {*/
/*    position: relative;*/
/*    margin: 0 10px;*/
/*}*/

/*#wo-breadcrumbs .separator:before {*/
/*    content: "|";*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 50%;*/
/*    margin-top: -13px;*/
/*    width: 1px;*/
/*    height: 18px;*/
/*    background: #F7F5F2;*/
/*}*/

.create-label {
    position: relative;
    background: url("../../images/create-label-bgr.jpg") center center no-repeat;
    text-align: center;
    background-size: cover;
}

.create-label:after {
    content: "";
    background: url("../../images/create-label.jpg") center center no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}

.label-steps {
    padding: 0 25px;
    margin: 0 0 20px 0;
}

.label-steps .step-num {
    background-color: #093A52;
    display: table;
    margin: 0 auto 10px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 100%;
    color: #fff;
    font-size: 25px;
}

.label-steps .step-lab {
    line-height: 18px;
}

.splide {
    margin: 0 auto;
}

.thumbnails {
    display: flex;
    margin: 1rem auto 0;
    padding: 0;
    flex-wrap: wrap;
}

.thumbnail {
    width: 70px;
    height: 70px;
    overflow: hidden;
    list-style: none;
    margin: 0 0.2rem;
    cursor: pointer;
    opacity: 0.3;
}

.thumbnail.is-active {
    opacity: 1;
}


.thumbnail img {
    width: 100%;
    height: auto;
}
#main-slider {
    max-width: none;
    width: 100%;
    height: 100% !important;
}

#main-slider iframe{
    width: 100%;
    height: 100%;
}
.step-arrow {
    width: 70px;
    margin-top: 20px;
}

.blog-list-lab {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 2px;
    margin: 0 0 15px 0;
    font-family: 'Sofia Sans', sans-serif;
}

.cart .discount-info p {
    margin-top: -50px;
    margin-bottom: 35px;
}

.map-location #Component_85_3:hover path, .map-location #Component_105_3:hover path {
    fill: #D7BE7E;
    stroke: #fff;
}

.map-location svg {
    max-width: 100%;
}

.map-location svg a {
    display: block;
}

.map-location #Component_85_3:hover g path,
.map-location #Component_105_3:hover g path {
    stroke: #fff;
}


.map-location svg g text {
    font-family: 'Moderato G5 L', sans-serif;
}


.year-nav {
    margin: 0 auto 25px auto;
    display: table;
}

.year-nav .year-btn {
    color: #B5B5B5;
    font-size: 40px;
    line-height: 30px;
    font-family: 'Moderato E4 L', sans-serif;
    margin: 0 15px;
    background: none;
    border: 0;
    outline: none;
}

.year-nav .year-btn.active {
    color: #000000;
}

.nav-history {
    max-width: 360px;
    background-color: #F5F3EF;
    padding: 30px;
    position: absolute;
    top: 65px;
    left: 10px;
    box-shadow: 0px 5px 20px 0px rgb(0, 0, 0, .25);
}

.nav-history li {
    display: block;
}

.nav-history li button {
    border: 0;
    background: none;
    margin: 10px 0;
    outline: none;
}


.owl-history p {
    color: #262020;
    font-family: 'Sofia Sans', sans-serif;
}
.nav-history li button h3 {
    font-family: 'Moderato E4 L', sans-serif;
    font-size: 30px;
    line-height: 38px;
    margin: 0 0 5px 0;
}

.nav-history li button p {
    font-family: 'Sofia Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: #262020;
}

.his {
    background-color: #F5F3EF;
}

.owl-history .tab-content {
    background-color: #ffffff;
    margin: 0 100px;
    padding: 100px 100px 70px 100px;
    text-align: center;
}

.owl-history .tab-content .carousel-text {
    max-width: 500px;
    margin: 0 auto;
}

.owl-history .tab-content h3 {
    color: #000;
    font-size: 50px;
    line-height: 50px;
    font-family: 'Moderato G5 L', sans-serif;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    text-align: center;
}

.owl-history .tab-content img {
    width: auto;
    max-height: 400px;
    margin: 0 auto;
    display: block;
}


.about-stat .val {
    color: #093A52;
    font-size: 100px;
    line-height: 90px;
    font-family: 'Moderato G5 L', sans-serif;
    position: relative;
    padding: 15px 0 0 0;
    margin: 0 0 15px 0;
}

.about-stat .val:before {
    content: "";
    width: 50px;
    height: 2px;
    background-color: #9D7A45;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.about-stat .lab {
    color: #000000;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    text-transform: uppercase;
}

.about-stat .lab span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-transform: none;
    display: block;
    margin: 5px 0 0 0;
}


.our-story {
    text-align: center;
    background: url('../../images/our-story-bgr.jpg') center center no-repeat;
    background-size: cover;
}

.our-story .heading {
    color: #fff;
    margin: 0 0 20px 0;
}

.our-story p {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Moderato E4 L', sans-serif;
    font-style: italic;
    margin: 0;
}


.hidden-column {
    display: none;
}

#showMorePartners {
    outline: none;
    border: 0;
    background-color: transparent;
}

.popup-banner-modal .btn {
    display: block;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 5px;
}

.popup-banner-modal .modal-content {
    padding: 15px 20px 15px;
}

.popup-banner-modal .close {
    display: table;
    width: 30px;
    margin: 5px 5px 0 auto;
    outline: none;
    transition: all .4s;
    z-index: 999999;
}

.popup-banner-modal .btn {
    display: block;
    width: 100%;
    padding: 0 20px;
}

.popup-banner-modal .btn-primary:hover {
    background-color: white;
    color: #9d7a45;
}

.popup-banner-modal .btn-primary.focus,
.popup-banner-modal .btn-primary:focus,
.popup-banner-modal .btn-primary.disabled,
.popup-banner-modal .btn-primary:disabled,
.popup-banner-modal .btn-primary:not(:disabled):not(.disabled).active:focus,
.popup-banner-modal .btn-primary:not(:disabled):not(.disabled):active:focus,
.popup-banner-modal .show > .btn-primary.dropdown-toggle:focus,
.popup-banner-modal .btn-primary:not(:disabled):not(.disabled).active,
.popup-banner-modal .btn-primary:not(:disabled):not(.disabled):active,
.popup-banner-modal .show > .btn-primary.dropdown-toggle {
    background-color: white;
    border-color: white;
    color: #9d7a45;
    box-shadow: none;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, .5);
}

.popup-banner-modal .hint {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
}

.popup-banner-modal .hint img {
    vertical-align: bottom;
    margin: 0px auto 20px;
    width: 50px;
    display: block;
}